import Immutable from 'immutable';
import * as DashboardActionTypes from '../actions/ActionTypes';
import { DEFAULT_PAGE_SIZE, LOCALSTORAGE_PAGE_SIZE_KEY } from '../Constants';
const getDefaultPageSize = () => {
  try {
    const pageSize = localStorage.getItem(LOCALSTORAGE_PAGE_SIZE_KEY);
    return pageSize ? parseInt(pageSize, 10) : DEFAULT_PAGE_SIZE;
  } catch (e) {
    return DEFAULT_PAGE_SIZE;
  }
};
const defaultState = Immutable.Map({
  pageSize: getDefaultPageSize()
});
export default function FileUsage(state = defaultState, {
  type,
  pageSize
}) {
  switch (type) {
    case DashboardActionTypes.SET_PAGE_SIZE:
      try {
        localStorage.setItem(LOCALSTORAGE_PAGE_SIZE_KEY, pageSize.toString());
      } catch (e) {
        console.error('failed to set local storage', e);
      }
      return state.merge({
        pageSize
      });
    default:
      return state;
  }
}