import { ADOBE_INIT_ATTEMPTED, ADOBE_INIT_SUCCEEDED, ADOBE_INIT_FAILED, SAVE_ADOBE_IMAGE_ATTEMPTED, SAVE_ADOBE_IMAGE_SUCCEEDED, SAVE_ADOBE_IMAGE_FAILED } from '../actions/ActionTypes';
import { RequestStatus } from 'file-manager-components/constants';
const defaultState = {
  initStatus: RequestStatus.UNINITIALIZED,
  saveStatus: RequestStatus.UNINITIALIZED
};
export default function Adobe(state = defaultState, action) {
  const {
    type
  } = action;
  switch (type) {
    case ADOBE_INIT_ATTEMPTED:
      return Object.assign({}, state, {
        initStatus: RequestStatus.PENDING
      });
    case ADOBE_INIT_SUCCEEDED:
      return Object.assign({}, state, {
        initStatus: RequestStatus.SUCCEEDED
      });
    case ADOBE_INIT_FAILED:
      return Object.assign({}, state, {
        initStatus: RequestStatus.FAILED
      });
    case SAVE_ADOBE_IMAGE_ATTEMPTED:
      return Object.assign({}, state, {
        saveStatus: RequestStatus.PENDING
      });
    case SAVE_ADOBE_IMAGE_SUCCEEDED:
      return Object.assign({}, state, {
        saveStatus: RequestStatus.SUCCEEDED
      });
    case SAVE_ADOBE_IMAGE_FAILED:
      return Object.assign({}, state, {
        saveStatus: RequestStatus.FAILED
      });
    default:
      return state;
  }
}