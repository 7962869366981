import apiClient from 'hub-http/clients/apiClient';
export const setFileExpiration = (fileId, timestamp) => {
  const query = {
    timestamp // just placed in query for logging / auditing
  };
  return apiClient.post(`filemanager/api/v3/files/${fileId}/set-expires-at`, {
    data: timestamp,
    query
  });
};
export const removeFileExpiration = fileId => {
  return apiClient.post(`filemanager/api/v3/files/${fileId}/delete-expires-at`);
};