module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "hasFilesWrite": {
        "type": "boolean",
        "isOptional": true
      },
      "hasFileHostingPaidDomains": {
        "type": "boolean",
        "isOptional": true
      },
      "hasMarketingVideo": {
        "type": "boolean",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "subscreenView": {
    "name": "pageview",
    "class": "view",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerBrowseShutterstock": {
    "name": "Browse Shutterstock",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "section": {
        "type": "string",
        "isOptional": true
      },
      "open": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerManageFiles": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": true
      },
      "extension": {
        "type": "string",
        "isOptional": true
      },
      "ttl": {
        "type": "number",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerManageFolders": {
    "name": "Manage Folders",
    "class": "interaction",
    "properties": {
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "isTopLevel": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerFileVisibility": {
    "name": "Manage file visibility",
    "class": "interaction",
    "properties": {
      "isBulk": {
        "type": "boolean",
        "isOptional": false
      },
      "fileCount": {
        "type": "number",
        "isOptional": true
      },
      "visibility": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerCopyFileUrl": {
    "name": "Copy file URL",
    "class": "interaction",
    "properties": {
      "urlType": [
        "internal-share-link",
        "file-preview-link",
        "temporary-share-link",
        "public-domain-link",
        "unknown-link"
      ],
      "fileType": [
        "IMG",
        "MOVIE",
        "DOCUMENT",
        "AUDIO",
        "OTHER"
      ],
      "domainType": [
        "system-domain",
        "custom-domain"
      ],
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerAlert": {
    "name": "Alert interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Click file visibility learn more"
      ],
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerBridgeToMobile": {
    "name": "bridge to mobile",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "fileManagerVideoInteraction": {
    "name": "video interaction",
    "class": "interaction",
    "properties": {
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUpload": {
    "name": "upload file",
    "class": "interaction",
    "properties": {
      "size": {
        "type": "number",
        "isOptional": true
      },
      "errorType": {
        "type": "string",
        "isOptional": true
      },
      "statusCode": {
        "type": "number",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "videoHostingProvider": {
        "type": [
          "mux"
        ],
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUploadDeduplication": {
    "name": "check upload duplicates",
    "class": "interaction",
    "properties": {
      "hasData": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "size": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoPQL": {
    "name": "Video PQL",
    "class": "interaction",
    "properties": {
      "alert": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "quantityRemaining": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerImageGeneration": {
    "name": "image generation",
    "class": "interaction",
    "properties": {
      "errorCount": {
        "type": "number",
        "isOptional": true
      },
      "isFeedbackPositive": {
        "type": "boolean",
        "isOptional": true
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "resolution": {
        "type": "string",
        "isOptional": true
      },
      "style": {
        "type": "string",
        "isOptional": true
      },
      "correlationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "adobeExpressInteraction": {
    "name": "adobe express",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  }
};