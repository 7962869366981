import { reportError } from 'FileManagerCore/utils/logging';
import { LOCALSTORAGE_MOBILE_BRIDGE_VIEW_COUNT_KEY } from '../Constants';
export const MAX_VIEW_COUNT = 3;
export function getBridgeToMobileViewCount() {
  try {
    const localStorageValue = String(localStorage.getItem(LOCALSTORAGE_MOBILE_BRIDGE_VIEW_COUNT_KEY));
    return parseInt(localStorageValue, 10) || 0;
  } catch (e) {
    reportError(e);
    return 0;
  }
}
const setViewCount = viewCount => {
  try {
    localStorage.setItem(LOCALSTORAGE_MOBILE_BRIDGE_VIEW_COUNT_KEY, viewCount.toString());
  } catch (e) {
    reportError(e);
  }
};
export function closeMobileBridgeBanner() {
  try {
    setViewCount(3);
  } catch (e) {
    reportError(e);
  }
}
export function increaseLocalStorageViewCount() {
  setViewCount(getBridgeToMobileViewCount() + 1);
}