import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { Limits } from '../Constants';
export function fetchLimits() {
  return http.get(`video/v1/video-capacity`).then(resp => {
    return fromJS({
      [Limits.EMBEDDABLE_VIDEO]: Object.assign({}, resp, {
        type: resp.limitType
      })
    });
  });
}