import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
const tracker = createTracker({
  events,
  properties: {
    namespace: 'file-manager',
    screen: 'dashboard'
  },
  lastKnownEventProperties: ['screen']
});
export const trackDashboardView = (attrs = {}) => {
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize
  // experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes
  // to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki:
  // https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  tracker.track('pageView', Object.assign({}, attrs, {
    screen: 'dashboard'
  }));
};
export default tracker;