import { MAX_SEARCH_QUERY_LENGTH, MAX_OBJECT_REQUEST_LIMIT } from 'FileManagerCore/constants/CrmPlatformLimits';
import { fetchInitialFolderByPath } from 'FileManagerCore/actions/FolderFetch';
import { fetchSuspensionStatus } from 'FileManagerCore/actions/Suspension';
import { getHasFileManagerWriteScope, getHasVideoIntegrationScope } from 'FileManagerCore/selectors/Auth';
import { fetchFileManagerPortalData } from 'FileManagerCore/actions/PortalMeta';

// @ts-expect-error Not converted yet
import { getDashboardUrlParams } from '../utils/urls';
// @ts-expect-error Not converted yet
import { getPageSize } from '../selectors/pageSize';
// @ts-expect-error Not converted yet
import { navigate } from './Actions';
import { fetchFilesAndFolders } from './FilesAndFolders';
import { fetchLimits } from 'FileManagerCore/actions/Limits';

/**
 * Fetches files and folders together instead of separately where possible.
 * It also performs other actions needed on Dashboard mount specifically, like fetching limits.
 */
export const dashboardDidMount = location => (dispatch, getState) => {
  if (!getHasFileManagerWriteScope(getState())) {
    // Intentionally not awaited - this can happen asynchronously
    dispatch(fetchSuspensionStatus());
  }
  const fileFetchOptions = getDashboardUrlParams({
    location
  });

  // If we're trying to load more pages at once than the API allows, swtich back to page 1
  if (fileFetchOptions.page && (fileFetchOptions.page + 1) * getPageSize(getState()) >= MAX_OBJECT_REQUEST_LIMIT) {
    // Intentionally not awaited because this is a synchronous action
    dispatch(navigate({
      page: 1
    }, true));
    fileFetchOptions.page = 1;
  }

  // We don't support folder path in our CRM-based searches,
  // so we need to fetch files and folders one at a time, using a DB search for the folders
  if (!fileFetchOptions.search && fileFetchOptions.path) {
    return dispatch(fetchInitialFolderByPath(fileFetchOptions.path)).then(initialFolder => {
      return dispatch(fetchFilesAndFolders(Object.assign({}, fileFetchOptions, {
        folderId: initialFolder.get('id')
      }), true /* since we don't support folderPath, assuming a user gets here through an external app, eg forms */));
    }).catch(err => {
      throw err;
    });
  }

  // Enforce the validity of the search string
  if (fileFetchOptions.search) {
    fileFetchOptions.search = fileFetchOptions.search.substr(0, MAX_SEARCH_QUERY_LENGTH);
  }
  const searchPromise = dispatch(fetchFilesAndFolders(fileFetchOptions, true /* initialFetch */));

  /**
   * BEGIN SECTION
   * These fetches are a bit less important, so we want to kick off the files and folders search first
   */
  dispatch(fetchFileManagerPortalData());
  if (getHasVideoIntegrationScope(getState())) {
    dispatch(fetchLimits());
  }
  /** END SECTION */

  // Ultimately, consumers are most likely to want to wait on the results of the files and folders search
  return searchPromise;
};