import { Map as ImmutableMap, List } from 'immutable';
import * as ActionTypes from '../actions/ActionTypes';
import { RequestStatus } from '../Constants';
const defaultState = ImmutableMap({
  results: List(),
  total: 0,
  query: ImmutableMap({
    page: 1
  }),
  searchStatus: RequestStatus.UNINITIALIZED,
  acquireStatus: RequestStatus.UNINITIALIZED
});
export default function getShutterstockReducer({
  concatenateResults
}) {
  return (state = defaultState, action) => {
    const {
      type,
      data,
      query
    } = action;
    switch (type) {
      case ActionTypes.RESET_PAGE:
      case ActionTypes.SEARCH_SHUTTERSTOCK_RESET:
        return defaultState;
      case ActionTypes.SEARCH_SHUTTERSTOCK_FAILED:
        return state.set('searchStatus', RequestStatus.FAILED);
      case ActionTypes.SEARCH_SHUTTERSTOCK_ATTEMPTED:
        return state.merge({
          searchStatus: RequestStatus.PENDING,
          query
        });
      case ActionTypes.SEARCH_SHUTTERSTOCK_SUCCEEDED:
        if (query.get('searchQuery') !== state.getIn(['query', 'searchQuery'])) {
          return state;
        }
        return state.merge({
          results: concatenateResults ? state.get('results').concat(data.get('results')) : data.get('results'),
          total: data.get('resultCount'),
          searchStatus: RequestStatus.SUCCEEDED,
          query
        });
      case ActionTypes.ACQUIRE_IMAGE_FAILED:
        return state.merge({
          acquireStatus: RequestStatus.FAILED
        });
      case ActionTypes.ACQUIRE_IMAGE_ATTEMPTED:
        return state.merge({
          acquireStatus: RequestStatus.PENDING
        });
      case ActionTypes.ACQUIRE_IMAGE_SUCCEEDED:
        return state.merge({
          acquireStatus: RequestStatus.SUCCEEDED
        });
      default:
        return state;
    }
  };
}