import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { OPEN_MODAL, CLOSE_MODAL } from '../actions/ActionTypes';
import { RENAME_FOLDER_ATTEMPTED, RENAME_FOLDER_FAILED, RENAME_FOLDER_SUCCEEDED, MOVE_FILE_ATTEMPTED, MOVE_FILE_FAILED, MOVE_FILE_SUCCEEDED, MOVE_FOLDER_ATTEMPTED, MOVE_FOLDER_FAILED, MOVE_FOLDER_SUCCEEDED, REPLACE_FILE_ATTEMPTED, REPLACE_FILE_FAILED, REPLACE_FILE_SUCCEEDED, CHANGE_FOLDER_PARTITIONING_SUCCEEDED, CHANGE_FOLDER_PARTITIONING_ATTEMPTED, CHANGE_FOLDER_PARTITIONING_FAILED, CHANGE_FILE_PARTITIONING_ATTEMPTED, CHANGE_FILE_PARTITIONING_SUCCEEDED, CHANGE_FILE_PARTITIONING_FAILED } from 'FileManagerCore/actions/ActionTypes';
import { RequestStatus } from 'FileManagerCore/Constants';
import { MODAL_REQUEST_TYPES } from '../types/redux/ModalDisplay';
const defaultState = ImmutableMap({
  openModalName: null,
  objectCategory: null,
  objectId: null,
  bulkSelectedFolderIds: ImmutableSet([]),
  bulkSelectedFileIds: ImmutableSet([]),
  requests: ImmutableMap({}),
  videoKey: null
});
export default function ModalDisplay(state = defaultState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return state.merge(action.payload).set('openModalName', action.modalId);
    case CLOSE_MODAL:
      return defaultState;
    case RENAME_FOLDER_ATTEMPTED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.renameFolder], RequestStatus.PENDING);
    case RENAME_FOLDER_FAILED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.renameFolder], RequestStatus.FAILED);
    case RENAME_FOLDER_SUCCEEDED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.renameFolder], RequestStatus.SUCCEEDED);
    case MOVE_FILE_ATTEMPTED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFile], RequestStatus.PENDING);
    case MOVE_FILE_FAILED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFile], RequestStatus.FAILED);
    case MOVE_FILE_SUCCEEDED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFile], RequestStatus.SUCCEEDED);
    case MOVE_FOLDER_ATTEMPTED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFolder], RequestStatus.PENDING);
    case MOVE_FOLDER_FAILED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFolder], RequestStatus.FAILED);
    case MOVE_FOLDER_SUCCEEDED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.moveFolder], RequestStatus.SUCCEEDED);
    case REPLACE_FILE_ATTEMPTED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.replaceFile], RequestStatus.PENDING);
    case REPLACE_FILE_FAILED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.replaceFile], RequestStatus.FAILED);
    case REPLACE_FILE_SUCCEEDED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.replaceFile], RequestStatus.SUCCEEDED);
    case CHANGE_FOLDER_PARTITIONING_SUCCEEDED:
    case CHANGE_FILE_PARTITIONING_SUCCEEDED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.partition], RequestStatus.SUCCEEDED);
    case CHANGE_FOLDER_PARTITIONING_ATTEMPTED:
    case CHANGE_FILE_PARTITIONING_ATTEMPTED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.partition], RequestStatus.PENDING);
    case CHANGE_FOLDER_PARTITIONING_FAILED:
    case CHANGE_FILE_PARTITIONING_FAILED:
      return state.setIn(['requests', MODAL_REQUEST_TYPES.partition], RequestStatus.FAILED);
    default:
      return state;
  }
}