'use es6';

import Immutable from 'immutable';
import { UPLOAD_FILE_PROGRESS, UPLOAD_FILE_SUCCEEDED, UPLOAD_FILE_ATTEMPTED, UPLOAD_FILE_FAILED } from '../actions/ActionTypes';
import { ObjectCategory } from '../Constants';
import { splitNameAndExtension, getType } from '../utils/file';
const defaultState = Immutable.Map({
  files: new Immutable.OrderedMap()
});
export default function files(state = defaultState, action) {
  const {
    type,
    tempId,
    progress,
    tempUrl,
    tempFile,
    folderId,
    dimensions
  } = action;
  const nameAndExtension = tempFile ? splitNameAndExtension(tempFile) : {};
  switch (type) {
    case UPLOAD_FILE_ATTEMPTED:
      return state.setIn(['files', tempId], Immutable.Map(Object.assign({
        progress: 0,
        id: tempId,
        name: nameAndExtension.name,
        title: nameAndExtension.name,
        extension: nameAndExtension.extension,
        encoding: nameAndExtension.extension,
        type: getType(nameAndExtension.extension),
        size: tempFile.size,
        created: Date.now(),
        updated: Date.now(),
        temp: true,
        meta: Immutable.Map(),
        folder_id: folderId || null,
        tempUrl,
        category: ObjectCategory.FILE
      }, dimensions)));
    case UPLOAD_FILE_SUCCEEDED:
    case UPLOAD_FILE_FAILED:
      return state.deleteIn(['files', tempId]);
    case UPLOAD_FILE_PROGRESS:
      return state.setIn(['files', tempId, 'progress'], progress);
    default:
      return state;
  }
}