import Immutable from 'immutable';
import * as ActionTypes from '../actions/ActionTypes';
import { RequestStatus } from '../Constants';
import { getCategoryValues, getCategoryName } from '../utils/portalMeta';
const FileManagerPortalDataDefaultState = Immutable.Map({
  updateRequestStatus: RequestStatus.UNINITIALIZED,
  fetchRequestStatus: RequestStatus.UNINITIALIZED,
  categoryValues: Immutable.Map()
});
export default function PortalMeta(state = FileManagerPortalDataDefaultState, action) {
  const {
    type,
    portalDataList,
    category,
    apiValue
  } = action;
  switch (type) {
    case ActionTypes.FETCH_PORTAL_META_FAILED:
      return state.merge({
        fetchRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_PORTAL_META_SUCCEEDED:
      return state.merge({
        categoryValues: getCategoryValues(portalDataList),
        fetchRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.UPDATE_PORTAL_META_ATTEMPTED:
      return state.merge({
        updateRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.UPDATE_PORTAL_META_SUCCEEDED:
      {
        let categoryValues = state.get('categoryValues');
        if (typeof category === 'number') {
          categoryValues = categoryValues.set(getCategoryName(category), apiValue);
        }
        return state.merge({
          categoryValues,
          updateRequestStatus: RequestStatus.SUCCEEDED
        });
      }
    case ActionTypes.UPDATE_PORTAL_META_FAILED:
      return state.merge({
        updateRequestStatus: RequestStatus.FAILED
      });
    default:
      return state;
  }
}