export function isCurrentUsageTracker(usageTracker) {
  return Boolean(usageTracker && usageTracker.clone && usageTracker.setProperties);
}
export function getUsageTrackerJsEventName(hublyticsEventName) {
  switch (hublyticsEventName) {
    case 'Manage Files':
      return 'fileManagerManageFiles';
    case 'Browse Shutterstock':
      return 'fileManagerBrowseShutterstock';
    case 'Change image optimization setting':
      return 'fileManagerImageOptimization';
    case 'Alert interaction':
      return 'fileManagerAlert';
    default:
      return hublyticsEventName;
  }
}