export function getAsyncDimensions(url) {
  return new Promise(resolve => {
    const img = new window.Image();
    img.src = url;
    const handleLoad = () => {
      const dimensions = {
        width: img.width,
        height: img.height
      };
      resolve(dimensions);
    };
    const handleError = () => {
      resolve({
        width: 0,
        height: 0
      });
    };
    img.addEventListener('load', handleLoad, {
      once: true
    });
    img.addEventListener('error', handleError, {
      once: true
    });
  });
}