import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["category", "objectId"],
  _excluded2 = ["folderId", "teams", "users"],
  _excluded3 = ["fileId", "teams", "users"];
import * as ActionTypes from './ActionTypes';
import { setFolderPermissions as setFolderPermissionsAPI, setFilePermissions as setFilePermissionsAPI } from '../api/Partitioning';
import { filePartitioningAlerts, folderPartitioningAlerts } from '../utils/notifications';
import { RequestStatus, ObjectCategory } from '../Constants';
export function setPermissions(_ref) {
  let {
      category,
      objectId
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  return dispatch => {
    if (category === ObjectCategory.FOLDER) {
      return dispatch(setFolderPermissions(Object.assign({}, rest, {
        folderId: objectId
      })));
    }
    return dispatch(setFilePermissions(Object.assign({}, rest, {
      fileId: objectId
    })));
  };
}
export function setFolderPermissions(_ref2) {
  let {
      folderId,
      teams,
      users
    } = _ref2,
    rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  return async dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_FOLDER_PARTITIONING_ATTEMPTED,
      status: RequestStatus.PENDING
    });
    try {
      const {
        teams: updateTeamIds,
        owners: updatedUserIds
      } = await setFolderPermissionsAPI(Object.assign({
        folderId,
        teams,
        users
      }, rest));
      dispatch({
        type: ActionTypes.CHANGE_FOLDER_PARTITIONING_SUCCEEDED,
        folderId,
        teamIds: updateTeamIds,
        userIds: updatedUserIds,
        meta: {
          notification: folderPartitioningAlerts.success
        },
        status: RequestStatus.SUCCEEDED
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CHANGE_FOLDER_PARTITIONING_FAILED,
        meta: {
          notification: folderPartitioningAlerts.error
        },
        status: RequestStatus.FAILED
      });
      throw error;
    }
  };
}
export function setFilePermissions(_ref3) {
  let {
      fileId,
      teams,
      users
    } = _ref3,
    rest = _objectWithoutPropertiesLoose(_ref3, _excluded3);
  return async dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_FILE_PARTITIONING_ATTEMPTED,
      status: RequestStatus.PENDING
    });
    try {
      const {
        teams: updateTeamIds,
        owners: updatedUserIds
      } = await setFilePermissionsAPI(Object.assign({
        fileId,
        teams,
        users
      }, rest));
      dispatch({
        type: ActionTypes.CHANGE_FILE_PARTITIONING_SUCCEEDED,
        fileId,
        teamIds: updateTeamIds,
        userIds: updatedUserIds,
        meta: {
          notification: filePartitioningAlerts.success
        },
        status: RequestStatus.SUCCEEDED
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CHANGE_FILE_PARTITIONING_FAILED,
        meta: {
          notification: filePartitioningAlerts.error
        },
        status: RequestStatus.FAILED
      });
      throw error;
    }
  };
}