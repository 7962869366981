import { createSelector } from 'reselect';
import { Limits } from '../Constants';
export const getVideoLimit = state => state.limits.get(Limits.EMBEDDABLE_VIDEO);
export const getEmbeddableVideoLimit = createSelector([getVideoLimit], limits => limits.get('quantityAllowed'));
export const getUsedEmbeddableVideoCount = createSelector([getVideoLimit], limits => limits.get('quantityUsed'));
export const getAttemptedVideoUploadsCount = createSelector([getVideoLimit], limits => limits.get('attemptedVideoUploads'));
export const getDaysRemainingToDowngrade = createSelector([getVideoLimit], limits => limits.get('daysRemainingToDowngrade'));
export const getVideoUsageRequestStatus = state => {
  return state.limits.get('requestStatus');
};
export const getHasUserExceededEmbeddableVideoLimit = state => {
  const used = getUsedEmbeddableVideoCount(state);
  const limit = getEmbeddableVideoLimit(state);
  return used >= limit;
};
export const getShouldUserDowngradeVideo2 = state => {
  return getUsedEmbeddableVideoCount(state) > getEmbeddableVideoLimit(state);
};