import * as TeamsApi from '../api/Teams';
import { reportError } from '../utils/logging';
import { FETCH_TEAMS_ATTEMPTED, FETCH_TEAMS_FAILED, FETCH_TEAMS_SUCCEEDED } from './ActionTypes';
export const fetchTeams = () => {
  return dispatch => {
    dispatch({
      type: FETCH_TEAMS_ATTEMPTED
    });
    return TeamsApi.fetchTeams().then(data => {
      return dispatch({
        type: FETCH_TEAMS_SUCCEEDED,
        data
      });
    }).catch(err => {
      reportError(err, {
        action: FETCH_TEAMS_FAILED
      });
      dispatch({
        type: FETCH_TEAMS_FAILED
      });
      throw err;
    });
  };
};