/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
/* eslint-disable hubspot-dev/no-async-await */

import { fromJS } from 'immutable';
import httpClient from 'hub-http/clients/apiClient';
const BASE_URI = 'users/v2';
export async function fetchHubUsers() {
  return httpClient.get(`${BASE_URI}/app/hub-users`).then(fromJS);
}