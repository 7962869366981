import Immutable from 'immutable';
import apiClient from 'hub-http/clients/apiClient';
const URI = 'filemanager/api/v3/players';
export function createPlayerForVideo(fileId, http = apiClient) {
  return http.post(URI, {
    data: {
      file_id: fileId,
      try_reuse: true
    }
  }).then(Immutable.fromJS);
}
export function softDeleteHubLVideoPlayer(playerId, http = apiClient) {
  return http.delete(`${URI}/${playerId}`);
}