import { Map as ImmutableMap } from 'immutable';
import { FETCH_SUSPENSION_STATE_ATTEMPTED, FETCH_SUSPENSION_STATE_FAILED, FETCH_SUSPENSION_STATE_SUCCEEDED } from '../actions/ActionTypes';
import { RequestStatus } from '../Constants';
import AppealStates from '../enums/SuspensionAppealStates';
const defaultState = ImmutableMap({
  suspended: false,
  canAppeal: false,
  appealState: AppealStates.UNINITIATED,
  fetchStatus: RequestStatus.UNINITIALIZED
});
export default function Suspension(state = defaultState, action) {
  const {
    type
  } = action;
  switch (type) {
    case FETCH_SUSPENSION_STATE_ATTEMPTED:
      return state.set('fetchStatus', RequestStatus.PENDING);
    case FETCH_SUSPENSION_STATE_SUCCEEDED:
      return action.data.set('fetchStatus', RequestStatus.SUCCEEDED);
    case FETCH_SUSPENSION_STATE_FAILED:
      return state.set('fetchStatus', RequestStatus.SUCCEEDED);
    default:
      return state;
  }
}