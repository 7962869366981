import { Map as ImmutableMap } from 'immutable';
import { RequestStatus } from '../Constants';
import { HUB_USERS_FETCH_SUCCEEDED, HUB_USERS_FETCH_FAILED, HUB_USERS_FETCH_PENDING } from '../actions/ActionTypes';
const HUB_USERS_EMPTY_STATE = ImmutableMap();
const defaultState = ImmutableMap({
  hubUsers: HUB_USERS_EMPTY_STATE,
  status: RequestStatus.UNINITIALIZED
});
export default function HubUsers(state = defaultState, action) {
  const {
    type,
    users
  } = action;
  switch (type) {
    case HUB_USERS_FETCH_PENDING:
      return state.merge({
        status: RequestStatus.PENDING
      });
    case HUB_USERS_FETCH_SUCCEEDED:
      return state.merge({
        hubUsers: users ? users.reduce((hash, user) => hash.set(user.get('id'), user), HUB_USERS_EMPTY_STATE) : state.get('hubUsers'),
        status: RequestStatus.SUCCEEDED
      });
    case HUB_USERS_FETCH_FAILED:
      return state.merge({
        status: RequestStatus.FAILED
      });
    default:
      return state;
  }
}