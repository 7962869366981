import { SYSTEM_FOLDER_PATHS } from '../constants/SystemFolders';
import { isFile } from './FoldersAndFiles';
const getIsSystemFolder = fileOrFolderObject => {
  if (isFile(fileOrFolderObject)) {
    return false;
  }
  const fullPath = fileOrFolderObject.get('full_path');
  const rootFolderPath = `/${fullPath.split('/')[1]}`;
  return SYSTEM_FOLDER_PATHS.includes(rootFolderPath);
};
export default getIsSystemFolder;