import PropTypes from 'prop-types';
import { ObjectCategory } from '../Constants';
import * as ReadOnlyReason from '../enums/ReadOnlyReason';
const TTL_OPTIONS = ['P1Y', 'P12M', 'P6M', 'P1D', 'P8W', 'P52W'];
export const FileUploadOptionsPropType = PropTypes.exact({
  ttl: PropTypes.oneOf(TTL_OPTIONS),
  folderId: PropTypes.number,
  folderPath: PropTypes.string,
  overwrite: PropTypes.bool
});
export const FILE_UPLOAD_V3_OPTION_KEYS = ['ttl', 'folderId', 'folderPath', 'overwrite'];
export const SUPPORTED_UI_DROP_ZONE_PROPS = ['className', 'children', 'contentClassName', 'iconName', 'iconSize', 'size', 'use'];
export const objectCategoryProp = PropTypes.oneOf(Object.values(ObjectCategory));
export const readOnlyReasonProp = PropTypes.oneOf(Object.values(ReadOnlyReason));