import Immutable from 'immutable';
import { FETCH_TEAMS_FAILED, FETCH_TEAMS_ATTEMPTED, FETCH_TEAMS_SUCCEEDED } from '../actions/ActionTypes';
import { RequestStatus } from '../Constants';
export default function Teams(state = Immutable.Map({
  status: RequestStatus.UNINITIALIZED,
  teams: Immutable.Map()
}), action) {
  const {
    type,
    data
  } = action;
  switch (type) {
    case FETCH_TEAMS_SUCCEEDED:
      return state.merge({
        status: RequestStatus.SUCCEEDED,
        teams: Immutable.Map(data.map(team => [team.get('id'), team]))
      });
    case FETCH_TEAMS_ATTEMPTED:
      return state.merge({
        status: RequestStatus.PENDING
      });
    case FETCH_TEAMS_FAILED:
      return state.merge({
        status: RequestStatus.FAILED
      });
    default:
      return state;
  }
}