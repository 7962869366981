import * as ActionTypes from './ActionTypes';
import * as FileManagerPortalDataApi from '../api/PortalMeta';
import { reportError } from '../utils/logging';
import { DiscoverabilityPopupDismissed, ShutterstockTosValues, TRACK_EVENT } from '../Constants';
import PortalMetaCategoryIds from '../enums/PortalMetaCategoryIds';
export const fetchFileManagerPortalData = () => async dispatch => {
  let response;
  try {
    response = await FileManagerPortalDataApi.fetchFileManagerPortalData();
    dispatch({
      type: ActionTypes.FETCH_PORTAL_META_SUCCEEDED,
      portalDataList: response
    });
    return response;
  } catch (err) {
    reportError(err, {
      action: ActionTypes.FETCH_PORTAL_META_FAILED
    });
    dispatch({
      type: ActionTypes.FETCH_PORTAL_META_FAILED
    });
  }
  return response;
};
export const updatePortalMeta = (category, apiValue, meta = {}) => async dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_PORTAL_META_ATTEMPTED
  });
  try {
    await FileManagerPortalDataApi.updatePortalMeta({
      category,
      categoryValue: apiValue
    });
    dispatch({
      type: ActionTypes.UPDATE_PORTAL_META_SUCCEEDED,
      category,
      apiValue,
      meta
    });
  } catch (err) {
    reportError(err, {
      type: ActionTypes.UPDATE_PORTAL_META_FAILED
    });
    dispatch({
      type: ActionTypes.UPDATE_PORTAL_META_FAILED
    });
  }
};
export const dismissAdvancedSearchDashboardPopup = () => dispatch => dispatch(updatePortalMeta(PortalMetaCategoryIds.DASHBOARD_ADVANCED_SEARCH_POPUP, DiscoverabilityPopupDismissed, {
  [TRACK_EVENT]: {
    eventKey: 'fileManagerExploreFiles',
    action: 'advanced-search-dismiss'
  }
}));
export const dismissAdvancedSearchPickerPopup = () => dispatch => dispatch(updatePortalMeta(PortalMetaCategoryIds.PICKER_ADVANCED_SEARCH_POPUP, DiscoverabilityPopupDismissed, {
  [TRACK_EVENT]: {
    eventKey: 'fileManagerExploreFiles',
    action: 'advanced-search-dismiss'
  }
}));
export const acceptShutterstockTos = () => dispatch => dispatch(updatePortalMeta(PortalMetaCategoryIds.SHUTTERSTOCK_TOS, ShutterstockTosValues.ACCEPTED));
export const setShutterstockFolderId = folderId => dispatch => dispatch(updatePortalMeta(PortalMetaCategoryIds.SHUTTERSTOCK_FOLDER_ID, folderId));