import { fromJS } from 'immutable';
import apiClient from 'hub-http/clients/apiClient';
import { getCategoryName } from '../utils/portalMeta';
const BASE_URL = 'filemanager/api/v4/portal-meta';
export function fetchFileManagerPortalData() {
  return apiClient.get(BASE_URL).then(fromJS);
}
export function updatePortalMeta({
  category,
  categoryValue
}) {
  const categoryName = getCategoryName(category);
  const data = {
    category,
    categoryValue
  };
  const query = {
    categoryName
  };
  return apiClient.post(`${BASE_URL}/upsert`, {
    data,
    query
  }).then(fromJS);
}