import { List, Map as ImmutableMap, OrderedMap, fromJS } from 'immutable';
import memoize from 'react-utils/memoize';
import I18n from 'I18n';
import { escapeRegExp, removeLeadingSlashFromPath } from './stringUtils';
import { ObjectCategory } from '../Constants';
export const getHomeFolder = memoize(() => ImmutableMap({
  name: I18n.text('FileManagerCore.rootFolder'),
  id: null,
  parent_folder_id: null,
  full_path: '',
  children: List()
}));
function findFoldersForParentId(foldersByParentId, parentId) {
  return foldersByParentId.get(parentId, List()).map(f => f.toJS()).map(f => {
    f.children = findFoldersForParentId(foldersByParentId, String(f.id));
    return f;
  }).toArray();
}
export function makeFolderTree(folders) {
  const foldersByParentId = OrderedMap(folders.groupBy(f => f.get('parent_folder_id')));
  const folderTree = findFoldersForParentId(foldersByParentId, null);
  const root = getHomeFolder().toJS();
  root.children = folderTree;
  return root;
}
export function isFile(object) {
  if (object.get('category')) {
    return object.get('category') === ObjectCategory.FILE;
  }
  return object.has('meta');
}
export function getFolder(id, folders) {
  if (folders instanceof OrderedMap) {
    return folders.get(id);
  }
  return folders.find(folder => folder.get('id') === id);
}
export function getParentFolderId(object) {
  if (!object) {
    return null;
  }
  const folderId = object.get('parent_folder_id') || object.get('folder_id');
  if (!folderId) {
    return null;
  }
  if (typeof folderId === 'string') {
    return parseInt(folderId, 10);
  }
  return folderId;
}
export function getParentFolder(object, folders) {
  const parentId = getParentFolderId(object);
  if (!parentId) {
    return getHomeFolder();
  }
  return folders.find(folder => {
    return folder.get('id') === parentId;
  }) || getHomeFolder();
}
export function getAncestors(folder, folders, ancestors = OrderedMap()) {
  if (!folder) {
    return ancestors;
  }
  ancestors = ancestors.set(folder.get('id'), folder);
  if (!folder.get('parent_folder_id')) {
    return ancestors.reverse();
  }
  const parentId = parseInt(folder.get('parent_folder_id'), 10);
  const parentFolder = getFolder(parentId, folders);
  if (!parentFolder) {
    return ancestors;
  }
  return getAncestors(parentFolder, folders, ancestors);
}
export function getAncestorsForFile(file, folders) {
  const folderId = file.get('folder_id');
  const folder = folders.get(folderId);
  if (!folder) {
    return OrderedMap();
  }
  return getAncestors(folder, folders);
}
export function getFilteredByKeyword(folders, keyword) {
  return folders.filter(folder => {
    const folderName = folder.get('name').toLowerCase();
    let searchQuery = keyword.toLowerCase();
    try {
      // decode percent-encoding, which is safe because folder names can't contain %
      searchQuery = window.decodeURIComponent(searchQuery);
    } catch (e) {} // eslint-disable-line no-empty

    return folderName.indexOf(searchQuery) >= 0;
  });
}
export function filterFolders(folders, path, keyword, parentFolderId = null) {
  if (keyword) {
    return getFilteredByKeyword(folders, keyword);
  } else if (!path && !parentFolderId) {
    return folders.filter(folder => {
      return folder.get('parent_folder_id') === null;
    });
  }
  let parentFolder = null;
  if (parentFolderId) {
    parentFolder = getFolder(parentFolderId, folders);
  } else if (path) {
    parentFolder = folders.find(folder => folder.get('full_path') === `/${path}` || folder.get('full_path') === path);
  }
  if (parentFolder) {
    return folders.filter(folder => {
      const parentId = Number(folder.get('parent_folder_id'));
      return parentFolder ? parentId === parentFolder.get('id') : true;
    });
  }
  // [TEMPORARY FIX] if the parent folder is hidden, use the path and regex
  // which may be less reliable since it won't use the parent_folder_id
  return folders.filter(folder => {
    const fullPath = folder.get('full_path');
    const regex = new RegExp(`\\/${escapeRegExp(folder.get('name'))}$`);
    const basePath = fullPath.replace(regex, '');
    return basePath === `/${path}`;
  });
}
export function toOrderedMapFilesOrFolders(objects) {
  return OrderedMap(objects.map(f => {
    return [f.get('id'), f];
  }));
}
export const buildFolderFromAttrs = attrs => {
  return fromJS(Object.assign({}, attrs, {
    category: ObjectCategory.FOLDER
  }));
};
export const buildFolderMap = objects => {
  return OrderedMap(objects.map(attrs => [attrs.id, buildFolderFromAttrs(attrs)]));
};
export const getAncestorPaths = fullPath => {
  fullPath = removeLeadingSlashFromPath(fullPath);
  const pathParts = fullPath.split('/');
  return pathParts.map((part, i) => {
    return `/${pathParts.slice(0, i + 1).join('/')}`;
  });
};
export const getLeafFolderName = fullPath => {
  fullPath = removeLeadingSlashFromPath(fullPath);
  const pathParts = fullPath.split('/');
  return pathParts[pathParts.length - 1];
};
const RECENT_FILE_UPLOAD_THRESHOLD_SECONDS = 10;
export const filterRecentlyUploadedFiles = (singleFileDetails, uploadRequestsById, currentFolderId) => {
  const threshold = I18n.moment().subtract(RECENT_FILE_UPLOAD_THRESHOLD_SECONDS, 'seconds').valueOf();
  const recentlyUploadedFileIds = uploadRequestsById.filter(val => val.get('timestamp') > threshold).keySeq().toList();
  let recentlyUploadedFiles = recentlyUploadedFileIds.map(id => singleFileDetails.get(id)).filter(f => Boolean(f));
  recentlyUploadedFiles = recentlyUploadedFiles.map(f => {
    const clientUploadedAt = uploadRequestsById.get(f.get('id')).get('timestamp');
    return f.set('clientUploadedAt', clientUploadedAt);
  });
  if (currentFolderId) {
    if (currentFolderId === 'None') {
      currentFolderId = null;
    }
    recentlyUploadedFiles = recentlyUploadedFiles.filter(f => f.get('folder_id') === currentFolderId);
  }
  return recentlyUploadedFiles;
};