import { TRACK_EVENT } from '../Constants';
import { TRACK_INTERACTION } from './ActionTypes';
import { getUsageTrackerJsEventName } from '../utils/tracking';
export function getTrackingMeta(name, action, meta = {}) {
  return {
    [TRACK_EVENT]: {
      name,
      eventKey: getUsageTrackerJsEventName(name),
      action,
      meta
    }
  };
}
export function trackInteraction(name, action, meta = {}) {
  return {
    type: TRACK_INTERACTION,
    meta: getTrackingMeta(name, action, meta)
  };
}
const trackedActionsByEvent = {};
export const trackOnce = (eventKey, action, meta = {}) => dispatch => {
  if (trackedActionsByEvent[eventKey] && trackedActionsByEvent[eventKey].includes(action)) {
    return;
  }
  if (!trackedActionsByEvent[eventKey]) {
    trackedActionsByEvent[eventKey] = [];
  }
  trackedActionsByEvent[eventKey].push(action);
  dispatch(trackInteraction(eventKey, action, meta));
};