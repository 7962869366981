/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

import I18n from 'I18n';
import Raven from 'raven-js';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
// @ts-expect-error No types for placeholder images
import imagePlaceholderUrl from 'bender-url!FileManagerImages/images/image-placeholder.png';
import allSettled from 'file-manager-components/utils/allSettled';
import * as FilesApi from '../api/Files';
import * as VideoPlayersApi from '../api/VideoPlayers';
import { FileTypes, TRACK_EVENT } from '../Constants';
import { IMPORTED_IMAGE_FOLDER_PATH } from '../constants/SystemFolders';
import { getAsyncDimensions } from '../utils/dimensions';
import tempFileId from '../utils/tempFileId';
import { splitNameAndExtension, getType, getUpdatedPlayerIdVideoFile, softDeleteVideoPlayerIdFromFile, canPreviewLocalFile, getIsVideo } from '../utils/file';
import { DELETE_FILE_FAILED, DELETE_FILE_SUCCEEDED, MOVE_FILE_ATTEMPTED, MOVE_FILE_FAILED, MOVE_FILE_SUCCEEDED, RENAME_FILE_ATTEMPTED, RENAME_FILE_FAILED, RENAME_FILE_SUCCEEDED, REPLACE_FILE_ATTEMPTED, REPLACE_FILE_PROGRESS, REPLACE_FILE_SUCCEEDED, REPLACE_FILE_FAILED, UPLOAD_FILE_ATTEMPTED, UPLOAD_FILE_FAILED, UPLOAD_FILE_PROGRESS, UPLOAD_FILE_SUCCEEDED, CREATE_VIDEO_PLAYERID_SUCCEEDED, CREATE_VIDEO_PLAYERID_ATTEMPTED, CREATE_VIDEO_PLAYERID_FAILED, SOFT_DELETE_VIDEO_PLAYERID_FAILED, SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED, BULK_UPLOAD_FILES_ATTEMPTED, IMAGE_TO_EDIT_SELECTED, IMAGE_TO_EDIT_DESELECTED, UPLOAD_EDITED_IMAGE_ATTEMPTED, UPLOAD_EDITED_IMAGE_FAILED, UPLOAD_EDITED_IMAGE_SUCCEEDED, FETCH_SIGNED_URL_FAILED, DOWNLOAD_FROM_EXTERNAL_URL_ATTEMPTED, DOWNLOAD_FROM_EXTERNAL_URL_SUCCEEDED, SOFT_DELETE_VIDEO_PLAYERID_ATTEMPTED, UPLOAD_BATCH_EXCEEDED_LIMIT } from './ActionTypes';
import { getDecreaseVideoQuantityUsedAction } from './Limits';
import { isHubLVideo } from '../utils/hubLVideo';
import { getFailureNotification, getFetchSignedURLFailedNotification, getFileDelete403FailureNotification, getFileTooLargeNotification, getReadOnlyPermissionCopy, getRenameFileFailedNotification, getSuccessNotification, getUploadBatchOverLimitNotification, getUploadError, getUploadFileErrorNotification } from '../utils/notifications';
import { reportError } from '../utils/logging';
import { getReadOnlyReason } from '../selectors/Permissions';
import { getFileAccess } from '../utils/fileAccessibility';
import { UploadValidationError } from '../types/redux/Files';
import { MAX_FILE_UPLOAD_TOTAL } from '../constants/CrmPlatformLimits';
import { MAX_UPLOAD_SIZE_BYTES } from '../constants/fileSizes';
import { trackInteraction } from './tracking';
import { selectIsUngatedForPermissions } from '../selectors/Auth';
import { getFoldersById } from '../selectors/Folders';
export function receiveFileDelete(file) {
  const notificationKey = 'trashFile';
  return {
    type: DELETE_FILE_SUCCEEDED,
    fileId: file.get('id'),
    meta: {
      notification: getSuccessNotification(notificationKey, {
        messageI18nOptions: {
          filename: file.get('name')
        }
      })
    }
  };
}
export function removeFile(file) {
  return (dispatch, getState) => {
    FilesApi.remove(file.get('id')).then(() => {
      if (isHubLVideo(file.toJS())) {
        dispatch(getDecreaseVideoQuantityUsedAction(1));
      }
      dispatch(receiveFileDelete(file));
    }, error => {
      const notificationKey = 'trashFile';
      dispatch({
        type: DELETE_FILE_FAILED,
        meta: {
          notification: selectIsUngatedForPermissions(getState()) && error.status === 403 ? getFileDelete403FailureNotification({
            filename: file.get('name')
          }) : getFailureNotification(notificationKey, {
            messageI18nOptions: {
              filename: file.get('name')
            }
          })
        },
        error,
        file
      });
    });
  };
}
export const renameFile = (fileToRename, newName) => (dispatch, getState) => {
  const newFile = fileToRename.set('name', newName);
  dispatch({
    type: RENAME_FILE_ATTEMPTED,
    file: newFile
  });
  return FilesApi.move(fileToRename.get('id'), {
    name: newName
  }).then(renamedFile => {
    dispatch({
      type: RENAME_FILE_SUCCEEDED,
      file: renamedFile
    });
    return renamedFile;
  }).catch(error => {
    reportError(error, {
      type: RENAME_FILE_FAILED
    });
    dispatch({
      type: RENAME_FILE_FAILED,
      data: fileToRename,
      meta: {
        notification: getRenameFileFailedNotification(fileToRename, error, selectIsUngatedForPermissions(getState()))
      },
      file: fileToRename,
      error
    });
    throw error;
  });
};
export function moveFile(fileToMove, destination) {
  return (dispatch, getState) => {
    dispatch({
      type: MOVE_FILE_ATTEMPTED
    });
    FilesApi.move(fileToMove.get('id'), {
      folder_id: destination
    }).then(movedFile => {
      dispatch({
        type: MOVE_FILE_SUCCEEDED,
        file: movedFile
      });
    }, error => {
      const errorType = error && error.responseJSON ? error.responseJSON.errorType : '';
      const titleText = I18n.text('FileManagerCore.notifications.moveFile.error.title');
      // default message
      let message = I18n.text('FileManagerCore.notifications.moveFile.error.message', {
        filename: fileToMove.get('name')
      });
      if (selectIsUngatedForPermissions(getState()) && error.status === 403) {
        message = I18n.text('FileManagerCore.notifications.moveFile.error.noAccessMessage', {
          filename: fileToMove.get('name')
        });
      } else if (errorType === 'FILE_MOVE_CONFLICT') {
        message = I18n.text('FileManagerCore.notifications.moveFile.error.FILE_MOVE_CONFLICT');
      }
      dispatch({
        type: MOVE_FILE_FAILED,
        meta: {
          notification: {
            type: 'danger',
            titleText,
            message
          }
        },
        error
      });
    });
  };
}
export const showUploadDisabledNotification = readOnlyReason => {
  const copyInfo = getReadOnlyPermissionCopy(readOnlyReason);
  FloatingAlertStore.addAlert({
    'data-test-id': 'upload-attempted-while-disabled-alert',
    type: 'danger',
    titleText: copyInfo.explanation
  });
};
const handleUploadInReadOnly = readOnlyReason => {
  showUploadDisabledNotification(readOnlyReason);
  const err = new UploadValidationError(`Attempted upload while readOnly, reason: ${readOnlyReason}`, readOnlyReason);
  return Promise.reject(err);
};
const handleUploadOverSizeLimit = file => dispatch => {
  FloatingAlertStore.addAlert(getFileTooLargeNotification(file));
  dispatch(trackInteraction('fileManagerManageFiles', 'upload over size limit'));
  return Promise.reject(new UploadValidationError('Upload over platform size limit'));
};
export const handleUploadBatchOverLimit = fileList => dispatch => {
  dispatch({
    type: UPLOAD_BATCH_EXCEEDED_LIMIT,
    meta: {
      notification: getUploadBatchOverLimitNotification(fileList.length),
      [TRACK_EVENT]: {
        eventKey: 'fileManagerManageFiles',
        action: 'upload batch over limit',
        meta: {
          count: fileList.length
        }
      }
    }
  });
  const err = new UploadValidationError('Attempted uploading more files at once than allowed', 'BATCH_EXCEEDED_LIMIT');
  return Promise.reject(err);
};

// prefer `uploadDashboardFiles/uploadPickerFiles` for user initiated uploads, as they have validations and contextual notifications
export function uploadFile(file, options, {
  updateProgress
} = {}) {
  return (dispatch, getState) => {
    const readOnlyReason = getReadOnlyReason(getState());
    if (readOnlyReason) {
      return handleUploadInReadOnly(readOnlyReason);
    }
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      return dispatch(handleUploadOverSizeLimit(file));
    }
    const tempId = options.tempId || tempFileId();
    const startedAt = Date.now();
    const {
      name,
      extension
    } = splitNameAndExtension(file);
    const type = getType(extension);
    const isImage = type === FileTypes.IMG;
    const progress = event => {
      dispatch({
        type: UPLOAD_FILE_PROGRESS,
        progress: event.loaded / event.total * 95,
        tempId
      });
    };
    Raven.captureBreadcrumb({
      message: UPLOAD_FILE_ATTEMPTED,
      data: Object.assign({}, options, {
        tempId,
        name,
        extension,
        type,
        size: file.size
      })
    });
    let tempUrl = '';
    if (canPreviewLocalFile(file)) {
      tempUrl = window.URL.createObjectURL(file);
    } else if (isImage) {
      tempUrl = imagePlaceholderUrl;
    }
    const upload = (dimensions = {}) => {
      dispatch({
        type: UPLOAD_FILE_ATTEMPTED,
        tempFile: file,
        folderId: options.folderId,
        dimensions,
        tempUrl,
        tempId
      });
      return FilesApi.uploadFileV3(file, options.access, options, {
        updateProgress: updateProgress || progress
      }).then(data => {
        const uploadedFile = data.getIn(['objects', 0]);
        const usageEvent = {
          eventKey: 'fileManagerUpload',
          action: 'upload success',
          meta: {
            fileType: uploadedFile.get('type'),
            fileExtension: uploadedFile.get('extension'),
            size: uploadedFile.get('size'),
            duration: Date.now() - startedAt
          }
        };
        if (getIsVideo(uploadedFile)) {
          usageEvent.meta.videoDuration = uploadedFile.getIn(['meta', 'duration']) / 1000;
          if (isHubLVideo(uploadedFile.toJS())) {
            usageEvent.meta.videoHostingProvider = 'mux';
          }
        }
        dispatch({
          type: UPLOAD_FILE_SUCCEEDED,
          file: uploadedFile,
          tempId,
          meta: {
            [TRACK_EVENT]: usageEvent
          }
        });
        if (tempUrl) {
          window.URL.revokeObjectURL(tempUrl);
        }
        Raven.captureBreadcrumb({
          message: UPLOAD_FILE_SUCCEEDED,
          data: Object.assign({}, options, {
            tempId,
            file: uploadedFile.toJS()
          })
        });
        return uploadedFile;
      }, error => {
        const notification = getUploadFileErrorNotification(error, file);
        const statusCode = typeof error.status === 'number' ? error.status : undefined;
        const usageEvent = {
          eventKey: 'fileManagerUpload',
          action: 'upload failed',
          meta: {
            fileType: type,
            fileExtension: extension,
            size: file.size,
            duration: Date.now() - startedAt,
            errorType: notification['data-error-type'],
            statusCode
          }
        };
        dispatch({
          type: UPLOAD_FILE_FAILED,
          meta: {
            notification: Object.assign({
              type: 'danger'
            }, notification),
            [TRACK_EVENT]: usageEvent
          },
          error,
          tempId
        });
        if (tempUrl) {
          window.URL.revokeObjectURL(tempUrl);
        }
        reportError(error, {
          action: UPLOAD_FILE_FAILED,
          fileType: type,
          fileExtension: extension,
          fileSize: file.size
        }, {
          reportToNewRelic: error.status !== 0
        });
        throw error;
      });
    };
    if (isImage && tempUrl) {
      return getAsyncDimensions(tempUrl).then(upload);
    }
    return upload();
  };
}
export function getEditedImageUploadSucceeded({
  file,
  titleText,
  message
}) {
  return {
    type: UPLOAD_EDITED_IMAGE_SUCCEEDED,
    file,
    tempId: null,
    meta: {
      notification: {
        type: 'success',
        titleText,
        message
      }
    }
  };
}
export function getEditedImageUploadFailed() {
  return {
    type: UPLOAD_EDITED_IMAGE_FAILED,
    meta: {
      notification: {
        type: 'danger',
        titleText: I18n.text('FileManagerCore.notifications.uploadEditedFile.error.title'),
        message: I18n.text('FileManagerCore.notifications.uploadEditedFile.error.message')
      }
    }
  };
}
function getTextForUploadEditedImgSuccess(file) {
  return {
    titleText: I18n.text('FileManagerCore.notifications.uploadEditedFile.success.title', {
      fileName: file.get('name')
    })
  };
}
export function uploadEditedImage({
  file,
  fileName,
  folderId,
  folderPath,
  uploadedFileAccess,
  onSaveComplete,
  onError,
  actionType = 'crop image',
  getSuccessMessage = getTextForUploadEditedImgSuccess
}) {
  return (dispatch, getState) => {
    dispatch({
      type: UPLOAD_EDITED_IMAGE_ATTEMPTED,
      meta: {
        [TRACK_EVENT]: {
          eventKey: 'fileManagerManageFiles',
          action: actionType
        }
      }
    });

    // We'll prioritize saving to the folder indicated by the folderId, if provided
    // If not, we'll save to the folder indicated by the folderPath, if provided
    // If neither are provided, we'll default upload to the home folder
    return FilesApi.uploadFileV3(file, uploadedFileAccess, {
      fileName,
      folderId,
      folderPath
    }).then(data => {
      const savedFile = data.getIn(['objects', 0]);
      dispatch(getEditedImageUploadSucceeded(Object.assign({
        file: savedFile
      }, getSuccessMessage(savedFile, getFoldersById(getState())))));
      if (onSaveComplete) {
        onSaveComplete(savedFile.get('url'), savedFile);
      }
    }).catch(error => {
      dispatch(getEditedImageUploadFailed());
      onError === null || onError === void 0 || onError();
      Raven.captureMessage('Failed to upload edited image', {
        extra: {
          error
        }
      });
    });
  };
}
export function uploadResizedImage(fileId, newWidth, uploadedFileAccess, onSaveComplete) {
  return dispatch => {
    dispatch({
      type: UPLOAD_EDITED_IMAGE_ATTEMPTED,
      meta: {
        [TRACK_EVENT]: {
          eventKey: 'fileManagerManageFiles',
          action: 'resize image'
        }
      }
    });
    return FilesApi.uploadResizedImage(fileId, newWidth).then(file => {
      dispatch(getEditedImageUploadSucceeded(Object.assign({
        file
      }, getTextForUploadEditedImgSuccess(file))));
      if (onSaveComplete) {
        onSaveComplete(file.get('url'), file);
      }
    }, () => {
      dispatch(getEditedImageUploadFailed());
    });
  };
}

// prefer `uploadDashboardFiles/uploadPickerFiles` for UI initiated uploads, as they have validations and contextual notifications
export const uploadFiles = (fileList, options) => (dispatch, getState) => {
  const files = Array.prototype.slice.call(fileList);
  const readOnlyReason = getReadOnlyReason(getState());
  if (readOnlyReason) {
    return handleUploadInReadOnly(readOnlyReason);
  }
  if (files.length > MAX_FILE_UPLOAD_TOTAL) {
    return dispatch(handleUploadBatchOverLimit(files));
  }
  dispatch({
    type: BULK_UPLOAD_FILES_ATTEMPTED,
    folderId: options.folderId,
    uploadingFiles: files
  });
  return allSettled(files.map(file => dispatch(uploadFile(file, options))));
};
export function replaceFile(existingFile, localFile) {
  return dispatch => {
    const fileId = existingFile.get('id');
    const progress = event => {
      dispatch({
        type: REPLACE_FILE_PROGRESS,
        progress: event.loaded / event.total * 95,
        fileId
      });
    };
    const {
      extension
    } = splitNameAndExtension(localFile);
    const type = getType(extension);
    const isImage = type === FileTypes.IMG;
    let tempUrl = '';
    if (canPreviewLocalFile(localFile)) {
      tempUrl = window.URL.createObjectURL(localFile);
    }
    const upload = (dimensions = {}) => {
      dispatch({
        type: REPLACE_FILE_ATTEMPTED,
        tempFile: localFile,
        dimensions,
        tempUrl,
        fileId
      });
      return FilesApi.replaceFile(fileId, localFile, getFileAccess(existingFile), {
        updateProgress: progress
      }).then(response => {
        let updatedFile = response.getIn(['objects', 0]);
        updatedFile = updatedFile.merge({
          replaced: true
        });
        dispatch({
          type: REPLACE_FILE_SUCCEEDED,
          file: updatedFile
        });
        if (tempUrl) {
          window.URL.revokeObjectURL(tempUrl);
        }
      }, error => {
        let message = I18n.text('FileManagerCore.notifications.replace.error.message', {
          filename: existingFile.get('name')
        });
        const errorData = getUploadError(error);
        if (errorData && errorData.errorType === 'VIRUS_FOUND') {
          message = I18n.text('FileManagerCore.notifications.replace.error.virusFoundMessage', {
            filename: existingFile.get('name')
          });
        }
        if (errorData && errorData.message.match(/Cannot replace .* with the file .* that has the different extension/gm)) {
          message = I18n.text('FileManagerCore.notifications.replace.error.extensionMismatchMessage', {
            filename: existingFile.get('name'),
            extension
          });
        }
        dispatch({
          type: REPLACE_FILE_FAILED,
          meta: {
            notification: {
              type: 'danger',
              titleText: I18n.text('FileManagerCore.notifications.replace.error.title'),
              message
            }
          },
          file: existingFile,
          error
        });
        if (tempUrl) {
          window.URL.revokeObjectURL(tempUrl);
        }
      });
    };
    if (isImage && tempUrl) {
      getAsyncDimensions(tempUrl).then(upload);
    } else {
      upload();
    }
  };
}
export const downloadFromExternalUrl = (url, uploadedFileAccess,
//@ts-expect-error Issue with destructuring empty object
{
  name
} = {}) => dispatch => {
  dispatch({
    type: DOWNLOAD_FROM_EXTERNAL_URL_ATTEMPTED
  });
  const fileDownloadOptions = {
    name,
    folderPath: IMPORTED_IMAGE_FOLDER_PATH
  };
  return FilesApi.downloadFromUrl(fileDownloadOptions, url, uploadedFileAccess).then(file => {
    dispatch({
      type: DOWNLOAD_FROM_EXTERNAL_URL_SUCCEEDED,
      file
    });
    return file;
  }).catch(err => {
    reportError(err, {
      type: 'DOWNLOAD_FROM_EXTERNAL_URL'
    });
    throw err;
  });
};
function getCreateVidyardPlayerSucceededAction(file) {
  return {
    type: CREATE_VIDEO_PLAYERID_SUCCEEDED,
    file
  };
}
function getCreateVidyardPlayerAttemptedAction() {
  return {
    type: CREATE_VIDEO_PLAYERID_ATTEMPTED
  };
}
function getCreateVidyardPlayerFailedAction() {
  return {
    type: CREATE_VIDEO_PLAYERID_FAILED,
    meta: {
      notification: {
        type: 'danger',
        titleText: I18n.text('FileManagerCore.notifications.syncToVidyard.error.title'),
        message: I18n.text('FileManagerCore.notifications.syncToVidyard.error.message')
      }
    }
  };
}
export function setOnSyncHubLVideoState(file) {
  console.log(file.toJS());
  return dispatch => {
    dispatch(getCreateVidyardPlayerAttemptedAction());
    VideoPlayersApi.createPlayerForVideo(file.get('id')).then(newHostingInfo => {
      dispatch(getCreateVidyardPlayerSucceededAction(getUpdatedPlayerIdVideoFile(file, newHostingInfo)));
    }, () => dispatch(getCreateVidyardPlayerFailedAction()));
  };
}
function getDeleteVideoPlayerFailedAction() {
  return {
    type: SOFT_DELETE_VIDEO_PLAYERID_FAILED,
    meta: {
      notification: {
        type: 'danger',
        titleText: I18n.text('FileManagerCore.notifications.unsyncFromVidyard.error.title'),
        message: I18n.text('FileManagerCore.notifications.unsyncFromVidyard.error.message')
      }
    }
  };
}
function getDeleteVideoPlayerSucceededAction(file) {
  return {
    type: SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED,
    file
  };
}
export function softDeleteHubLVideoPlayer(playerId, file) {
  return dispatch => {
    dispatch({
      type: SOFT_DELETE_VIDEO_PLAYERID_ATTEMPTED,
      file
    });
    VideoPlayersApi.softDeleteHubLVideoPlayer(playerId).then(() => dispatch(getDeleteVideoPlayerSucceededAction(softDeleteVideoPlayerIdFromFile(file, playerId))), () => dispatch(getDeleteVideoPlayerFailedAction()));
  };
}
export function getSelectImageToEditAction(file, selectedImgFrom) {
  return {
    type: IMAGE_TO_EDIT_SELECTED,
    file,
    selectedImgFrom
  };
}
export function getDeselectImageToEditAction() {
  return {
    type: IMAGE_TO_EDIT_DESELECTED
  };
}
export function getFetchSignedURLFailedAction() {
  return {
    type: FETCH_SIGNED_URL_FAILED,
    meta: {
      notification: getFetchSignedURLFailedNotification()
    }
  };
}