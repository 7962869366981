import { createSelector } from 'reselect';
import { ObjectCategory, RequestStatus } from 'FileManagerCore/Constants';
import { getFoldersById } from 'FileManagerCore/selectors/Folders';
import { getFiles } from 'FileManagerCore/selectors/Files';
import { getParentFolder } from 'FileManagerCore/utils/FoldersAndFiles';
import { getSingleFileDetails } from 'FileManagerCore/selectors/FileDetails';
import { MODAL_REQUEST_TYPES } from '../types/redux/ModalDisplay';
export const getOpenModalName = state => state.modalDisplay.get('openModalName');
const getRequests = state => state.modalDisplay.get('requests');
export const getSelectedObjectInfo = state => {
  return {
    objectCategory: state.modalDisplay.get('objectCategory'),
    objectId: state.modalDisplay.get('objectId')
  };
};
export const getModalDisplayMeta = state => state.modalDisplay;
export const selectIsRenameFolderRequestPending = createSelector([getRequests], requests => requests.get(MODAL_REQUEST_TYPES.renameFolder) === RequestStatus.PENDING);
export const getIsMoveRequestPending = createSelector([getRequests], requests => requests.get(MODAL_REQUEST_TYPES.moveFile) === RequestStatus.PENDING || requests.get(MODAL_REQUEST_TYPES.moveFolder) === RequestStatus.PENDING);
export const getReplaceFiles = state => state.modalDisplay.get('files');
export const getSelectedFolderOrFile = createSelector([getFoldersById, getFiles, getSelectedObjectInfo, getSingleFileDetails], (folders, files, selectedObjectInfo, fileDetails) => {
  if (selectedObjectInfo.objectId && selectedObjectInfo.objectCategory === ObjectCategory.FOLDER) {
    return folders.get(selectedObjectInfo.objectId);
  } else if (selectedObjectInfo.objectId && selectedObjectInfo.objectCategory === ObjectCategory.FILE) {
    return fileDetails.get(selectedObjectInfo.objectId) || files.find(f => f.get('id') === selectedObjectInfo.objectId);
  }
  return null;
});
export const getParentFolderForSelectedObject = createSelector([getFoldersById, getSelectedFolderOrFile], (folders, selectedObject) => selectedObject ? getParentFolder(selectedObject, folders) : null);
export const getPartitioningStatus = createSelector([getRequests], requests => requests.get(MODAL_REQUEST_TYPES.partition));
const getBulkSelectedFileIds = state => state.modalDisplay.get('bulkSelectedFileIds');
const getBulkSelectedFolderIds = state => state.modalDisplay.get('bulkSelectedFolderIds');
export const getBulkSelectedFiles = createSelector([getFiles, getBulkSelectedFileIds], (files, fileIds) => fileIds.map(id => files.get(id)));
export const getBulkSelectedFolders = createSelector([getFoldersById, getBulkSelectedFolderIds], (folders, folderIds) => folderIds.map(id => folders.get(id)));
export const getInAppVideoKey = createSelector([getModalDisplayMeta], modalDisplay => modalDisplay.get('videoKey'));