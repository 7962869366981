import { reportError } from '../utils/logging';
import { SEARCH_FILES_AND_FOLDERS_FAILED, SEARCH_FILES_AND_FOLDERS_ATTEMPTED, SEARCH_FILES_AND_FOLDERS_SUCCESS } from './ActionTypes';
import { trackInteraction, trackOnce } from './tracking';
import { getRecentlyUploadedFiles } from '../selectors/FileDetails';
import { containsDoubleByteUnicode } from '../utils/stringUtils';
import { fetchFilesAndFoldersFromPublicSearch } from '../api/FilesAndFoldersSearch';
import { buildFilesAndFoldersResponsePayload, mergeRecentlyUploadedFilesWithFolders } from '../utils/crmSearch';
import { getModifiedOptionsWithFileTypeSearch } from './FileSearch';
import { fetchInitialFolderById } from './FolderFetch';
import { RequestStatus } from '../Constants';
import { selectIsUngatedForAiPoweredSearch } from '../selectors/Auth';
const searchFilesAndFoldersAttempted = (query, initialFetch, fetchingMore) => ({
  type: SEARCH_FILES_AND_FOLDERS_ATTEMPTED,
  status: RequestStatus.PENDING,
  query,
  initialFetch,
  fetchingMore
});
export const searchFilesAndFoldersSuccess = (query, data, initialFetch, fetchingMore) => ({
  type: SEARCH_FILES_AND_FOLDERS_SUCCESS,
  status: RequestStatus.SUCCEEDED,
  query,
  data,
  initialFetch,
  fetchingMore
});
const searchFilesAndFoldersFailed = (query, error) => ({
  type: SEARCH_FILES_AND_FOLDERS_FAILED,
  status: RequestStatus.NOTFOUND,
  query,
  error
});

/**
 * Warning to consumers: the returning "total" for this endpint refers to the combined total for files and folders.
 * If you need to fetch the total files or folders count separately for a given search or position in the folder tree,
 * You are better off using other thunks such as searchFiles or searchFolders (or others).
 * You could also use the files-public-search/v1/count API
 */
export const searchFilesAndFolders = (searchOptions, {
  initialFetch = false,
  fetchingMore = false,
  currentFolderId = undefined
} = {}) => async (dispatch, getState) => {
  const folderId = searchOptions.folder_id || currentFolderId;
  const folderIdSanitized = folderId === 'None' ? null : folderId;

  // If this is a first fetch, make sure we fetch any breadcrumbs that we need
  // After the first fetch, breadcrumb fetches will be handled by the Dashboard component
  if (initialFetch && folderIdSanitized) {
    dispatch(fetchInitialFolderById(folderIdSanitized));
  }
  const recentlyUploadedFiles = getRecentlyUploadedFiles(getState(), {
    folderId
  });
  const shouldMergeRecentlyUploadedFiles = !fetchingMore && !searchOptions.search && !searchOptions.archived;
  if (searchOptions.search) {
    searchOptions = getModifiedOptionsWithFileTypeSearch(searchOptions);
  }
  dispatch(searchFilesAndFoldersAttempted(searchOptions, initialFetch, fetchingMore));
  if (searchOptions.extension) {
    dispatch(trackInteraction('Manage Files', 'search-by-extension', {
      fileExtension: searchOptions.extension
    }));
  }
  if (searchOptions.search && containsDoubleByteUnicode(searchOptions.search)) {
    dispatch(trackOnce('Manage Files', 'search-with-fuzzy-unicode'));
  }
  try {
    const useSemanticSearch = selectIsUngatedForAiPoweredSearch(getState());
    const data = await fetchFilesAndFoldersFromPublicSearch(searchOptions, useSemanticSearch);
    let resp = buildFilesAndFoldersResponsePayload(data);
    if (shouldMergeRecentlyUploadedFiles) {
      resp = mergeRecentlyUploadedFilesWithFolders(resp, recentlyUploadedFiles);
    }
    dispatch(searchFilesAndFoldersSuccess(searchOptions, resp, initialFetch, fetchingMore));
  } catch (err) {
    // Workaround for errors in catch clauses not being typeable
    const error = err;
    const reportToNewRelic = error.status !== 0;
    reportError(err, {
      type: SEARCH_FILES_AND_FOLDERS_FAILED
    }, {
      reportToNewRelic
    });
    dispatch(searchFilesAndFoldersFailed(searchOptions, error));
  }
};