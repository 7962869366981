// Max search query length before CRM search begins to behave unexpectedly
export const MAX_SEARCH_QUERY_LENGTH = 50;
export const SEARCH_QUERY_WARN_LENGTH = MAX_SEARCH_QUERY_LENGTH - 5;

// This limit is only used in testing
export const MAX_IMG_SIZE_FOR_THUMBNAIL = 999999;

//The below limits are all based on recommendations from BE to avoid unexpected timeouts
export const FOLDER_BY_PARENT_REQUEST_LIMIT = 500;
export const FOLDER_SEARCH_REQUEST_LIMIT = 500;
export const FOLDER_BY_PARENT_CUTOFF = FOLDER_SEARCH_REQUEST_LIMIT * 2;
export const MAX_OBJECT_REQUEST_LIMIT = 500;
export const MAX_FILE_UPLOAD_TOTAL = 100;