import { Map as ImmutableMap } from 'immutable';
import * as ActionTypes from './ActionTypes';
import * as RequestType from '../enums/RequestType';
import { RequestStatus, ROOT_FOLDER_ID } from '../Constants';
import { reportError, reportMessage } from '../utils/logging';
import * as foldersApi from '../api/FolderFetch';
import { hasFolderRequestInitialized, hasFolderRequestSucceeded } from '../selectors/Folders';
import { FOLDER_BY_PARENT_CUTOFF, FOLDER_BY_PARENT_REQUEST_LIMIT, FOLDER_SEARCH_REQUEST_LIMIT } from '../constants/CrmPlatformLimits';
import { addLeadingSlashToPath } from '../utils/stringUtils';
import { buildFolderMap } from '../utils/FoldersAndFiles';
import { buildFoldersResponsePayload, transformCrmSearchFolder } from '../utils/crmSearch';
function fetchFoldersAttempt({
  initialFetch,
  requestType,
  requestLookupKey
}) {
  return {
    type: ActionTypes.FETCH_FOLDERS_ATTEMPTED,
    status: RequestStatus.PENDING,
    initialFetch,
    requestType,
    requestLookupKey
  };
}
export function fetchFoldersSuccess(data, {
  initialFetch = false,
  requestType,
  requestLookupKey
} = {}) {
  return {
    type: ActionTypes.FETCH_FOLDERS_SUCCEEDED,
    status: RequestStatus.SUCCEEDED,
    data,
    initialFetch,
    requestType,
    requestLookupKey
  };
}
function searchFoldersSuccess(data, searchTerm, {
  initialFetch = false
}) {
  return {
    type: ActionTypes.FETCH_FOLDERS_SUCCEEDED,
    status: RequestStatus.SUCCEEDED,
    data,
    initialFetch,
    requestType: RequestType.bySearchTerm,
    requestLookupKey: searchTerm
  };
}
function fetchFoldersFailed({
  initialFetch,
  status = RequestStatus.FAILED,
  requestType,
  requestLookupKey
}) {
  return {
    type: ActionTypes.FETCH_FOLDERS_FAILED,
    status,
    initialFetch,
    requestType,
    requestLookupKey
  };
}
export const fetchFoldersByParentId = (parentFolderId, {
  initialFetch = false
} = {}) => (dispatch, getState) => {
  parentFolderId = parentFolderId || ROOT_FOLDER_ID;
  if (hasFolderRequestSucceeded(getState(), RequestType.byParentId, parentFolderId)) {
    return null;
  }
  dispatch(fetchFoldersAttempt({
    initialFetch,
    requestType: RequestType.byParentId,
    requestLookupKey: parentFolderId
  }));
  const fetchOptions = {
    parent_folder_id: parentFolderId === ROOT_FOLDER_ID ? 'None' : parentFolderId
  };
  return foldersApi.fetchFoldersFromPublicSearch(fetchOptions).then(resp => {
    let subfolderData = buildFoldersResponsePayload(resp);
    if (subfolderData.get('total') > FOLDER_BY_PARENT_REQUEST_LIMIT) {
      if (subfolderData.get('total') > FOLDER_BY_PARENT_CUTOFF) {
        reportMessage('Found more folders within a parent than we will load', {
          total: subfolderData.get('total'),
          limit: FOLDER_BY_PARENT_CUTOFF,
          parentFolderId
        });
      }
      if (initialFetch) {
        return foldersApi.fetchFoldersFromPublicSearch(Object.assign({}, fetchOptions, {
          offset: FOLDER_BY_PARENT_REQUEST_LIMIT
        })).then(remainingFolderData => {
          const remainingFolders = buildFolderMap(remainingFolderData.results.map(transformCrmSearchFolder));
          subfolderData = subfolderData.updateIn(['objects'], objects => objects.merge(remainingFolders));
          dispatch(fetchFoldersSuccess(subfolderData, {
            requestType: RequestType.byParentId,
            requestLookupKey: parentFolderId,
            initialFetch
          }));
          return subfolderData;
        }).catch(err => {
          reportError(err, {
            type: 'INITIAL_FETCH_FOLDERS_FROM_PUBLIC_SEARCH_FAILED',
            parentFolderId,
            initialFetch
          });
        });
      }
    }
    dispatch(fetchFoldersSuccess(subfolderData, {
      requestType: RequestType.byParentId,
      requestLookupKey: parentFolderId,
      initialFetch
    }));
    return subfolderData;
  }).catch(err => {
    reportError(err, {
      type: 'FETCH_FOLDERS_FROM_PUBLIC_SEARCH_FAILED',
      parentFolderId,
      initialFetch
    });
    dispatch(fetchFoldersFailed({
      requestType: RequestType.byParentId,
      requestLookupKey: parentFolderId,
      initialFetch
    }));
  });
};
export const fetchInitialFolderByPath = folderPath => dispatch => {
  dispatch(fetchFoldersAttempt({
    initialFetch: true
  }));
  return foldersApi.fetchFolderWithBreadcrumbs(folderPath).then(data => {
    const objects = buildFolderMap(data);
    const resp = ImmutableMap({
      objects,
      total: objects.size
    });
    const initialFolder = objects.find(f => f.get('full_path') === addLeadingSlashToPath(folderPath));
    if (!initialFolder) {
      throw new Error('Could not find initial folder by path');
    }
    dispatch(fetchFoldersSuccess(resp, {
      initialFetch: true
    }));
    return initialFolder;
  }).catch(err => {
    reportError(err, {
      type: 'FETCH_FOLDER_WITH_BREADCRUMBS_BY_PATH_FAILED',
      folderPath
    });
    dispatch(fetchFoldersFailed({
      initialFetch: true,
      status: RequestStatus.NOTFOUND
    }));
    throw err;
  });
};
export const fetchInitialFolderById = folderId => dispatch => {
  dispatch(fetchFoldersAttempt({
    initialFetch: true,
    requestType: RequestType.byParentId,
    requestLookupKey: folderId
  }));
  return foldersApi.fetchFolderWithBreadcrumbs(folderId).then(data => {
    const objects = buildFolderMap(data);
    const resp = ImmutableMap({
      objects,
      total: objects.size
    });
    dispatch(fetchFoldersSuccess(resp, {
      initialFetch: true,
      requestType: RequestType.byParentId,
      requestLookupKey: folderId
    }));
  }).catch(err => {
    reportError(err, {
      type: 'FETCH_FOLDER_WITH_BREADCRUMBS_BY_ID',
      folderId
    });
    dispatch(fetchFoldersFailed({
      initialFetch: true,
      status: RequestStatus.NOTFOUND,
      requestType: RequestType.byParentId,
      requestLookupKey: folderId
    }));
  });
};
export const fetchFolderByIdWithBreadcrumbs = id => dispatch => {
  foldersApi.fetchFolderWithBreadcrumbs(id).then(data => {
    const resp = ImmutableMap({
      objects: buildFolderMap(data)
    });
    dispatch(fetchFoldersSuccess(resp, {
      requestType: RequestType.parentBreadcrumbs,
      requestLookupKey: id
    }));
  }).catch(err => {
    reportError(err, {
      type: 'FETCH_FOLDER_WITH_BREADCRUMBS_FAILED'
    });
  });
};
export const searchFolders = (searchTerm, {
  initialFetch = false,
  initialFolderId
} = {}) => (dispatch, getState) => {
  const options = {
    search: searchTerm
  };
  if (initialFetch) {
    if (initialFolderId) {
      dispatch(fetchInitialFolderById(initialFolderId));
    } else {
      dispatch(fetchFoldersByParentId(ROOT_FOLDER_ID, {
        initialFetch
      }));
    }
  }
  if (!searchTerm) {
    return Promise.resolve();
  }
  if (hasFolderRequestInitialized(getState(), RequestType.bySearchTerm, searchTerm)) {
    return Promise.resolve();
  }
  dispatch(fetchFoldersAttempt({
    requestType: RequestType.bySearchTerm,
    requestLookupKey: searchTerm
  }));
  return foldersApi.fetchFoldersFromPublicSearch(options).then(data => {
    dispatch(searchFoldersSuccess(buildFoldersResponsePayload(data), searchTerm, {
      initialFetch
    }));
  }).catch(err => {
    reportError(err, {
      type: 'FETCH_FOLDERS_FROM_PUBLIC_SEARCH_FAILED',
      initialFetch
    });
  });
};
export const fetchRecentlyUpdatedFolders = ({
  limit = FOLDER_SEARCH_REQUEST_LIMIT
}) => dispatch => {
  const options = {
    limit,
    order_by: '-updatedAt'
  };
  dispatch(fetchFoldersAttempt({
    requestType: RequestType.recentlyUpdated,
    requestLookupKey: RequestType.recentlyUpdated
  }));
  return foldersApi.fetchFoldersFromPublicSearch(options).then(data => {
    dispatch(fetchFoldersSuccess(buildFoldersResponsePayload(data), {
      requestType: RequestType.recentlyUpdated,
      requestLookupKey: RequestType.recentlyUpdated
    }));
  });
};
export const fetchFolderCount = folderId => dispatch => foldersApi.fetchFoldersCountFromPublicSearch([folderId]).then(data => {
  dispatch({
    type: ActionTypes.FETCH_FOLDER_COUNT_SUCCEEDED,
    payload: {
      [folderId]: data
    }
  });
});