import keyMirror from 'react-utils/keyMirror';
import Immutable from 'immutable';
import { TRY_VIDEO_PATH, HUSBPOT_VIDEO_PATH } from 'FileManagerCore/Constants';
import { VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE } from 'FileManagerCore/enums/InternalFileManagerFileAccess';
import { SortDirection } from './enums/SortDirection';
export const LOCALSTORAGE_MOBILE_BRIDGE_VIEW_COUNT_KEY = 'fileManagerMobileBridgeViewCount';
export const LOCALSTORAGE_PAGE_SIZE_KEY = 'fileManagerDashboardPageSize';
export const PAGE_SIZES = [10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = PAGE_SIZES[0];
export const MAX_SELECTABLE_ITEMS = 100;
export const Tabs = keyMirror({
  DASHBOARD: null,
  STOCK_IMAGES: null
});
export const DISABLED_TABS = [Tabs.STOCK_IMAGES];
export const TabPaths = {
  '/': Tabs.DASHBOARD,
  '/stock-images': Tabs.STOCK_IMAGES
};
export const QueryParams = Object.freeze({
  query: 'query',
  sortDirection: 'sortDirection',
  orderBy: 'orderBy',
  page: 'page',
  archived: 'archived',
  fileType: 'fileType',
  folderId: 'folderId',
  isHublVideo: 'isHublVideo'
});
export const DefaultQuery = Immutable.Map({
  sortDirection: SortDirection.ASC,
  orderBy: 'name',
  page: 1,
  archived: false,
  fileType: null,
  folderId: null
});
export const FolderValidationError = keyMirror({
  INVALID_CHARS: null,
  TOO_LONG: null,
  DUPLICATE: null
});
export const ResourceTypes = keyMirror({
  BLOG_LISTING_PAGE: null,
  BLOG_POST: null,
  EMAIL: null,
  LANDING_PAGE: null,
  SITE_PAGE: null,
  TEMPLATE: null,
  UNKNOWN: null,
  KNOWLEDGE_ARTICLE: null
});
export const PathParams = {
  TRY_VIDEO: TRY_VIDEO_PATH,
  HUBSPOT_VIDEO: HUSBPOT_VIDEO_PATH,
  SEARCH: '/search'
};
export const FILE_MANAGER_DASHBOARD_DEFAULT_ACCESS = VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE;
export const MobileLinks = {
  googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=com.hubspot.android&referrer=utm_source%3Dbridge%26utm_medium%3Dfiles%26anid%3Dadmob',
  appleAppStore: 'https://apps.apple.com/app/apple-store/id1107711722?pt=391837&ct=files-bridge-web&mt=8'
};