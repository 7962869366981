'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["pathname"],
  _excluded2 = ["type"];
import { NAVIGATE, SEARCH, NAVIGATE_TO_FOLDER, ARCHIVE, SORT, SELECT_FILE, FILTER_FILE_TYPE } from '../../actions/ActionTypes';
import { CHANGE_TAB, BULK_UPLOAD_FILES_ATTEMPTED, SEARCH_FILES_ATTEMPTED } from 'FileManagerCore/actions/ActionTypes';
import pick from 'FileManagerCore/utils/pick';
import { Tabs } from '../../Constants';
import { SortDirection } from '../../enums/SortDirection';
import { FILE_DETAILS_QUERY_PARAM_NAME, FOLDER_PATH_QUERY_PARAM_NAME, SEARCH_QUERY_PARAM_NAME } from '../urls';
import { parseSearchString, stringifyQueryObject } from 'FileManagerCore/utils/urls';
function filterObject(obj) {
  Object.keys(obj).forEach(param => {
    if (!obj[param] && typeof obj[param] !== 'boolean') {
      delete obj[param];
    }
  });
}
const SUPPORTED_QUERY_PARAMS = [FOLDER_PATH_QUERY_PARAM_NAME, SEARCH_QUERY_PARAM_NAME, FILE_DETAILS_QUERY_PARAM_NAME, 'folderId', 'orderBy', 'sortDirection', 'archived', 'page', 'type', 'orientation', 'sort', 'fileType', 'isHublVideo'];
export function getMergedLocation(currentLocation, _ref) {
  let {
      pathname
    } = _ref,
    newSearchQuery = _objectWithoutPropertiesLoose(_ref, _excluded);
  const {
    pathname: currentPathname,
    search: currentSearch
  } = currentLocation;
  const currentQuery = parseSearchString(currentSearch);
  newSearchQuery = pick(newSearchQuery, SUPPORTED_QUERY_PARAMS);
  const mergedQuery = Object.assign({}, currentQuery, newSearchQuery);
  filterObject(mergedQuery);
  if (mergedQuery.page === 1) {
    delete mergedQuery.page;
  }
  if (mergedQuery[FOLDER_PATH_QUERY_PARAM_NAME] === '/') {
    delete mergedQuery[FOLDER_PATH_QUERY_PARAM_NAME];
  }
  const location = {
    pathname: pathname || currentPathname,
    search: stringifyQueryObject(mergedQuery)
  };
  return location;
}
export default (history => {
  function goToMergedLocation(params, replace) {
    const location = getMergedLocation(history.location, params);
    if (replace) {
      history.replace(location);
    } else {
      history.push(location);
    }
  }
  function handleSearch(state, {
    search
  }) {
    const {
      search: currentSearch
    } = history.location;
    goToMergedLocation(Object.assign({}, parseSearchString(currentSearch), {
      pathname: search ? 'search' : '/',
      [SEARCH_QUERY_PARAM_NAME]: search,
      page: 1
    }));
  }
  function handleNavigateToFolder({
    folderId
  }) {
    const {
      search
    } = history.location;
    goToMergedLocation(Object.assign({}, parseSearchString(search), {
      pathname: '/',
      query: '',
      page: 1,
      folderId,
      folderPath: ''
    }));
  }
  function handleOpenDetailsPanel(fileId) {
    const {
      search
    } = history.location;
    goToMergedLocation(Object.assign({}, parseSearchString(search), {
      [FILE_DETAILS_QUERY_PARAM_NAME]: fileId
    }), true);
  }
  function handleCloseDetailsPanel() {
    const {
      search
    } = history.location;
    goToMergedLocation(Object.assign({}, parseSearchString(search), {
      [FILE_DETAILS_QUERY_PARAM_NAME]: ''
    }), true);
  }
  function handleUpload({
    folderId
  }) {
    goToMergedLocation({
      folderId,
      page: 1,
      sortDirection: SortDirection.DESC,
      orderBy: 'updated',
      archived: false
    });
  }
  function handleChangeTab({
    tab,
    searchQuery
  }) {
    switch (tab) {
      case Tabs.DASHBOARD:
        history.push(searchQuery ? `search?query=${searchQuery}` : '');
        break;
      case Tabs.STOCK_IMAGES:
        history.push(searchQuery ? `/stock-images?query=${searchQuery}` : 'stock-images');
        break;
      default:
    }
  }
  return store => next => action => {
    const _next = next(action),
      {
        type
      } = _next,
      result = _objectWithoutPropertiesLoose(_next, _excluded2);
    const state = store.getState();
    const location = history.location;
    switch (type) {
      case CHANGE_TAB:
        handleChangeTab(result);
        break;
      case NAVIGATE:
        goToMergedLocation(result.location, result.replace);
        break;
      case SEARCH:
        handleSearch(state, result);
        break;
      case NAVIGATE_TO_FOLDER:
        handleNavigateToFolder(result);
        break;
      case BULK_UPLOAD_FILES_ATTEMPTED:
        handleUpload(result);
        break;
      case SELECT_FILE:
        if (result.file) {
          handleOpenDetailsPanel(result.file.get('id'));
        } else if (parseSearchString(location.search)[FILE_DETAILS_QUERY_PARAM_NAME]) {
          handleCloseDetailsPanel();
        }
        break;
      case ARCHIVE:
      case SORT:
        goToMergedLocation(Object.assign({}, result, {
          page: 1
        }), true);
        break;
      case FILTER_FILE_TYPE:
        {
          const fileType = result.fileType ? result.fileType.toLowerCase() : null;
          const isHublVideo = result.isHublVideo;
          goToMergedLocation({
            fileType,
            isHublVideo,
            page: 1
          }, true);
          break;
        }
      case SEARCH_FILES_ATTEMPTED:
        if (!parseSearchString(location.search).folderId && result.query.folder_id && result.query.folder_id !== 'None') {
          goToMergedLocation({
            folderId: result.query.folder_id
          }, true);
        }
        break;
      default:
        break;
    }
    return result;
  };
});