import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import enviro from 'enviro';
import { matches } from 'UIComponents/utils/Dom';
const cancelDragEvent = e => {
  e.preventDefault();
  e.stopPropagation();
};
const isDebug = () => enviro.debug('filedrop');
const logDebug = (...args) => {
  if (isDebug()) {
    console.log.apply(console, ['[FileDragAndDrop]', ...args]);
  }
};
const matchesSelector = (el, className) => {
  if (el instanceof SVGElement) {
    return false;
  }
  return matches(el, className);
};
const matchesOrWithinSelector = (el, className) => {
  if (matchesSelector(el, className)) {
    return true;
  }
  while (el && el.parentElement) {
    if (matchesSelector(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

// the event handlers need to be applied to a child element in order to function
// `isOver` is optional, use if styling needs to be updated while a file has been dragged within the element
const FileDragAndDrop = ({
  children,
  disabled = false,
  ignoreTargetSelector,
  onDropFiles
}) => {
  const [isOver, setIsOver] = useState(false);
  const isOverRef = useRef(null);
  const onDragEnter = useCallback(e => {
    if (ignoreTargetSelector && matchesOrWithinSelector(e.target, ignoreTargetSelector)) {
      logDebug('ignoring onDragEnter on target to parent classname', e.target, ignoreTargetSelector);
      return;
    } else {
      logDebug('onDragEnter', e.target);
    }
    e.preventDefault();
    isOverRef.current = e.target;
    setIsOver(true);
  }, [ignoreTargetSelector]);
  const onDragOver = useCallback(e => {
    cancelDragEvent(e);
  }, []);
  const onDragLeave = useCallback(e => {
    if (isOverRef.current === e.target) {
      isOverRef.current = null;
      setIsOver(false);
    }
  }, []);
  const onDrop = useCallback(e => {
    if (ignoreTargetSelector && e.target instanceof Element && matchesOrWithinSelector(e.target, ignoreTargetSelector)) {
      logDebug('ignoring onDrop on target to parent classname', e.target, ignoreTargetSelector);
      return;
    } else {
      if (isDebug()) {
        e.persist();
        logDebug('onDrop', e);
      }
    }
    const files = e.dataTransfer ? e.dataTransfer.files : [];
    if (files && files.length) {
      e.preventDefault();
      onDropFiles(Array.from(files));
      setIsOver(false);
    }
  }, [ignoreTargetSelector, onDropFiles]);
  if (disabled) {
    return children({
      isOver: false,
      onDragOver: cancelDragEvent,
      onDrop: cancelDragEvent
    });
  }
  return children({
    isOver,
    onDrop,
    onDragEnter,
    onDragOver,
    onDragLeave
  });
};
FileDragAndDrop.propTypes = {
  children: PropTypes.func.isRequired,
  onDropFiles: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  ignoreTargetSelector: PropTypes.string
};
FileDragAndDrop.defaultProps = {
  disabled: false
};
export default FileDragAndDrop;