import { Map as ImmutableMap } from 'immutable';
import { RequestStatus } from '../Constants';
import { FETCH_SUBTITLES_PENDING, FETCH_SUBTITLES_SUCCEEDED, FETCH_SUBTITLES_FAILED, EDIT_SUBTITLE_LANGUAGE, EDIT_SUBTITLE_FILE, CLEAR_SUBTITLES, UPDATE_SUBTITLE_PENDING, UPDATE_SUBTITLE_SUCCEEDED, UPDATE_SUBTITLE_FAILED, DELETE_SUBTITLE_LANGUAGE, UPLOAD_SUBTITLES_SUCCESS } from '../actions/ActionTypes';
import { defaultSubtitlesState } from '../types/redux/videoSubtitles';
import { sortSubtitlesByLanguage } from '../utils/videoSubtitles';
export default function (state = defaultSubtitlesState, action) {
  const {
    type,
    subtitles,
    selectedVideoFileId,
    editedLanguage,
    editedSubtitleFile
  } = action;
  const currentSubtitles = state.get('locallyLoadedSubtitles') || ImmutableMap({});
  const currentFetchRequestStatus = state.get('fetchSubtitlesRequestStatusByVideoFileId') || ImmutableMap();
  switch (type) {
    case FETCH_SUBTITLES_PENDING:
      if (selectedVideoFileId) {
        return state.merge({
          fetchSubtitlesRequestStatusByVideoFileId: currentFetchRequestStatus.set(selectedVideoFileId, RequestStatus.PENDING),
          selectedVideoFileId
        });
      }
      return state;
    case FETCH_SUBTITLES_FAILED:
      if (selectedVideoFileId) {
        return state.merge({
          fetchSubtitlesRequestStatusByVideoFileId: currentFetchRequestStatus.set(selectedVideoFileId, RequestStatus.FAILED)
        });
      }
      return state;
    case FETCH_SUBTITLES_SUCCEEDED:
      if (selectedVideoFileId) {
        if (subtitles) {
          return state.merge({
            fetchSubtitlesRequestStatusByVideoFileId: currentFetchRequestStatus.set(selectedVideoFileId, RequestStatus.SUCCEEDED),
            locallyLoadedSubtitles: currentSubtitles.set(selectedVideoFileId, subtitles)
          });
        } else {
          return state.merge({
            fetchSubtitlesRequestStatusByVideoFileId: currentFetchRequestStatus.set(selectedVideoFileId, RequestStatus.SUCCEEDED)
          });
        }
      }
      return state;
    case UPDATE_SUBTITLE_PENDING:
      return state.merge({
        updateSubtitlesRequestStatus: RequestStatus.PENDING
      });
    case UPDATE_SUBTITLE_SUCCEEDED:
      if (selectedVideoFileId) {
        return state.merge({
          updateSubtitlesRequestStatus: RequestStatus.SUCCEEDED,
          locallyLoadedSubtitles: subtitles === undefined ? currentSubtitles.remove(selectedVideoFileId) : currentSubtitles.set(selectedVideoFileId, subtitles),
          selectedVideoFileId
        });
      }
      return state;
    case UPDATE_SUBTITLE_FAILED:
      return state.merge({
        updateSubtitlesRequestStatus: RequestStatus.FAILED
      });
    case UPLOAD_SUBTITLES_SUCCESS:
      if (selectedVideoFileId && action.language && action.file) {
        const updatedVideoSubtitles = sortSubtitlesByLanguage(state.getIn(['locallyLoadedSubtitles', selectedVideoFileId]).set(action.language, action.file));
        return state.merge({
          locallyLoadedSubtitles: currentSubtitles.set(selectedVideoFileId, updatedVideoSubtitles),
          updateSubtitlesRequestStatus: RequestStatus.SUCCEEDED
        });
      }
      return state;
    case DELETE_SUBTITLE_LANGUAGE:
      if (selectedVideoFileId && action.language) {
        state = state.deleteIn(['locallyLoadedSubtitles', selectedVideoFileId, action.language]);
      }
      return state.merge({
        updateSubtitlesRequestStatus: RequestStatus.SUCCEEDED
      });
    case EDIT_SUBTITLE_LANGUAGE:
      return state.merge({
        editedLanguage
      });
    case EDIT_SUBTITLE_FILE:
      return state.merge({
        editedSubtitleFile
      });
    case CLEAR_SUBTITLES:
      return state.merge({
        editedLanguage: undefined,
        editedSubtitleFile: undefined,
        updateSubtitlesRequestStatus: RequestStatus.UNINITIALIZED,
        selectedVideoFileId: undefined
      });
    default:
      return state;
  }
}