import { SET_AUTH } from '../actions/ActionTypes';
export default function Auth(state, action) {
  const {
    type,
    auth
  } = action;
  switch (type) {
    case SET_AUTH:
      return auth;
    default:
      return state || {};
  }
}