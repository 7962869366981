import PortalIdParser from 'PortalIdParser';
import { FileTypes, ThumbnailSizes } from '../constants';
import { appendStaticAppInfo, getApiHostForEnvironment } from '../internal/utils';
import { getFileType, getIsFileExternal, getIsFileSensitive, getIsSvg, getIsSvgTooLarge, getIsTemporaryUploadingFile, splitNameAndExtension } from './file';
export function isImage(file) {
  return file.type && file.type.startsWith('image/');
}
const NO_LOCAL_PREVIEW_IMAGE_EXTENSIONS = ['svg', 'heic', 'tiff'];
export function canPreviewLocalFile(localFile) {
  const {
    extension
  } = splitNameAndExtension(localFile.name);
  return isImage(localFile) && !NO_LOCAL_PREVIEW_IMAGE_EXTENSIONS.includes(extension.toLowerCase());
}
export const getHasThumbnail = file => {
  if (getIsSvg(file.type, file.extension, file.encoding) && (getIsSvgTooLarge(file.size) || getIsTemporaryUploadingFile(file.id))) {
    return false;
  }
  if (getIsFileExternal(file.meta, file.url)) {
    return false;
  }
  if (getIsFileSensitive(file.meta)) {
    return false;
  }
  const fileType = getFileType(file.type, file.extension);
  return fileType === FileTypes.IMG || fileType === FileTypes.MOVIE;
};
export function getThumbnailUrl(fileId, thumbSize = ThumbnailSizes.THUMB, isFilePrivate = false) {
  let path = `//${getApiHostForEnvironment()}/filemanager/api/v3/files/thumbnail-redirect/${fileId}`;
  if (isFilePrivate) {
    path = `//${getApiHostForEnvironment()}/filemanager/api/v2/files/${fileId}/signed-url-redirect`;
  }
  const url = `${path}?size=${thumbSize}&portalId=${PortalIdParser.get()}`;
  return appendStaticAppInfo(url);
}