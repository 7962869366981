import { createSelector } from 'reselect';
import { RequestStatus } from '../Constants';
import { getIsFileBeingReplaced } from '../utils/file';
export const getFiles = state => state.files.get('objects');
export const getFilesTotal = state => state.files.get('total');
export const getFetchFilesRequestStatus = state => state.files.get('status');
export const getIsFetchingMore = state => state.files.get('fetchingMore');
export const getAreFilesLoading = createSelector([getFetchFilesRequestStatus, getIsFetchingMore], (requestStatus, fetchingMore) => requestStatus === RequestStatus.PENDING || fetchingMore);
export const getFilesQuery = state => state.files.get('query');
export const getSearchQuery = createSelector([getFilesQuery], query => query.get('search'));
export const getSearchExtension = createSelector([getFilesQuery], query => query.get('extension'));
export const getIsSearching = createSelector([getSearchQuery], search => Boolean(search));
export const getIsFilteringArchived = createSelector([getFilesQuery], query => Boolean(query.get('archived')));
export const getIsFilteringType = createSelector([getFilesQuery], query => Boolean(query.get('type')));
export const getIsFilteringExtension = createSelector([getFilesQuery], query => Boolean(query.get('extension')));
export const getVideoPlayerRequestStatus = state => state.files.get('videoPlayerRequestStatus');
export const getAreFilesBeingReplaced = createSelector([getFiles], files => files.some(getIsFileBeingReplaced));