import http from 'hub-http/clients/apiClient';
import { reportError } from '../utils/logging';
import { OptInStatus } from '../Constants';
export async function fetchFileAnalysisOptInStatus() {
  try {
    const response = await http.get('content-text-extraction/v1/portal-opt-in-status');
    return response;
  } catch (error) {
    reportError(error);
  }
  return new Promise(() => ({
    portalOptInStatus: {
      optInStatus: OptInStatus.OptOut
    }
  }));
}