'use es6';

import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { ObjectCategory } from 'FileManagerCore/Constants';
import { filterFolders, getParentFolder, getAncestors } from 'FileManagerCore/utils/FoldersAndFiles';
import { getFiles, getIsSearching, getIsFilteringArchived, getIsFilteringExtension, getIsFilteringType } from 'FileManagerCore/selectors/Files';
import { findFolderByPath, getFoldersById } from 'FileManagerCore/selectors/Folders';
import { getUploadingFiles } from 'FileManagerCore/selectors/UploadingFiles';
import { getSearchQueryFromUrl, getQueryParams } from './Query';
import { FOLDER_PATH_QUERY_PARAM_NAME, parseFileIdFromUrlParams } from '../utils/urls';
import { parseFolderIdFromLocation } from '../utils/location';
import { addLeadingSlashToPath } from 'FileManagerCore/utils/stringUtils';
import { getUserHasAccessToAsset } from 'FileManagerCore/utils/teams';
import { getIsUserSuperAdmin, getCurrentUserTeams } from 'FileManagerCore/selectors/Auth';
export const getFolderPathFromUrl = createSelector([getQueryParams], query => {
  const folderPath = query[FOLDER_PATH_QUERY_PARAM_NAME];
  if (folderPath && folderPath !== '/') {
    return addLeadingSlashToPath(folderPath);
  }
  return null;
});
export const getFolderIdFromUrl = (state, {
  location
} = {}) => parseFolderIdFromLocation(location);
export const getFileDetailsIdFromUrl = (state, {
  location
} = {}) => parseFileIdFromUrlParams(location);
export const getFolders = state => {
  return state.folders.get('objects').toList();
};
export const getIsFilteringFiles = createSelector([getIsFilteringArchived, getIsFilteringType], (filteringArchived, filteringType) => filteringArchived || filteringType);
export const getIsSearchingOrFiltering = createSelector([getIsSearching, getIsFilteringFiles, getIsFilteringExtension], (isSearching, isFiltering, isFilteringExtension) => isSearching || isFiltering || isFilteringExtension);
const filterFilesToCurrentFolder = (files, folders, currentFolderId, folderPath) => {
  if (currentFolderId) {
    return files.filter(f => f.get('folder_id') === currentFolderId);
  }
  if (folderPath) {
    const parentFolder = folders.find(folder => folder.get('full_path') === addLeadingSlashToPath(folderPath));
    if (parentFolder) {
      return files.filter(f => {
        return f.get('folder_id') === parentFolder.get('id');
      });
    }
  }
  return files;
};
export const getFilteredFolders = createSelector([getFoldersById, getFolderPathFromUrl, getSearchQueryFromUrl, getFolderIdFromUrl, getIsFilteringFiles, getCurrentUserTeams, getIsUserSuperAdmin], (folders, folderPath, searchQuery, folderId, isFilteringFiles) => {
  if (isFilteringFiles) {
    return Immutable.List();
  }
  return filterFolders(folders, folderPath, searchQuery, folderId).toList();
});
export const getFilteredFiles = createSelector([getFiles, getFolders, getFolderIdFromUrl, getFolderPathFromUrl, getIsSearchingOrFiltering], (files, folders, folderId, folderPath, isSearchingOrFiltering) => {
  if (isSearchingOrFiltering) {
    return files;
  }
  return filterFilesToCurrentFolder(files, folders, folderId, folderPath);
});
export const getFilteredUploadingFiles = createSelector([getUploadingFiles, getFolders, getFolderIdFromUrl, getFolderPathFromUrl, getSearchQueryFromUrl], (uploadingFiles, folders, folderId, folderPath, isSearchingOrFiltering) => {
  if (isSearchingOrFiltering) {
    return uploadingFiles;
  }
  return filterFilesToCurrentFolder(uploadingFiles, folders, folderId, folderPath);
});
export const getIsFetchingMore = state => state.files.get('fetchingMore');
export const getParentFolderForObject = createSelector([(state, props) => props.object, getFolders], getParentFolder);
export const getCurrentFolder = createSelector([getFoldersById, getFolderPathFromUrl, getFolderIdFromUrl], (foldersById, folderPath, folderId) => {
  if (!(folderPath || folderId)) {
    return null;
  }
  if (folderId) {
    return foldersById.get(folderId);
  }
  return findFolderByPath(foldersById, addLeadingSlashToPath(folderPath));
});
export const getCurrentFolderAncestors = createSelector([getCurrentFolder, getFoldersById], getAncestors);
export const getHasAnyFolders = createSelector([getFoldersById], folders => !folders.isEmpty());
export const getHasAccessToFolder = (state, props) => {
  const currentFolder = getCurrentFolder(state, props);
  return getUserHasAccessToAsset(state, currentFolder && currentFolder.get('id'), ObjectCategory.FOLDER);
};