import { useEffect } from 'react';
export function useConfirmNavigation(enable = true) {
  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = true;
    };
    if (enable) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enable]);
}
export const ConfirmNavigation = ({
  enable
}) => {
  useConfirmNavigation(enable);
  return null;
};