import * as SearchTypes from '../enums/SearchTypes';
const getFileSearchType = ({
  systemRecognizedFileExtension,
  searchQuery,
  fileTypeFilterValue
} = {}) => {
  const searchCriteria = [];
  if (searchQuery && searchQuery.length > 0) {
    searchCriteria.push('KEYWORDS');
  }
  if (systemRecognizedFileExtension) {
    searchCriteria.push('EXTENSION');
  }
  if (fileTypeFilterValue) {
    searchCriteria.push('FILETYPE');
  }
  if (searchCriteria.length === 0) {
    return SearchTypes.KEYWORDS;
  }
  return searchCriteria.join('_');
};
export default getFileSearchType;