import { RequestStatus } from 'FileManagerCore/Constants';
import { FETCH_SINGLE_FILE_ATTEMPTED, UPDATE_FILE_EXPIRATION_ATTEMPTED, UPDATE_FILE_EXPIRATION_FAILED, UPDATE_FILE_EXPIRATION_SUCCEEDED } from 'FileManagerCore/actions/ActionTypes';
const defaultState = {};
const FileExpiration = (state = defaultState, {
  type,
  fileId,
  ttl
}) => {
  switch (type) {
    case FETCH_SINGLE_FILE_ATTEMPTED:
      return Object.assign({}, state, {
        [fileId]: {
          status: RequestStatus.UNINITIALIZED
        }
      });
    case UPDATE_FILE_EXPIRATION_ATTEMPTED:
      return Object.assign({}, state, {
        [fileId]: {
          status: RequestStatus.PENDING,
          ttl: state[fileId] ? state[fileId].ttl : 0
        }
      });
    case UPDATE_FILE_EXPIRATION_SUCCEEDED:
      return Object.assign({}, state, {
        [fileId]: {
          ttl,
          status: RequestStatus.SUCCEEDED
        }
      });
    case UPDATE_FILE_EXPIRATION_FAILED:
      return Object.assign({}, state, {
        [fileId]: {
          status: RequestStatus.FAILED
        }
      });
    default:
      return state;
  }
};
export default FileExpiration;