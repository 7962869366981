/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

import * as ActionTypes from './ActionTypes';
import { setFolderPartitions } from '../api/Partitioning';
import { filePartitioningAlerts } from '../utils/notifications';
import { RequestStatus } from '../Constants';
export function updatePartitioning({
  folderId,
  teamIds
}) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_FOLDER_PARTITIONING_ATTEMPTED,
      status: RequestStatus.PENDING
    });
    setFolderPartitions({
      folderId,
      teamIds
    }).then(() => {
      dispatch({
        type: ActionTypes.CHANGE_FOLDER_PARTITIONING_SUCCEEDED,
        folderId,
        teamIds,
        meta: {
          notification: filePartitioningAlerts.success
        },
        status: RequestStatus.SUCCEEDED
      });
    }, error => {
      dispatch({
        type: ActionTypes.CHANGE_FOLDER_PARTITIONING_FAILED,
        meta: {
          notification: filePartitioningAlerts.error
        },
        status: RequestStatus.FAILED
      });
      throw error;
    });
  };
}