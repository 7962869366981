import apiClient from 'hub-http/clients/apiClient';
import Immutable from 'immutable';
const API_PATH = '/cosindex/api/v1/connections';
export function fetchParents(fileId) {
  return apiClient.get(`${API_PATH}/${fileId}/parents`, {
    query: {
      levels: 0,
      fields: 'BASIC'
    }
  }).then(Immutable.fromJS);
}