import { flattenTeams } from 'FileManagerCore/utils/teams';
function getAllParentAssetTeamsFromParentAssetLeafTeams({
  allTeams,
  parentAssetTeamIds
}) {
  const allParentAssetTeams = new Set();
  function traverse(team) {
    allParentAssetTeams.add(team.id);
    if (team.parentTeamId == null) {
      return;
    }

    // If the team has a parent, recursively traverse the parent
    const parentTeam = allTeams.find(t => t.id === team.parentTeamId);
    if (parentTeam) {
      traverse(parentTeam);
    }
  }

  // Find enabled leaf nodes and traverse up the tree
  allTeams.filter(team => parentAssetTeamIds.includes(team.id)).forEach(traverse);
  return Array.from(allParentAssetTeams);
}

/**
 * Parent assets (folders, for now) may provide all teams that have access to that asset, OR they may provide only the "leaf teams"
 * This is because users of teams automatically have access to everything that users of a child team would have, so we can infer the tree from the leaf teams
 * Since we can't guarantee which of these cases is true in the API response, we should assume leaf nodes and traverse the tree, but deduplicate the results
 */
export default (({
  allTeams,
  parentAssetTeamIds
}) => {
  if (!parentAssetTeamIds) {
    return undefined;
  }
  const allTeamsFlat = allTeams.map(t => flattenTeams(t)).flat(2);
  return getAllParentAssetTeamsFromParentAssetLeafTeams({
    allTeams: allTeamsFlat,
    parentAssetTeamIds
  });
});