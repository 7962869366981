import { connect } from 'react-redux';
import { getFoldersById, getFolderTree, getSelectedFolderAncestors } from '../selectors/Folders';
import { fetchFoldersByParentId } from '../actions/FolderFetch';
import { fetchTeams } from '../actions/Teams';
import FolderTree from '../components/FolderTree';
import { getHasTeamsAccessScope } from '../selectors/Auth';
import { getIsTeamsRequestSucceeded, getPortalTeams } from '../selectors/Teams';
const mapStateToProps = (state, props) => {
  return {
    tree: getFolderTree(state),
    ancestors: getSelectedFolderAncestors(state, props),
    folders: getFoldersById(state),
    isTeamsRequestSucceeded: getIsTeamsRequestSucceeded(state),
    teams: getPortalTeams(state),
    hasTeamsAccess: getHasTeamsAccessScope(state)
  };
};
const mapDispatchToProps = {
  fetchFoldersByParentId,
  fetchTeams
};
export default connect(mapStateToProps, mapDispatchToProps)(FolderTree);