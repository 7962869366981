import { createSelector } from 'reselect';
import { ShutterstockTosValues, DiscoverabilityPopupDismissed } from '../Constants';
import { getCategoryValues, hasFetchedPortalMeta } from './PortalMeta';
import PortalMetaCategory from '../enums/PortalMetaCategory';
export const getUserSettingsHaveLoaded = hasFetchedPortalMeta;
export const getShutterstockTosAccepted = createSelector([getCategoryValues], categoryValues => categoryValues.get(PortalMetaCategory.SHUTTERSTOCK_TOS) === ShutterstockTosValues.ACCEPTED);
export const getShutterstockFolderId = createSelector([hasFetchedPortalMeta, getCategoryValues], (isFetched, categoryValues) => {
  if (isFetched && categoryValues.get(PortalMetaCategory.SHUTTERSTOCK_FOLDER_ID)) {
    const folderId = parseInt(categoryValues.get(PortalMetaCategory.SHUTTERSTOCK_FOLDER_ID), 10);
    return folderId > 0 ? folderId : null;
  }
  return null;
});
export const getHasSeenAdvancedSearchDashboardPopup = createSelector([hasFetchedPortalMeta, getCategoryValues], (isFetched, categoryValues) => {
  if (isFetched) {
    return categoryValues.get(PortalMetaCategory.DASHBOARD_ADVANCED_SEARCH_POPUP) === DiscoverabilityPopupDismissed;
  }
  return null;
});
export const getHasSeenAdvancedSearchPickerPopup = createSelector([hasFetchedPortalMeta, getCategoryValues], (isFetched, categoryValues) => {
  if (isFetched) {
    return categoryValues.get(PortalMetaCategory.PICKER_ADVANCED_SEARCH_POPUP) === DiscoverabilityPopupDismissed;
  }
  return null;
});