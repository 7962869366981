import { combineReducers } from 'redux';
import Canva from 'FileManagerCore/reducers/Canva';
import Adobe from 'FileManagerCore/reducers/Adobe';
// @ts-expect-error not yet typed
import Notifications from 'ReduxMessenger/reducers/Notifications';
import Auth from 'FileManagerCore/reducers/Auth';
import EditAndCreateImage from 'FileManagerCore/reducers/EditAndCreateImage';
import Files from 'FileManagerCore/reducers/Files';
// @ts-expect-error not yet typed
import FileDetails from 'FileManagerCore/reducers/FileDetails';
// @ts-expect-error not yet typed
import Folders from 'FileManagerCore/reducers/Folders';
// @ts-expect-error not yet typed
import UploadingFiles from 'FileManagerCore/reducers/UploadingFiles';
import portalMeta from 'FileManagerCore/reducers/PortalMeta';
import Limits from 'FileManagerCore/reducers/Limits';
import Selection from './Selection';
import getShutterstockReducer from 'FileManagerCore/reducers/Shutterstock';
import Domains from './Domains';
import FileUsage from './FileUsage';
// @ts-expect-error not yet typed
import VideoThumbnails from 'FileManagerCore/reducers/VideoThumbnails';
import HubUsers from 'FileManagerCore/reducers/HubUsers';
import suspension from 'FileManagerCore/reducers/Suspension';
import teams from 'FileManagerCore/reducers/Teams';
import modalDisplay from './ModalDisplay';
import pageSize from './PageSize';
import fileHistory from './FileHistory';
import videoSubtitles from 'FileManagerCore/reducers/videoSubtitles';
import fileExpiration from './fileExpiration';
import FilesAndFoldersTable from './FilesAndFoldersTable';
export default combineReducers({
  adobe: Adobe,
  auth: Auth,
  domains: Domains,
  canva: Canva,
  editAndCreateImage: EditAndCreateImage,
  files: Files,
  fileDetails: FileDetails,
  folders: Folders,
  notifications: Notifications,
  selection: Selection,
  shutterstock: getShutterstockReducer({
    concatenateResults: false
  }),
  uploadingFiles: UploadingFiles,
  portalMeta,
  fileUsage: FileUsage,
  videoThumbnail: VideoThumbnails,
  limits: Limits,
  hubUsers: HubUsers,
  suspension,
  modalDisplay,
  teams,
  pageSize,
  videoSubtitles,
  fileHistory,
  fileExpiration,
  filesAndFoldersTable: FilesAndFoldersTable
});