import { RequestStatus } from 'FileManagerCore/Constants';
import * as DashboardActionTypes from './ActionTypes';
import { fetchParents } from '../api/CosIndex';
import { getFileUsageRequestStatus } from '../selectors/FileUsage';
export function fetchFileUsage(fileId) {
  return (dispatch, getState) => {
    const fetchStatus = getFileUsageRequestStatus(getState(), {
      fileId
    });
    if (fetchStatus === RequestStatus.PENDING || fetchStatus === RequestStatus.SUCCEEDED) {
      return;
    }
    dispatch({
      type: DashboardActionTypes.FETCH_FILE_PARENTS_ATTEMPTED,
      fileId
    });
    fetchParents(fileId).then(response => {
      dispatch({
        type: DashboardActionTypes.FETCH_FILE_PARENTS_SUCCEEDED,
        items: response,
        fileId
      });
    }, error => {
      dispatch({
        type: DashboardActionTypes.FETCH_FILE_PARENTS_FAILED,
        error,
        fileId
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}