import { flattenTeams } from 'FileManagerCore/utils/teams';
const noDuplicates = (teamId, teamIndex, teamsArray) => teamsArray.indexOf(teamId) === teamIndex;
const hasParentTeam = (start, parentTeams, allTeamsFlat) => {
  if (parentTeams.includes(start.id)) {
    return true;
  }
  if (start.parentTeamId) {
    const parentTeam = allTeamsFlat.find(t => t.id === start.parentTeamId);
    if (!parentTeam) {
      console.error('All teams: ', allTeamsFlat);
      throw Error(`Could not find team id ${start.parentTeamId} in portal`);
    }
    return hasParentTeam(parentTeam, parentTeams, allTeamsFlat);
  }
  return false;
};
const getIsTeamOutsideAncestorChain = (team, teams, parentTeams) => {
  if (parentTeams.length === 0) {
    return false;
  }
  if (!team.parentTeamId) {
    return true;
  }
  return !hasParentTeam(team, parentTeams, teams);
};
export default (({
  teams,
  parentTeams
}) => teams.map(team => flattenTeams(team)).flat(3).filter((team, _index, allTeamsFlat) => !getIsTeamOutsideAncestorChain(team, allTeamsFlat, parentTeams)).map(t => t.id).filter(noDuplicates));