import { createSelector } from 'reselect';
import * as ReadOnlyReason from '../enums/ReadOnlyReason';
import { getHasFileManagerWriteScope } from './Auth';
import { getIsPortalSuspended, getSuspensionFetchStatus } from './Suspension';
import { RequestStatus } from '../Constants';
export const getReadOnlyReason = createSelector([getSuspensionFetchStatus, getIsPortalSuspended, getHasFileManagerWriteScope], (fetchStatus, isSuspended, hasWriteScope) => {
  if (!hasWriteScope) {
    if (fetchStatus === RequestStatus.SUCCEEDED && isSuspended) {
      return ReadOnlyReason.PORTAL_SUSPENDED;
    }
    return ReadOnlyReason.USER_WRITE_PERMISSION;
  }
  return null;
});
export const getIsReadOnly = createSelector([getReadOnlyReason], readOnlyReason => Boolean(readOnlyReason));
export const getIsReadOnlySuspended = createSelector([getReadOnlyReason], readOnlyReason => readOnlyReason === ReadOnlyReason.PORTAL_SUSPENDED);