import { RequestStatus } from 'FileManagerCore/Constants';
import FileUsageTypes from '../enums/FileUsageTypes';
export function getMaybeTruncatedShutterstockFilename(filename) {
  if (filename.length < 30) {
    return filename;
  }
  return `${filename.substring(0, 27)}...`;
}
export function getHasFileUsageRequestFailedOrIsEmpty(fileUsage, fileUsageRequestStatus) {
  return fileUsageRequestStatus === RequestStatus.FAILED || fileUsage && fileUsage.get('status') === RequestStatus.SUCCEEDED;
}
export const getEditableUsage = items => {
  const editableUsage = items.filter(item => Object.keys(FileUsageTypes).includes(item.get('type')));
  return editableUsage.count() > 0;
};