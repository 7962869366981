import { STYLE_OPTIONS } from '../constants/imageGeneration';
export const getIsFileAiGenerated = file => file.getIn(['meta', 'ai_generated']);
export const getAdobeId = file => file.getIn(['meta', 'adobe_id']);
export const buildFullPrompt = (prompt, style) => {
  return style ? `${style}: ${prompt}` : prompt;
};
export const extractStyle = fullPrompt => {
  if (fullPrompt.includes(':')) {
    const style = fullPrompt.split(':')[0];
    if (STYLE_OPTIONS.includes(style)) {
      return {
        prompt: fullPrompt.substring(style.length + 1).trim(),
        style: style
      };
    }
  }
  return {
    prompt: fullPrompt,
    style: null
  };
};
const FILE_NAME_SANITIZATION_REGEX = /[|/#!$%@^&*;:{}=\-_`~()]/g;
const FILE_NAME_MAX_LENGTH = 150;
export const getFileNameFromPrompt = prompt => {
  const sentences = prompt.split('.');
  if (sentences.length > 1 && sentences[0].length > 5 &&
  // exclude strings too short to likely be sentences
  sentences[0].length < FILE_NAME_MAX_LENGTH) {
    prompt = sentences[0];
  }
  return prompt.replace(FILE_NAME_SANITIZATION_REGEX, '').replace(/\s{2,}/g, ' ').trim().substring(0, FILE_NAME_MAX_LENGTH);
};