import { HUB_USERS_FETCH_SUCCEEDED, HUB_USERS_FETCH_FAILED, HUB_USERS_FETCH_PENDING } from './ActionTypes';
import * as UsersApi from '../api/Users';
export function fetchHubUsers(callback) {
  return dispatch => {
    dispatch({
      type: HUB_USERS_FETCH_PENDING
    });
    return UsersApi.fetchHubUsers().then(users => {
      dispatch({
        type: HUB_USERS_FETCH_SUCCEEDED,
        users
      });
      if (callback) {
        callback(users);
      }
    }, () => dispatch({
      type: HUB_USERS_FETCH_FAILED
    }));
  };
}