import { VideoProviders, PATH_TO_VIDEO_HOSTING_INFO } from '../Constants';
import { DefaultHubLVideoParams, SupportedHubLVideoParams } from '../constants/HubLVideoParams';
import getIn from './getIn';
import { logBadConversionAssetData } from './logging';
import { capitalizeString } from './stringUtils';
const CONVERSION_ASSET_NECESSARY_PROPERTIES = ['type', 'id', 'position'];
export function getVideoHostingInfoProperty(file) {
  return getIn(file, PATH_TO_VIDEO_HOSTING_INFO);
}
export const isHubSpotVideo2 = video => {
  if ('videoId' in video) {
    return true;
  }
  const video2HostingInfo = findVideoInfoByProvider(video, VideoProviders.HUBSPOT_VIDEO_2, {
    includeDisabled: true
  });
  return Boolean(video2HostingInfo && video2HostingInfo.id);
};
export function findVideoInfoByProvider(file, provider, {
  includeDisabled = false
} = {}) {
  let hostingInfoList = getVideoHostingInfoProperty(file);
  if (!hostingInfoList) {
    return {};
  }
  if (!includeDisabled) {
    hostingInfoList = hostingInfoList.filter(info => info.status === 'ENABLED');
  }
  return hostingInfoList.find(e => e.provider === provider) || {};
}
export const getHubLVideoParamObjectFromFile = videoOrFile => {
  const {
    width,
    height
  } = videoOrFile;
  if ('videoId' in videoOrFile) {
    return {
      width,
      height,
      player_id: videoOrFile.videoId
    };
  }
  const hostingInfo = findVideoInfoByProvider(videoOrFile, VideoProviders.HUBSPOT_VIDEO_2);
  if (!hostingInfo.id) {
    return {};
  }
  return Object.assign({}, videoOrFile.meta.video_data, {
    width,
    height,
    player_id: hostingInfo.id
  });
};
export const getVideoIdFromFile = file => {
  const hostingInfos = getVideoHostingInfoProperty(file);
  return hostingInfos && hostingInfos.length ? hostingInfos[0].id : null;
};
export const getHubLVideoPlayerIdFromFile = videoOrFile => {
  if (isHubSpotVideo2(videoOrFile) && 'videoId' in videoOrFile) {
    return videoOrFile.videoId;
  }
  return getHubLVideoParamObjectFromFile(videoOrFile).player_id;
};
export const isHubLVideo = videoOrFile => {
  if (isHubSpotVideo2(videoOrFile) && 'isEmbeddable' in videoOrFile) {
    return videoOrFile.isEmbeddable;
  }
  return Boolean(getHubLVideoPlayerIdFromFile(videoOrFile));
};

// todo:LB - try to deprecate and remove, consider if we really need logBadConversionAssetData
export const getDoesConversionAssetHaveNecessaryProperties = conversionAsset => {
  return Boolean(conversionAsset.id && conversionAsset.type && conversionAsset.position);
};
export const getIsConversionAssetValid = conversionAsset => {
  if (!conversionAsset) {
    return false;
  }
  if (typeof conversionAsset !== 'object') {
    logBadConversionAssetData('conversion_asset attribute needs to be a valid JSON object', `conversion_asset is ${conversionAsset === null ? 'null' : 'not an object'}`);
    return false;
  }
  if (!getDoesConversionAssetHaveNecessaryProperties(conversionAsset)) {
    logBadConversionAssetData(`conversion_asset must have at least 3 properties: ${CONVERSION_ASSET_NECESSARY_PROPERTIES.join()}`);
    return false;
  }
  return true;
};
export const getHubLParamsForVideoPlayer = params => {
  const hubLVideoPlayerParams = Object.assign({}, DefaultHubLVideoParams, params);
  return Object.keys(hubLVideoPlayerParams).filter(key => SupportedHubLVideoParams.includes(key)).filter(key => {
    return key === 'conversion_asset' ? getIsConversionAssetValid(hubLVideoPlayerParams[key]) : true;
  }).map(key => {
    const val = hubLVideoPlayerParams[key];
    if (typeof val === 'boolean') {
      return `${key}=${capitalizeString(val.toString())}`;
    } else if (key === 'conversion_asset') {
      try {
        return `conversion_asset='${JSON.stringify(val)}'`;
      } catch (error) {
        logBadConversionAssetData(String(error));
      }
    }
    return `${key}='${val}'`;
  }).join(', ');
};
export const getHubLVideoHubLTag = params => {
  return `{% video_player "embed_player" ${getHubLParamsForVideoPlayer(params)} %}`;
};