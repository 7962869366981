import Immutable from 'immutable';
import getAuditActionType from '../utils/getAuditActionType';
import { RequestStatus } from 'FileManagerCore/Constants';
import { FETCH_FILE_HISTORY_ATTEMPTED, FETCH_FILE_HISTORY_FAILED, FETCH_FILE_HISTORY_SUCCEEDED } from '../actions/ActionTypes';
const defaultState = Immutable.Map();
export default function FileHistory(state = defaultState, {
  type,
  data,
  fileId
}) {
  switch (type) {
    case FETCH_FILE_HISTORY_ATTEMPTED:
      return state.set(fileId, {
        status: RequestStatus.PENDING
      });
    case FETCH_FILE_HISTORY_SUCCEEDED:
      return state.set(fileId, {
        results: data.results.map(result => Object.assign({}, result, {
          auditActionType: getAuditActionType(result)
        })),
        status: RequestStatus.SUCCEEDED
      });
    case FETCH_FILE_HISTORY_FAILED:
      return state.set(fileId, {
        status: RequestStatus.FAILED
      });
    default:
      return state;
  }
}