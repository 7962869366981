import { SystemRecognizedFileExtensionList } from '../Constants';
export const parseFileExtensionFromSearch = searchQuery => {
  if (!searchQuery) {
    return {
      systemRecognizedFileExtension: undefined,
      searchQuery
    };
  }
  const searchKeywordList = searchQuery.trim().toLowerCase().split(' ');
  const systemRecognizedFileExtension = SystemRecognizedFileExtensionList.find(extension => searchKeywordList.includes(extension));
  if (searchQuery === systemRecognizedFileExtension) {
    return {
      searchQuery: '',
      systemRecognizedFileExtension
    };
  }
  if (systemRecognizedFileExtension) {
    const trimmedString = searchKeywordList.filter(word => word !== systemRecognizedFileExtension).join(' ');
    return {
      searchQuery: trimmedString,
      systemRecognizedFileExtension
    };
  }
  return {
    searchQuery
  };
};