import { reportMessage } from 'FileManagerCore/utils/logging';
import { FileHistoryAuditActionTypes, FileHistoryEventTypes } from '../enums/FileHistoryEventTypes';
export default (result => {
  const modelDiff = JSON.parse(result.modelDiff) || {};

  // Prefer audit action types as they are most granular
  if (result.auditActionType && result.auditActionType in FileHistoryAuditActionTypes) {
    return result.auditActionType;
  }
  if (modelDiff.access) {
    return 'FILE_VISIBILITY_CHANGE';
  }
  if (modelDiff.name) {
    return 'RENAMED';
  }

  // Fall back to event types as they are least granular
  if (result.eventType in FileHistoryEventTypes) {
    return result.eventType;
  }
  reportMessage('Found unknown file history event', {
    result
  });
  return 'UNKNOWN';
});