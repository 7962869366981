import Raven from 'raven-js';
// @ts-expect-error Not converted yet
import md5 from './md5';
const TOO_LARGE_TO_PROCESS_THRESHOLD = 100 * 1000 * 1000; // 100 MB

export function hashBlob(file) {
  if (file.size > TOO_LARGE_TO_PROCESS_THRESHOLD) {
    return Promise.reject(new Error('File too large to compute hash for'));
  }
  return file.arrayBuffer().then(bytes => md5(bytes)).catch(err => {
    Raven.captureException(err);
    throw err;
  });
}