import Immutable from 'immutable';
import { BULK_MOVE_ATTEMPTED, BULK_MOVE_SUCCEEDED, BULK_DELETE_ATTEMPTED, REPLACE_FILE_SUCCEEDED, VIDEO_THUMBNAIL_UPDATE_SUCCEEDED, GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED, CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED, UPLOAD_EDITED_IMAGE_SUCCEEDED, CHANGE_TAB, DELETE_FOLDER_SUCCEEDED } from 'FileManagerCore/actions/ActionTypes';
import { RequestStatus } from 'FileManagerCore/Constants';
import * as DashboardActionTypes from '../actions/ActionTypes';
import { sumTotalNestedFileCountAndSelectedFiles } from '../utils/sumTotalNestedFileCountAndSelectedFiles';
const defaultState = Immutable.Map({
  single: undefined,
  bulkFileIds: Immutable.Set(),
  bulkFolderIds: Immutable.Set(),
  singleFileChangePublicAccessibilityRequestStatus: RequestStatus.UNINITIALIZED,
  bulkChangePublicAccessibilityRequestStatus: RequestStatus.UNINITIALIZED,
  totalFileCount: undefined,
  totalNestedFileCountRequestStatus: RequestStatus.UNINITIALIZED
});
const isSelected = (selectedFile, files) => {
  if (!selectedFile) {
    return false;
  }
  return files.findIndex(file => selectedFile.get('id') === file.get('id')) > -1;
};
const getSelected = (selectedFile, files) => {
  if (!selectedFile) {
    return undefined;
  }
  return files.find(file => file.get('id') === selectedFile.get('id'));
};
const clearBulkSelection = state => {
  return state.merge({
    bulkFileIds: Immutable.Set(),
    bulkFolderIds: Immutable.Set()
  });
};
export default function Selection(state = defaultState, action) {
  const {
    type,
    file,
    files,
    updatedFiles,
    totalNestedFileCount,
    bulkFileIds,
    bulkFolderIds
  } = action;
  const currentSelection = state.get('bulkFileIds');
  switch (type) {
    case VIDEO_THUMBNAIL_UPDATE_SUCCEEDED:
    case GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED:
    case DashboardActionTypes.SELECT_FILE:
      return state.set('single', file);
    case CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED:
      return state.merge({
        single: file,
        singleFileChangePublicAccessibilityRequestStatus: RequestStatus.SUCCEEDED
      });
    case DashboardActionTypes.CHANGE_FILE_ACCESSIBILITY_REQUEST_ATTEMPTED:
      return state.set('singleFileChangePublicAccessibilityRequestStatus', RequestStatus.PENDING);
    case DashboardActionTypes.CHANGE_FILE_ACCESSIBILITY_REQUEST_FAILED:
      return state.set('singleFileChangePublicAccessibilityRequestStatus', RequestStatus.FAILED);
    case REPLACE_FILE_SUCCEEDED:
    case UPLOAD_EDITED_IMAGE_SUCCEEDED:
      if (state.getIn(['single', 'id']) !== file.get('id')) {
        return state.set('single', file);
      }
      return state;
    case DashboardActionTypes.ADD_BULK_FILE_SELECTION:
      return state.merge({
        bulkFileIds: state.get('bulkFileIds').union(bulkFileIds)
      });
    case DashboardActionTypes.ADD_BULK_FOLDER_SELECTION:
      return state.merge({
        bulkFolderIds: state.get('bulkFolderIds').union(bulkFolderIds)
      });
    case DashboardActionTypes.REMOVE_BULK_FILE_SELECTION:
      return state.merge({
        bulkFileIds: state.get('bulkFileIds').subtract(bulkFileIds)
      });
    case DashboardActionTypes.REMOVE_BULK_FOLDER_SELECTION:
      return state.merge({
        bulkFolderIds: state.get('bulkFolderIds').subtract(bulkFolderIds)
      });
    case BULK_DELETE_ATTEMPTED:
      if (isSelected(state.get('single'), files)) {
        return defaultState;
      }
      return clearBulkSelection(state);
    case BULK_MOVE_SUCCEEDED:
      if (isSelected(state.get('single'), updatedFiles)) {
        return state.set('single', getSelected(state.get('single'), updatedFiles));
      }
      return state;
    case DELETE_FOLDER_SUCCEEDED:
      {
        const folderId = action.folder && action.folder.get('id');
        if (folderId && state.get('bulkFolderIds').has(folderId)) {
          state = state.update('bulkFolderIds', folderIds => folderIds.delete(folderId));
        }
        return state;
      }
    case DashboardActionTypes.SEARCH:
    case DashboardActionTypes.SORT:
    case DashboardActionTypes.ARCHIVE:
    case DashboardActionTypes.FILTER_FILE_TYPE:
    case DashboardActionTypes.RESET_PAGE:
    case DashboardActionTypes.CLEAR_BULK_SELECTION:
    case CHANGE_TAB:
    case BULK_MOVE_ATTEMPTED:
      {
        return clearBulkSelection(state);
      }
    case DashboardActionTypes.START_START_BULK_VISIBILITY_CHANGE_ATTEMPTED:
      return state.set('bulkChangePublicAccessibilityRequestStatus', RequestStatus.PENDING);
    case DashboardActionTypes.START_BULK_VISIBILITY_CHANGE_SUCCESS:
      return state.set('bulkChangePublicAccessibilityRequestStatus', RequestStatus.SUCCEEDED);
    case DashboardActionTypes.START_BULK_VISIBILITY_CHANGE_FAILURE:
      return state.set('bulkChangePublicAccessibilityRequestStatus', RequestStatus.FAILED);
    case DashboardActionTypes.COUNT_FILES_ATTEMPTED:
      return state.merge({
        totalFileCount: undefined,
        totalNestedFileCountRequestStatus: RequestStatus.PENDING
      });
    case DashboardActionTypes.COUNT_FILES_SUCCEEDED:
      return state.merge({
        totalFileCount: sumTotalNestedFileCountAndSelectedFiles(totalNestedFileCount, currentSelection),
        totalNestedFileCountRequestStatus: RequestStatus.SUCCEEDED
      });
    case DashboardActionTypes.COUNT_FILES_FAILED:
      return state.merge({
        totalNestedFileCountRequestStatus: RequestStatus.FAILED
      });
    case DashboardActionTypes.CLOSE_MODAL:
      return state.merge({
        totalFileCount: undefined,
        totalNestedFileCountRequestStatus: defaultState.get('totalNestedFileCountRequestStatus')
      });
    default:
      return state;
  }
}