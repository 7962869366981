// Event types
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const HARD_DELETE = 'HARD_DELETE';
export const RESTORE = 'RESTORE';
export const FileHistoryEventTypes = {
  CREATE,
  UPDATE,
  DELETE,
  HARD_DELETE,
  RESTORE
};

// Audit action types - more granular than Event Types
export const UPLOAD = 'UPLOAD';
export const DOWNLOAD_FROM_URL = 'DOWNLOAD_FROM_URL';
export const COPY = 'COPY';
export const REPLACED = 'REPLACED';
export const RENAMED = 'RENAMED';
export const RESTORE_FROM_FILES_TRASH = 'RESTORE_FROM_FILES_TRASH';
export const THUMBNAIL_GENERATION = 'THUMBNAIL_GENERATION';
export const MOVED = 'MOVED';
export const BULK_MOVE = 'BULK_MOVE';
export const FILE_VISIBILITY_CHANGE = 'FILE_VISIBILITY_CHANGE';
export const GDPR_DELETE = 'GDPR_DELETE';
export const FILES_TRASH_DELETE = 'FILES_TRASH_DELETE';
export const PERMANENT_DELETE = 'PERMANENT_DELETE';
export const TTL_DELETE = 'TTL_DELETE';
export const TTL_UPDATE = 'TTL_UPDATE';
export const FOLDER_DELETE = 'FOLDER_DELETE';
export const UNKNOWN = 'UNKNOWN';
export const FILE_EXPIRATION_DELETE = 'FILE_EXPIRATION_DELETE';
export const RESIZE = 'RESIZE';
export const PERMISSIONS_UPDATE = 'PERMISSIONS_UPDATE';
export const FileHistoryAuditActionTypes = {
  UPLOAD,
  DOWNLOAD_FROM_URL,
  COPY,
  REPLACED,
  RENAMED,
  RESTORE_FROM_FILES_TRASH,
  THUMBNAIL_GENERATION,
  MOVED,
  BULK_MOVE,
  FILE_VISIBILITY_CHANGE,
  GDPR_DELETE,
  FILES_TRASH_DELETE,
  PERMANENT_DELETE,
  TTL_DELETE,
  TTL_UPDATE,
  FOLDER_DELETE,
  UNKNOWN,
  FILE_EXPIRATION_DELETE,
  PERMISSIONS_UPDATE
};