import http from 'hub-http/clients/apiClient';
import { translateLegacyOptions } from './FileSearch';
const PUBLIC_SEARCH_URI = 'files-public-search/v1/search';
export const fetchFilesAndFoldersFromPublicSearch = (legacyOptions, useSemanticSearch) => {
  const query = Object.assign({}, translateLegacyOptions(legacyOptions, legacyOptions.category), {
    semanticSearchEnabled: useSemanticSearch
  });
  return http.get(PUBLIC_SEARCH_URI, {
    query
  });
};