import { Limits } from '../Constants';
import { splitNameAndExtension, getType } from './fileTemporary';
export function countUploadingFilesByType(uploadingFiles, fileType) {
  return uploadingFiles.filter(file => getType(splitNameAndExtension(file).extension) === fileType).length || 0;
}
export const isOrWillVideoLimitBeReached = ({
  hasUserExceededVideoLimit,
  attemptedVideoUploadsCount,
  quantityUsed,
  quantityAllowed
}) => {
  return hasUserExceededVideoLimit || attemptedVideoUploadsCount + quantityUsed >= quantityAllowed;
};
export const updateAttemptedVideoUploads = (state, newValue) => {
  return state.get(Limits.EMBEDDABLE_VIDEO).merge({
    attemptedVideoUploads: newValue
  });
};
export function getDecreasedQuantityUsed(state, count) {
  return state.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed']) - count;
}
export function getIncreasedQuantityUsed(state, count) {
  return state.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed']) + count;
}