'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllUsers, createGetAllUsers } from 'reference-resolvers/api/AppUsersAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createAppUserReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.APP_USERS,
  createFetchData: createGetAllUsers,
  fetchData: getAllUsers
}, options));
export default createAppUserReferenceResolver();