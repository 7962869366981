'use es6';

import Immutable from 'immutable';
import http from 'hub-http/clients/apiClient';
import { FileVisibilityOptions } from 'FileManagerCore/enums/FileVisibilityOptions';
const API_PATH = 'filemanager/api/v3/files';
export function deleteAll(files, folders) {
  return http.post(`${API_PATH}/delete`, {
    data: {
      files,
      folders
    }
  }).then(Immutable.fromJS);
}
export function move(files, folders, destinationPath) {
  return http.post(`${API_PATH}/move`, {
    timeout: 60000,
    data: {
      replacementStrategy: 'keep-both',
      files,
      folders,
      destinationPath
    }
  }).then(Immutable.fromJS);
}
export function getFileCountFromPublicSearch(folderIdList) {
  return http.get('files-public-search/v1/count', {
    query: {
      folderIds: [...folderIdList]
    }
  }).then(Immutable.fromJS);
}
export function changeVisibility({
  visibility,
  folderIds,
  fileIds
}) {
  return http.post(`${API_PATH}/bulk-set-accessibility`, {
    data: {
      folderIds,
      fileIds,
      accessibilityRequest: FileVisibilityOptions[visibility]
    }
  });
}