import Immutable from 'immutable';
import { RequestStatus } from 'FileManagerCore/Constants';
import * as DashboardActionTypes from '../actions/ActionTypes';
const defaultState = Immutable.Map({
  domains: Immutable.List(),
  status: RequestStatus.UNINITIALIZED
});
export default function Domains(state = defaultState, action) {
  const {
    type,
    domains
  } = action;
  switch (type) {
    case DashboardActionTypes.FETCH_DOMAINS_ATTEMPTED:
      return state.set('status', RequestStatus.PENDING);
    case DashboardActionTypes.FETCH_DOMAINS_SUCCEEDED:
      return state.merge({
        status: RequestStatus.SUCCEEDED,
        domains
      });
    default:
      return state;
  }
}