export const getSelectedImageToEdit = state => state.editAndCreateImage.get('imgToEditSelected');
export const getSelectedImageFrom = state => state.editAndCreateImage.get('selectedImgFrom');
export const getImageSrc = state => state.editAndCreateImage.get('imgSrc');
export const getFileToEditId = state => state.editAndCreateImage.get('fileId');
export const getFileToEditName = state => state.editAndCreateImage.get('fileName');
export const getFolderId = state => state.editAndCreateImage.get('fileFolderId');
export const getImageHeight = state => state.editAndCreateImage.get('imgHeight');
export const getImageWidth = state => state.editAndCreateImage.get('imgWidth');
export const getEditImageRequestStatus = state => state.editAndCreateImage.get('updateRequestStatus');
export const getImageEncoding = state => state.editAndCreateImage.get('encoding');
export const getImageAspectRatio = state => getImageWidth(state) / getImageHeight(state);