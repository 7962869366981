import { searchFilesAndFolders } from 'FileManagerCore/actions/FilesAndFoldersFetch';
// @ts-expect-error Not converted yet
import { getFilesAndFoldersQueryFromOptions } from '../utils/getFilesAndFoldersQueryFromOptions';
import { MAX_OBJECT_REQUEST_LIMIT } from 'FileManagerCore/constants/CrmPlatformLimits';
// @ts-expect-error Not converted yet
import { navigate } from './Actions';

// FIXME - options should have a type! These options come from query params in the FileManagerDashboard
export const fetchFilesAndFolders = (options, initialFetch = false) => (dispatch, getState) => {
  const query = getFilesAndFoldersQueryFromOptions(Object.assign({}, options, {
    folderId: options.folderId
  }), getState(), initialFetch);

  // If for some reason we're trying to fetch too many objects, revert to page 1
  // This can happen when navigating back through page history, for example
  if (query.limit > MAX_OBJECT_REQUEST_LIMIT) {
    dispatch(navigate(Object.assign({}, options, {
      page: 1
    }), true));
    query.limit = MAX_OBJECT_REQUEST_LIMIT;
  }
  return dispatch(searchFilesAndFolders(query, {
    initialFetch,
    currentFolderId: options.folderId
  }));
};