import Raven from 'raven-js';
import I18n from 'I18n';
import { FETCH_SINGLE_FILE_ATTEMPTED, FETCH_SINGLE_FILE_FAILED, FETCH_SINGLE_FILE_SUCCESS } from './ActionTypes';
import * as FilesApi from '../api/Files';
import { getSingleFileDetails } from '../selectors/FileDetails';
import { getHasVideoIntegrationScope } from '../selectors/Auth';
import { getIsVideo } from '../utils/file';
import { getVideoHostingInfoProperty } from '../utils/hubLVideo';
import { reportError } from '../utils/logging';
const getFetchSingleFileFailedNotification = () => {
  return {
    'data-test-id': 'single-file-fetch-failed',
    type: 'danger',
    titleText: I18n.text('FileManagerCore.notifications.fetchSingleFile.error.title')
  };
};
const getFetchSingleFileFailed = fileId => ({
  type: FETCH_SINGLE_FILE_FAILED,
  fileId,
  meta: {
    notification: getFetchSingleFileFailedNotification()
  }
});
const getFetchSingleFileAttempted = fileId => ({
  type: FETCH_SINGLE_FILE_ATTEMPTED,
  fileId
});
const getFetchSingleFileSuccess = (fileId, file) => ({
  type: FETCH_SINGLE_FILE_SUCCESS,
  fileId,
  file
});
const reportUnexpectedVideoState = file => (dispatch, getState) => {
  const hasVideoIntegrationScope = getHasVideoIntegrationScope(getState());
  const hostingInfo = getVideoHostingInfoProperty(file.toJS());
  if (hasVideoIntegrationScope && !hostingInfo) {
    Raven.captureMessage('Found file without hosting info', {
      level: 'info',
      extra: {
        file: file.toJS()
      }
    });
  }
};
export function fetchSingleFile(fileId) {
  return dispatch => {
    dispatch(getFetchSingleFileAttempted(fileId));
    return FilesApi.fetchSingleFile(fileId).then(file => {
      if (getIsVideo(file)) {
        dispatch(reportUnexpectedVideoState(file));
      }
      dispatch(getFetchSingleFileSuccess(fileId, file));
      return file;
    }).catch(err => {
      reportError(err, {
        type: FETCH_SINGLE_FILE_FAILED
      });
      dispatch(getFetchSingleFileFailed(fileId));
      throw err;
    });
  };
}

// TODO: In some contexts (such as the File Picker) the caller of `fetchSingleFileIfNeeded`
// already has the most up to date file object. In those instances, we may be able
// to avoid checking the `fileDetails` redux state as a caching mechanism.
export const fetchSingleFileIfNeeded = ({
  fileId,
  fileLastUpdated
}) => (dispatch, getState) => {
  const singleFileDetails = getSingleFileDetails(getState());
  const cachedFileDetails = singleFileDetails.get(fileId);
  const hasMostRecentFile = cachedFileDetails && (!fileLastUpdated || cachedFileDetails.get('updated') === fileLastUpdated);
  if (hasMostRecentFile) {
    return Promise.resolve(cachedFileDetails);
  }
  return dispatch(fetchSingleFile(fileId));
};