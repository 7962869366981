import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { RequestStatus, ContentTypes } from 'FileManagerCore/Constants';
import { ResourceTypes } from '../Constants';
const getUsageForFile = (state, props) => {
  const {
    file
  } = props;
  return state.fileUsage.get(file.get('id')) || Immutable.Map({
    items: Immutable.List(),
    status: RequestStatus.UNINITIALIZED
  });
};
const groupUsage = usage => {
  const objects = usage.get('items');
  const typeToObjects = {};
  objects.forEach(object => {
    const type = object.get('type');
    let resourceType;
    switch (type) {
      case 'HUBDB_TABLE_ROW':
      case 'CONTENT':
        {
          const subType = object.get('subType');
          if (subType === ContentTypes.UNMAPPED || subType === ContentTypes.LEGACY_PAGE) {
            resourceType = ResourceTypes.UNKNOWN;
          } else {
            resourceType = subType;
          }
          break;
        }
      case 'EXTENSION_RESOURCE':
      case 'LAYOUT':
        resourceType = ResourceTypes.TEMPLATE;
        break;
      default:
        resourceType = ResourceTypes.UNKNOWN;
    }
    if (typeToObjects[resourceType]) {
      typeToObjects[resourceType].push(object);
    } else {
      typeToObjects[resourceType] = [object];
    }
  });
  return usage.set('items', Immutable.fromJS(typeToObjects));
};
export const getFileUsage = createSelector([getUsageForFile], groupUsage);
export const getIsFileUsed = createSelector([getFileUsage], fileUsage => {
  return fileUsage.get('items').count() > 0;
});
export const getFileUsageRequestStatus = (state, props) => state.fileUsage.getIn([props.fileId, 'status']) || RequestStatus.UNINITIALIZED;