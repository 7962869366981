'use es6';

import { getFiles, getFilesTotal } from 'FileManagerCore/selectors/Files';
import { getFilteredFolders } from '../selectors/FoldersAndFiles';
import getFormattedOrderByUsingSortDirection from './getFormattedOrderByUsingSortDirection';
import { addLeadingSlashToPath } from 'FileManagerCore/utils/stringUtils';
import { MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT } from 'FileManagerCore/Constants';
import { MAX_SEARCH_QUERY_LENGTH } from 'FileManagerCore/constants/CrmPlatformLimits';
import { getPageSize } from '../selectors/pageSize';
import { stringify } from 'hub-http/helpers/params';
import getFetchedFilesAndFoldersLimit from './getFetchedFilesAndFoldersLimit';
export const getFilesQueryFromOptions = (options, state, initialFetch = false) => {
  const {
    search: searchQuery,
    archived,
    page,
    orderBy,
    path,
    sortDirection,
    folderId,
    fileType,
    isHublVideo
  } = options;
  const pageSize = getPageSize(state);
  const query = {
    archived,
    limit: getFetchedFilesAndFoldersLimit(page, pageSize),
    order_by: getFormattedOrderByUsingSortDirection(orderBy, sortDirection)
  };
  if (isHublVideo !== undefined) {
    query.isHublVideo = isHublVideo;
  }
  if (!initialFetch) {
    const filesTotal = getFilesTotal(state);
    const fetchedFilesCount = getFiles(state).size;
    const visibleFolders = getFilteredFolders(state, {
      location: {
        search: `?${stringify({
          folderId,
          folderPath: path,
          query: searchQuery
        })}`
      }
    });
    const folderCount = visibleFolders.count();
    const total = fetchedFilesCount + folderCount;
    const last = page * pageSize;
    const needed = last - total;
    if (needed <= 0 || fetchedFilesCount >= filesTotal) {
      return false;
    }
    query.offset = fetchedFilesCount;
    query.limit = needed < MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT ? MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT : needed;
  }
  if (searchQuery) {
    query.search = searchQuery.substr(0, MAX_SEARCH_QUERY_LENGTH);
  } else {
    if (folderId) {
      query.folder_id = folderId;
    } else if (path) {
      query.folder_path = addLeadingSlashToPath(path);
    } else {
      query.folder_id = 'None';
    }
  }
  if (fileType) {
    query.type = fileType;
    delete query.folder_id;
  }
  return query;
};