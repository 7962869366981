import { createSelector } from 'reselect';
import { filterRecentlyUploadedFiles } from '../utils/FoldersAndFiles';
import { RequestStatus } from '../Constants';
export const getSingleFileDetails = state => state.fileDetails.get('fileDetails');
export const getUploadRequestsById = state => state.fileDetails.get('uploadRequestsById');
const getCurrentFolderId = (state, {
  folderId
}) => folderId;
export const getRecentlyUploadedFiles = createSelector([getSingleFileDetails, getUploadRequestsById, getCurrentFolderId], (singleFileDetails, uploadRequests, currentFolderId) => {
  return filterRecentlyUploadedFiles(singleFileDetails, uploadRequests, currentFolderId);
});
export const getIsDownloadFromExternalUrlPending = state => state.fileDetails.get('downloadFromExternalUrlStatus') === RequestStatus.PENDING;