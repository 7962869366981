module.exports = {
  "/": {
    "name": "File folder table",
    "success": {
      "file folder table": [
        "FILE_FOLDER_TABLE_LOADED"
      ],
      "dashboard no access": [
        "DASHBOARD_NO_ACCESS"
      ],
      "dashboard empty": [
        "DASHBOARD_EMPTY"
      ],
      "empty folder": [
        "EMPTY_FOLDER"
      ],
      "folder not found": [
        "FOLDER_NOT_FOUND"
      ]
    },
    "error": [
      "FILE_FOLDER_TABLE_FAILED"
    ]
  },
  "/folder/:path": {
    "name": "Nested file folder table",
    "success": {
      "file folder table": [
        "FILE_FOLDER_TABLE_LOADED"
      ],
      "empty folder": [
        "EMPTY_FOLDER"
      ],
      "folder not found": [
        "FOLDER_NOT_FOUND"
      ]
    },
    "error": [
      "FILE_FOLDER_TABLE_FAILED"
    ]
  },
  "/folder/:path1/:path2": {
    "name": "Double nested file folder table",
    "success": {
      "file folder table": [
        "FILE_FOLDER_TABLE_LOADED"
      ],
      "empty folder": [
        "EMPTY_FOLDER"
      ],
      "folder not found": [
        "FOLDER_NOT_FOUND"
      ]
    },
    "error": [
      "FILE_FOLDER_TABLE_FAILED"
    ]
  },
  "/search": {
    "name": "Search",
    "success": {
      "file folder table": [
        "FILE_FOLDER_TABLE_LOADED"
      ],
      "search no results": [
        "SEARCH_NO_RESULTS"
      ]
    },
    "error": [
      "FILE_FOLDER_TABLE_FAILED"
    ]
  },
  "/tryVideo": {
    "name": "Try video",
    "success": {
      "file folder table": [
        "FILE_FOLDER_TABLE_LOADED"
      ],
      "dashboard empty": [
        "DASHBOARD_EMPTY"
      ]
    },
    "error": [
      "FILE_FOLDER_TABLE_FAILED"
    ]
  },
  "/stock-images": {
    "name": "Stock images",
    "success": {
      "stock images initial": [
        "STOCK_IMAGES_INITIAL"
      ],
      "stock image search results": [
        "STOCK_IMAGE_RESULTS"
      ],
      "stock images no access": [
        "STOCK_IMAGES_NO_ACCESS"
      ],
      "stock images no results": [
        "STOCK_IMAGES_NO_RESULTS"
      ]
    },
    "error": [
      "STOCK_IMAGE_RESULTS_FAILED"
    ]
  },
  "/some-random-route": {}
};