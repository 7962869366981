import { FileTypes, TypeToExtensions } from '../Constants';
export function splitNameAndExtension(file) {
  const name = file.name;
  // eslint-disable-next-line no-useless-escape
  const matches = name.match(/(.*)\.([^\.]+)$/);
  if (!matches) {
    return {
      extension: '',
      name
    };
  }
  return {
    name: matches[1],
    extension: matches[2].toLowerCase()
  };
}
export function getType(extensionOrType) {
  if (Object.keys(FileTypes).includes(extensionOrType)) {
    return extensionOrType;
  }
  const type = TypeToExtensions.findKey(extensions => extensions.includes(extensionOrType));
  if (type) {
    return type;
  }
  return FileTypes.OTHER;
}