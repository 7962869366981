import http from 'hub-http/clients/apiClient';
const BASE_FOLDER_URI = 'filemanager/api/v3/folders';
const BASE_FILE_URI = 'filemanager/api/v3/files';

/**
 * @deprecated - use setFolderPermissions instead
 */
export const setFolderPartitions = ({
  folderId,
  teamIds
}) => {
  return http.post(`${BASE_FOLDER_URI}/${folderId}/partition`, {
    data: teamIds
  });
};
export const setFolderPermissions = ({
  folderId,
  teams,
  users,
  clearTeams,
  clearUsers
}) => {
  return http.post(`${BASE_FOLDER_URI}/${folderId}/permissions`, {
    data: {
      teams,
      owners: users,
      clearTeams,
      clearOwners: clearUsers
    }
  });
};
export const setFilePermissions = ({
  fileId,
  teams,
  users,
  clearTeams,
  clearUsers
}) => {
  return http.post(`${BASE_FILE_URI}/${fileId}/permissions`, {
    data: {
      teams,
      owners: users,
      clearTeams,
      clearOwners: clearUsers
    }
  });
};