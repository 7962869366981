import { Map as ImmutableMap } from 'immutable';
import { FETCH_SUSPENSION_STATE_ATTEMPTED, FETCH_SUSPENSION_STATE_SUCCEEDED, FETCH_SUSPENSION_STATE_FAILED } from './ActionTypes';
import { requestSuspensionStatus } from '../api/Suspension';
import { reportError } from '../utils/logging';
import { hasFetchedSuspensionStatus } from '../selectors/Suspension';
const buildSuspensionStateFromResponse = data => ImmutableMap({
  suspended: data.suspended,
  canAppeal: data.canAppeal,
  appealState: data.appealState
});
export const fetchSuspensionStatus = () => (dispatch, getState) => {
  if (hasFetchedSuspensionStatus(getState())) {
    return;
  }
  dispatch({
    type: FETCH_SUSPENSION_STATE_ATTEMPTED
  });
  requestSuspensionStatus().then(data => {
    dispatch({
      type: FETCH_SUSPENSION_STATE_SUCCEEDED,
      data: buildSuspensionStateFromResponse(data)
    });
  }).catch(err => {
    reportError(err, {
      action: FETCH_SUSPENSION_STATE_FAILED
    });
    dispatch({
      type: FETCH_SUSPENSION_STATE_FAILED
    });
  });
};