'use es6';

import { reportMessage } from 'FileManagerCore/utils/logging';
export default function loggingMiddleware() {
  return () => next => action => {
    if (!action.type) {
      reportMessage('Action without type dispatched, this is probably a bug!', action);
    }
    return next(action);
  };
}