import { OrderedMap, List, Map as ImmutableMap, fromJS } from 'immutable';
import { ObjectCategory } from '../Constants';
export const transformCrmSearchFile = attrs => ({
  id: attrs.id,
  name: attrs.name,
  size: attrs.size,
  extension: attrs.extension,
  encoding: attrs.encoding,
  url: attrs.url,
  width: attrs.width,
  height: attrs.height,
  category: ObjectCategory.FILE,
  archived: attrs.archived,
  portal_id: attrs.portalId,
  folder_id: attrs.folderId || null,
  updated: attrs.updatedAt,
  created: attrs.createdAt,
  deleted_at: attrs.deletedAt,
  full_path: attrs.fullPath,
  type: attrs.fileType,
  cloud_key: attrs.cloudKey,
  friendly_url: attrs.url,
  default_hosting_url: attrs.url,
  isHublVideo: attrs.isHublVideo,
  is_indexable: attrs.isIndexable,
  teams: attrs.teams || [],
  owners: attrs.owners || [],
  meta: {
    allows_anonymous_access: attrs.allowsAnonymousAccess,
    sensitive: attrs.isSensitive
  }
});

// we're moving towards loading folders via CRM search instead of ES,
// but we transform these to appear like folders from /filemanager/api/v2 the app was familiar with
export const transformCrmSearchFolder = attrs => ({
  id: attrs.id,
  portal_id: attrs.portalId,
  category: ObjectCategory.FOLDER,
  name: attrs.name,
  parent_folder_id: attrs.folderId ? String(attrs.folderId) : null,
  full_path: attrs.fullPath,
  updated: attrs.updatedAt,
  created: attrs.createdAt,
  deleted: false,
  deleted_at: 0,
  teams: attrs.teams || [],
  owners: attrs.owners || []
});
const buildResultFolderItems = objects => OrderedMap(objects.map(attrs => [attrs.id, fromJS(transformCrmSearchFolder(attrs))]));
export const buildFoldersResponsePayload = resp => {
  const objects = buildResultFolderItems(resp.results);
  return ImmutableMap(Object.assign({}, resp, {
    objects
  })).delete('results');
};
export const buildResultFileItems = objects => List(objects.map(attrs => fromJS(transformCrmSearchFile(attrs))));
export const buildFilesResponsePayload = resp => {
  const objects = buildResultFileItems(resp.results);
  return ImmutableMap(Object.assign({}, resp, {
    objects
  })).delete('results');
};
const buildResultFileAndFolderItems = objects => List(objects.map(attrs => {
  if (attrs.category === ObjectCategory.FILE) {
    return fromJS(transformCrmSearchFile(attrs));
  } else {
    return fromJS(transformCrmSearchFolder(attrs));
  }
}));
export const buildFilesAndFoldersResponsePayload = resp => {
  const files = buildResultFileItems(resp.results.filter(object => object.category === ObjectCategory.FILE));
  const folders = buildResultFolderItems(resp.results.filter(object => object.category === ObjectCategory.FOLDER));
  const objects = buildResultFileAndFolderItems(resp.results);
  return ImmutableMap(Object.assign({}, resp, {
    objects,
    files,
    folders
  })).delete('results');
};

// Used to add recently uploaded files to a set of results that only contains files
export const mergeRecentlyUploadedFiles = (data, recentlyUploadedFiles) => {
  const fileIds = data.get('objects').map(f => f.get('id'));
  const missingRecentlyUploadedFiles = recentlyUploadedFiles.filter(f => !fileIds.includes(f.get('id'))).toList();
  if (missingRecentlyUploadedFiles.isEmpty()) {
    return data;
  }
  return data.merge({
    objects: data.get('objects').concat(missingRecentlyUploadedFiles),
    total: data.get('total') + missingRecentlyUploadedFiles.size
  });
};

// Used to add recently uploaded files to a set of results that may contain files and folders
export const mergeRecentlyUploadedFilesWithFolders = (data, recentlyUploadedFiles) => {
  const fileIds = data.get('files').map(f => f.get('id'));
  const missingRecentlyUploadedFiles = recentlyUploadedFiles.filter(f => !fileIds.includes(f.get('id'))).toList();
  if (missingRecentlyUploadedFiles.isEmpty()) {
    return data;
  }
  return data.merge({
    files: data.get('files').concat(missingRecentlyUploadedFiles),
    objects: data.get('objects').concat(missingRecentlyUploadedFiles),
    total: data.get('total') + missingRecentlyUploadedFiles.size
  });
};