import { OrderedMap } from 'immutable';
import { createSelector } from 'reselect';
import { getAncestors, makeFolderTree } from '../utils/FoldersAndFiles';
import { RequestStatus } from '../Constants';
import * as RequestType from '../enums/RequestType';
export const findFolderByPath = (folders, path) => {
  if (!path) {
    return null;
  }
  return folders.find(folder => {
    return folder.get('full_path') === path;
  });
};
export const getFolders = state => state.folders.get('objects').toList();
export const getFoldersById = state => state.folders.get('objects');
export const getFoldersCount = state => state.folders.get('total');
export const getSelectedFolder = (state, {
  selectedFolder
}) => selectedFolder;
export const getSelectedFolderAncestors = createSelector([getSelectedFolder, getFoldersById], (selectedFolder, folders) => selectedFolder ? getAncestors(selectedFolder, folders) : OrderedMap());
export const getFolderTree = createSelector([getFoldersById], makeFolderTree);
export const getFolderRequestInfo = (state, requestType, requestLookupKey) => state.folders.getIn(['requests', requestType, requestLookupKey]);
export const hasFolderRequestSucceeded = (state, requestType, requestLookupKey) => {
  const requestInfo = getFolderRequestInfo(state, requestType, requestLookupKey);
  return requestInfo && requestInfo.get('status') === RequestStatus.SUCCEEDED;
};
export const hasFolderRequestInitialized = (state, requestType, requestLookupKey) => {
  const requestInfo = getFolderRequestInfo(state, requestType, requestLookupKey);
  return requestInfo && [RequestStatus.SUCCEEDED, RequestStatus.PENDING].includes(requestInfo.get('status'));
};
export const selectRecentlyUpdatedFoldersFetchStatus = state => state.folders.getIn(['requests', RequestType.recentlyUpdated, RequestType.recentlyUpdated, 'status']);
export const selectFolderCount = (state, {
  folderId
}) => state.folders.get('folderCounts')[folderId];