import Immutable from 'immutable';
import * as ActionTypes from '../actions/ActionTypes';
import { Limits, RequestStatus, FileTypes } from '../Constants';
import { countUploadingFilesByType, updateAttemptedVideoUploads, getDecreasedQuantityUsed, getIncreasedQuantityUsed } from '../utils/Limits';
import { isHubLVideo } from '../utils/hubLVideo';
const DEFAULT_VIDEO_LIMIT = {
  quantityAllowed: 50,
  quantityUsed: 0,
  type: Limits.EMBEDDABLE_VIDEO,
  attemptedVideoUploads: 0
};
const LimitsInitialState = Immutable.Record({
  [Limits.EMBEDDABLE_VIDEO]: Immutable.fromJS(DEFAULT_VIDEO_LIMIT),
  requestStatus: RequestStatus.UNINITIALIZED
});
export default function limitsReducer(state = LimitsInitialState(), action) {
  const {
    type,
    limits,
    uploadingFiles,
    videoCount,
    file
  } = action;
  switch (type) {
    case ActionTypes.FETCH_LIMITS_SUCCEEDED:
      if (limits) {
        const newVideoLimit = {
          quantityAllowed: limits.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityAllowed']),
          quantityUsed: limits.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed']),
          type: Limits.EMBEDDABLE_VIDEO,
          attemptedVideoUploads: 0
        };
        return state.merge({
          requestStatus: RequestStatus.SUCCEEDED,
          [Limits.EMBEDDABLE_VIDEO]: Immutable.fromJS(newVideoLimit)
        });
      }
      return state;
    case ActionTypes.BULK_UPLOAD_FILES_ATTEMPTED:
      return state.merge({
        [Limits.EMBEDDABLE_VIDEO]: updateAttemptedVideoUploads(state, countUploadingFilesByType(uploadingFiles, FileTypes.MOVIE))
      });
    case ActionTypes.CONFIRM_VIDEO_UPLOAD_WITHOUT_EMBED:
      return state.merge({
        [Limits.EMBEDDABLE_VIDEO]: updateAttemptedVideoUploads(state, 0)
      });
    case ActionTypes.DECREASE_VIDEO_QUANTITY_USED:
      return state.setIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed'], getDecreasedQuantityUsed(state, videoCount !== undefined ? videoCount : 0));
    case ActionTypes.CREATE_VIDEO_PLAYERID_SUCCEEDED:
      return state.setIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed'], getIncreasedQuantityUsed(state, 1));
    case ActionTypes.UPLOAD_FILE_SUCCEEDED:
      if (isHubLVideo(file && file.toJS())) {
        return state.setIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed'], getIncreasedQuantityUsed(state, 1));
      }
      return state;
    case ActionTypes.SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED:
      return state.setIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed'], getDecreasedQuantityUsed(state, 1));
    default:
      return state;
  }
}