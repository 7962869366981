/**
 * Borrowed from https://git.hubteam.com/HubSpot/hs-promise-utils/blob/88eb84d0204cfba35f4c15891ab0d38f66378302/hs-promise-utils/static/js/allSettled.ts
 *
 * Due to a large number of deployables that are dependent on FileManagerCore,
 * adding this util here is more effective that adding a dependency on hs-promise-utils
 */

function allSettled(arrayOrPromise) {
  return Promise.resolve(arrayOrPromise).then(promises => Promise.all(promises.map(promise => Promise.resolve(promise).then(value => ({
    status: 'fulfilled',
    value
  })).catch(reason => ({
    status: 'rejected',
    reason
  })))));
}
export default allSettled;