import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { RequestStatus } from 'FileManagerCore/Constants';
import { getFiles } from 'FileManagerCore/selectors/Files';
import { getFoldersById } from 'FileManagerCore/selectors/Folders';
import { getSingleFileDetails } from 'FileManagerCore/selectors/FileDetails';
import { getSubtitlesState } from 'FileManagerCore/selectors/videoSubtitles';
import { getAncestorsForFile, toOrderedMapFilesOrFolders } from 'FileManagerCore/utils/FoldersAndFiles';
import getIsSystemFolder from 'FileManagerCore/utils/getIsSystemFolder';
export const getSelectedFileIds = state => state.selection.get('bulkFileIds');
export const getSelectedFolderIds = state => state.selection.get('bulkFolderIds');
const mergeFilesAndFolders = createSelector([getFiles, getFoldersById, getSelectedFileIds, getSelectedFolderIds], (files, folders, fileIds, folderIds) => {
  const filesById = toOrderedMapFilesOrFolders(files);
  const selectedFiles = Immutable.List(fileIds.map(id => filesById.get(id)).filter(o => Boolean(o)));
  const selectedFolders = Immutable.List(folderIds.map(id => folders.get(id)).filter(o => Boolean(o)));
  const orderedSelectedFiles = toOrderedMapFilesOrFolders(selectedFiles);
  const orderedSelectedFolders = toOrderedMapFilesOrFolders(selectedFolders);
  return orderedSelectedFiles.concat(orderedSelectedFolders);
});
export const getSelectedFilesAndFolders = state => mergeFilesAndFolders(state);
export const getSelectedObjectIds = createSelector([getSelectedFileIds, getSelectedFolderIds], (fileIds, folderIds) => fileIds.concat(folderIds));
export const getTotalFileCount = state => state.selection.get('totalFileCount');
export const getTotalNestedFileCountRequestStatus = state => state.selection.get('totalNestedFileCountRequestStatus');
export const getChangePublicAccessibilityRequestStatus = state => state.selection.get('singleFileChangePublicAccessibilityRequestStatus');
export const getBulkChangePublicAccessibilityRequestStatus = state => state.selection.get('bulkChangePublicAccessibilityRequestStatus');
const getSingleFileSelection = state => {
  return state.selection.get('single');
};
export const getSelectedFile = createSelector([getSingleFileSelection, getSingleFileDetails], (selectedFile, singleFileDetails) => {
  if (!selectedFile) {
    return selectedFile;
  }
  return singleFileDetails.get(selectedFile.get('id'));
});
export const getSelectedFileAncestors = createSelector([getSelectedFile, getFoldersById], (selectedFile, folders) => {
  if (!selectedFile) {
    return selectedFile;
  }
  return getAncestorsForFile(selectedFile, folders);
});
export const getIsSelectionPartitionable = createSelector([getSelectedFileIds, getSelectedFolderIds, getFoldersById], (fileIds, folderIds, foldersById) => {
  if (!fileIds.isEmpty()) {
    return false;
  }
  const folders = folderIds.map(id => foldersById.get(id));
  return folders.every(folder => folder.get('parent_folder_id') === null && !getIsSystemFolder(folder));
});
export const selectSubtitlesFetchStatus = createSelector([getSubtitlesState, getSelectedFile], (videoSubtitles, selectedFile) => {
  if (!selectedFile) {
    return null;
  }
  return videoSubtitles.getIn(['fetchSubtitlesRequestStatusByVideoFileId', selectedFile.get('id')]) || RequestStatus.UNINITIALIZED;
});