import I18n from 'I18n';
const UNITS_FOR_POWERS_OF_KILO = {
  GB: Math.pow(2, 30),
  MB: Math.pow(2, 20)
};
const megabitToBit = 0.000001;
export function fileSize(filesize, precision = 2) {
  for (const unit in UNITS_FOR_POWERS_OF_KILO) {
    if (Object.prototype.hasOwnProperty.call(UNITS_FOR_POWERS_OF_KILO, unit)) {
      const minnum = UNITS_FOR_POWERS_OF_KILO[unit];
      if (filesize >= minnum) {
        return `${I18n.formatNumber(filesize / minnum, {
          precision
        })} ${unit}`;
      }
    }
  }
  if (filesize >= 1024) {
    return `${I18n.formatNumber(filesize / 1024, {
      precision: 0
    })} KB`;
  }
  return `${I18n.formatNumber(filesize)} B`;
}
export function megabitRate(bitrate, precision = 2) {
  const mbRate = bitrate * megabitToBit;
  return `${I18n.formatNumber(mbRate, {
    precision
  })} Mb/s`;
}