import Immutable from 'immutable';
import { RequestStatus } from 'FileManagerCore/Constants';
import { ADD_FOLDER_SUCCEEDED, ARCHIVE_FILE_SUCCEEDED, BULK_DELETE_ATTEMPTED, BULK_DELETE_FAILED, BULK_IMAGE_IMPORT_SUCCEEDED, BULK_MOVE_ATTEMPTED, BULK_MOVE_FAILED, CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED, CHANGE_FILE_PARTITIONING_SUCCEEDED, CHANGE_FOLDER_PARTITIONING_SUCCEEDED, CREATE_VIDEO_PLAYERID_SUCCEEDED, DELETE_FILE_SUCCEEDED, DELETE_FOLDER_SUCCEEDED, DOWNLOAD_FROM_CANVA_SUCCEEDED, GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED, MOVE_FILE_SUCCEEDED, MOVE_FOLDER_SUCCEEDED, RECALCULATE_IMAGE_DIMENSIONS_SUCCESS, REMOVE_CANVA_ID_SUCCEEDED, RENAME_FILE_ATTEMPTED, RENAME_FILE_FAILED, RENAME_FILE_SUCCEEDED, RENAME_FOLDER_SUCCEEDED, REPLACE_FILE_ATTEMPTED, REPLACE_FILE_FAILED, REPLACE_FILE_PROGRESS, REPLACE_FILE_SUCCEEDED, SAVE_GENERATED_IMAGES_SUCCESS, SEARCH_FILES_AND_FOLDERS_ATTEMPTED, SEARCH_FILES_AND_FOLDERS_FAILED, SEARCH_FILES_AND_FOLDERS_SUCCESS, SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED, UNARCHIVE_FILE_SUCCEEDED, UPDATE_FILE_EXPIRATION_SUCCEEDED, UPLOAD_EDITED_IMAGE_SUCCEEDED, UPLOAD_FILE_SUCCEEDED, VIDEO_THUMBNAIL_UPDATE_SUCCEEDED } from 'FileManagerCore/actions/ActionTypes';
import { toOrderedMapFilesOrFolders } from 'FileManagerCore/utils/FoldersAndFiles';
const defaultState = Immutable.Map({
  status: RequestStatus.UNINITIALIZED,
  query: Immutable.Map(),
  objects: Immutable.List(),
  total: 0
});
function findIndex(state, index) {
  return state.get('objects').findIndex(item => {
    return !!item && item.get('id') === index;
  });
}
function addObject(state, object) {
  return state.merge({
    objects: state.get('objects').unshift(object),
    total: state.get('total') + 1
  });
}
function addMultipleObjects(state, objects) {
  return state.merge({
    objects: state.get('objects').unshift(...objects.toArray()),
    total: state.get('total') + objects.size
  });
}
function replaceObject(state, object) {
  const index = findIndex(state, object.get('id'));
  if (index === -1) {
    return state;
  }
  return state.setIn(['objects', index], object);
}
function updateFolderPartioning(state, folderId, teamIds, userIds) {
  return state.merge({
    objects: state.get('objects').map(folderToUpdate => {
      if (folderId === folderToUpdate.get('id')) {
        return folderToUpdate.set('teams', Immutable.Set(teamIds).toList()).set('owners', Immutable.Set(userIds).toList());
      }
      return folderToUpdate;
    })
  });
}
function updateFilePartitioning(state, fileId, teamIds, userIds) {
  return state.merge({
    objects: state.get('objects').map(fileToUpdate => {
      if (fileId === fileToUpdate.get('id')) {
        return fileToUpdate.set('teams', Immutable.Set(teamIds).toList()).set('owners', Immutable.Set(userIds).toList());
      }
      return fileToUpdate;
    })
  });
}
function removeObject(state, id) {
  const index = findIndex(state, id);

  // If object is not found, do nothing
  if (index < 0) {
    return state;
  }
  return state.merge({
    objects: state.get('objects').delete(index),
    total: state.get('total') - 1
  });
}
function removeMultipleObjects(state, objects) {
  let nextState = state;
  objects.forEach(object => {
    nextState = removeObject(nextState, object.get('id'));
  });
  return nextState;
}
function updateFile(state, fileId, updates) {
  const index = findIndex(state, fileId);
  if (index === -1) {
    return state;
  }
  let file = state.getIn(['objects', index]);
  file = file.merge(updates);
  return state.setIn(['objects', index], file);
}
const FilesAndFoldersTable = (state = defaultState, {
  type,
  status,
  query,
  data,
  file,
  files,
  fileId,
  progress,
  tempFile,
  tempUrl,
  dimensions,
  folder,
  folders,
  folderId,
  teamIds,
  userIds,
  fetchingMore
}) => {
  switch (type) {
    case SEARCH_FILES_AND_FOLDERS_ATTEMPTED:
      state = state.merge({
        query,
        fetchingMore
      });
      if (!fetchingMore) {
        state = state.merge({
          status,
          files: Immutable.List()
        });
      }
      return state;
    case SEARCH_FILES_AND_FOLDERS_SUCCESS:
      {
        let objects = data.get('objects');
        if (fetchingMore) {
          objects = toOrderedMapFilesOrFolders(state.get('objects')).concat(toOrderedMapFilesOrFolders(objects)).toList();
        }
        return state.merge({
          objects,
          total: data.get('total'),
          query,
          status,
          fetchingMore: false
        });
      }
    case SEARCH_FILES_AND_FOLDERS_FAILED:
      return state.merge({
        objects: Immutable.List(),
        total: 0,
        query,
        status,
        fetchingMore: false
      });
    case UPLOAD_FILE_SUCCEEDED:
    case UPLOAD_EDITED_IMAGE_SUCCEEDED:
    case DOWNLOAD_FROM_CANVA_SUCCEEDED:
      return addObject(state, file);
    case SAVE_GENERATED_IMAGES_SUCCESS:
      return addMultipleObjects(state, files);
    case RENAME_FILE_ATTEMPTED:
    case RENAME_FILE_SUCCEEDED:
    case RENAME_FILE_FAILED:
    case REPLACE_FILE_SUCCEEDED:
    case REPLACE_FILE_FAILED:
    case CREATE_VIDEO_PLAYERID_SUCCEEDED:
    case SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED:
    case VIDEO_THUMBNAIL_UPDATE_SUCCEEDED:
    case GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED:
    case CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED:
    case REMOVE_CANVA_ID_SUCCEEDED:
    case RECALCULATE_IMAGE_DIMENSIONS_SUCCESS:
    case UPDATE_FILE_EXPIRATION_SUCCEEDED:
      return replaceObject(state, file);
    case REPLACE_FILE_PROGRESS:
      return updateFile(state, fileId, {
        progress
      });
    case REPLACE_FILE_ATTEMPTED:
      return updateFile(state, fileId, Object.assign({
        replaced: true,
        size: tempFile.size,
        tempUrl,
        progress
      }, dimensions));
    // Since we're either currently filtering by "archived" or not, we can simply remove the file from the current view
    // Similarly, since we filter the view by folder ID, we can remove a file from state on move
    case ARCHIVE_FILE_SUCCEEDED:
    case UNARCHIVE_FILE_SUCCEEDED:
    case MOVE_FILE_SUCCEEDED:
      return removeObject(state, file.get('id'));
    case DELETE_FILE_SUCCEEDED:
      return removeObject(state, fileId);
    case ADD_FOLDER_SUCCEEDED:
      return addObject(state, folder);
    case RENAME_FOLDER_SUCCEEDED:
      return replaceObject(state, folder);
    // Since we filter the view by folder ID, we can remove a folder from state on move
    case MOVE_FOLDER_SUCCEEDED:
    case DELETE_FOLDER_SUCCEEDED:
      return removeObject(state, folder.get('id'));
    case CHANGE_FOLDER_PARTITIONING_SUCCEEDED:
      return updateFolderPartioning(state, folderId, teamIds, userIds);
    case CHANGE_FILE_PARTITIONING_SUCCEEDED:
      return updateFilePartitioning(state, fileId, teamIds, userIds);
    case BULK_IMAGE_IMPORT_SUCCEEDED:
      return addMultipleObjects(state, files);
    case BULK_DELETE_ATTEMPTED:
    case BULK_MOVE_ATTEMPTED:
      // Remove all files and folders on delete or move
      return removeMultipleObjects(state, files.concat(folders).toList());
    case BULK_DELETE_FAILED:
    case BULK_MOVE_FAILED:
      // Add back all files and folders on delete or move. Start with folders first.
      return addMultipleObjects(state, folders.concat(files).toList());
    default:
      return state;
  }
};
export default FilesAndFoldersTable;