import Immutable from 'immutable';
const buildParentFolderList = (folder, folders, result = Immutable.List()) => {
  const parentId = folder.get('parent_folder_id');
  if (!parentId) {
    return result;
  }
  const cursor = folders.find(f => f.get('id').toString() === parentId.toString());
  if (!cursor) {
    throw Error(`Failed to find folder ${parentId}`);
  }
  return buildParentFolderList(cursor, folders, result.push(cursor));
};
export default ((teamId, folder, folders) => {
  const parentFolders = buildParentFolderList(folder, folders).reverse();
  const affectedFolder = parentFolders.find(f => {
    return f.get('teams').includes(teamId);
  });
  return affectedFolder || parentFolders.first();
});