'use es6';

import http from 'hub-http/clients/apiClient';
import { formatUsersV2 } from 'reference-resolvers/formatters/formatUsersV2';
const BASE_URL = 'app-users/v1';
export const createGetAllUsers = ({
  httpClient
}) => () => {
  return httpClient.get(`${BASE_URL}/users`, {
    timeout: 60000
  }).then(formatUsersV2);
};
export const getAllUsers = createGetAllUsers({
  httpClient: http
});