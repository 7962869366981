import { ObjectCategory } from '../Constants';
import { getCurrentUserTeams, getIsUserSuperAdmin } from '../selectors/Auth';
import { getSingleFileDetails } from '../selectors/FileDetails';
import { getFoldersById } from '../selectors/Folders';
export const getAssetHasTeam = (assetTeams, userTeams) => {
  if (!assetTeams) {
    return true;
  }
  return assetTeams.length === 0 || assetTeams.some(assetTeam => userTeams.includes(assetTeam));
};
const getSelectorForObject = category => category === ObjectCategory.FOLDER ? getFoldersById : getSingleFileDetails;
export const getUserHasAccessToAsset = (state, objectId, objectCategory) => {
  if (!objectId || getIsUserSuperAdmin(state)) {
    return true;
  }
  const objects = getSelectorForObject(objectCategory)(state);
  const object = objects.get(objectId);
  const objectTeams = object.get('teams') ? object.get('teams').toArray() : [];
  return getAssetHasTeam(objectTeams, getCurrentUserTeams(state).map(t => t.id));
};
export const flattenTeams = (team, path = [], result = []) => {
  if (!team.childTeams.length) {
    result.push(path.concat(team));
  }
  for (const child of team.childTeams) {
    flattenTeams(child, path.concat(team), result);
  }
  return result;
};
export const getOnlyLeafTeams = (allTeams, teamIdsToAssign) => {
  const allTeamsFlat = allTeams.map(t => flattenTeams(t)).flat(2);
  const teamsToAssign = teamIdsToAssign.map(teamId => {
    const mappedTeam = allTeamsFlat.find(team => team.id === teamId);
    if (!mappedTeam) {
      throw Error(`Attempted to assign asset to team<${teamId}> but team is not in portal`);
    }
    return mappedTeam;
  });
  return teamsToAssign.filter(teamToAssign => {
    return !teamsToAssign.some(team => team.parentTeamId && team.parentTeamId === teamToAssign.id);
  }).map(team => team.id);
};