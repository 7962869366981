export const ACQUIRE_IMAGE_ATTEMPTED = 'ACQUIRE_IMAGE_ATTEMPTED';
export const ACQUIRE_IMAGE_SUCCEEDED = 'ACQUIRE_IMAGE_SUCCEEDED';
export const ACQUIRE_IMAGE_FAILED = 'ACQUIRE_IMAGE_FAILED';
export const EXPORT_FILES_ATTEMPTED = 'EXPORT_FILES_ATTEMPTED';
export const EXPORT_FILES_SUCCEEDED = 'EXPORT_FILES_SUCCEEDED';
export const EXPORT_FILES_FAILED = 'EXPORT_FILES_FAILED';
export const FETCH_DOMAINS_ATTEMPTED = 'FETCH_DOMAINS_ATTEMPTED';
export const FETCH_DOMAINS_SUCCEEDED = 'FETCH_DOMAINS_SUCCEEDED';
export const FETCH_DOMAINS_FAILED = 'FETCH_DOMAINS_FAILED';
export const FETCH_FILE_PARENTS_ATTEMPTED = 'FETCH_FILE_PARENTS_ATTEMPTED';
export const FETCH_FILE_PARENTS_SUCCEEDED = 'FETCH_FILE_PARENTS_SUCCEEDED';
export const FETCH_FILE_PARENTS_FAILED = 'FETCH_FILE_PARENTS_FAILED';
export const SEARCH = 'SEARCH';
export const SORT = 'SORT';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const ARCHIVE = 'ARCHIVE';
export const FILTER_FILE_TYPE = 'FILTER_FILE_TYPE';
export const SELECT_FILE = 'SELECT_FILE';
export const ADD_BULK_FILE_SELECTION = 'ADD_BULK_FILE_SELECTION';
export const ADD_BULK_FOLDER_SELECTION = 'ADD_BULK_FOLDER_SELECTION';
export const REMOVE_BULK_FOLDER_SELECTION = 'REMOVE_BULK_FOLDER_SELECTION';
export const REMOVE_BULK_FILE_SELECTION = 'REMOVE_BULK_FILE_SELECTION';
export const CLEAR_BULK_SELECTION = 'CLEAR_BULK_SELECTION';
export const NAVIGATE = 'NAVIGATE';
export const NAVIGATE_TO_FOLDER = 'NAVIGATE_TO_FOLDER';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CHANGE_FILE_ACCESSIBILITY_REQUEST_ATTEMPTED = 'CHANGE_FILE_ACCESSIBILITY_REQUEST_ATTEMPTED';
export const CHANGE_FILE_ACCESSIBILITY_REQUEST_FAILED = 'CHANGE_FILE_ACCESSIBILITY_REQUEST_FAILED';
export const START_START_BULK_VISIBILITY_CHANGE_ATTEMPTED = 'START_START_BULK_VISIBILITY_CHANGE_ATTEMPTED';
export const START_BULK_VISIBILITY_CHANGE_SUCCESS = 'START_BULK_VISIBILITY_CHANGE_SUCCESS';
export const START_BULK_VISIBILITY_CHANGE_FAILURE = 'START_BULK_VISIBILITY_CHANGE_FAILURE';
export const COUNT_FILES_ATTEMPTED = 'COUNT_FILES_ATTEMPTED';
export const COUNT_FILES_SUCCEEDED = 'COUNT_FILES_SUCCEEDED';
export const COUNT_FILES_FAILED = 'COUNT_FILES_FAILED';
export const SHOW_DISALLOWED_PAGE = 'SHOW_DISALLOWED_PAGE';
export const HIDE_DISALLOWED_PAGE = 'HIDE_DISALLOWED_PAGE';
export const FETCH_FILE_HISTORY_ATTEMPTED = 'FETCH_FILE_HISTORY_ATTEMPTED';
export const FETCH_FILE_HISTORY_SUCCEEDED = 'FETCH_FILE_HISTORY_SUCCEEDED';
export const FETCH_FILE_HISTORY_FAILED = 'FETCH_FILE_HISTORY_FAILED';