import { FETCH_LIMITS_SUCCEEDED, CONFIRM_VIDEO_UPLOAD_WITHOUT_EMBED, DECREASE_VIDEO_QUANTITY_USED } from './ActionTypes';
import { Limits, TRACK_EVENT } from '../Constants';
import * as LimitsApi from '../api/Limits';
import { reportError } from '../utils/logging';
function getFetchLimitsSucceeded(limits) {
  const limit = limits.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityAllowed']);
  const quantityRemaining = limit - limits.getIn([Limits.EMBEDDABLE_VIDEO, 'quantityUsed']);
  return {
    type: FETCH_LIMITS_SUCCEEDED,
    limits,
    meta: {
      [TRACK_EVENT]: {
        eventKey: 'fileManagerVideoPQL',
        action: 'limits loaded',
        meta: {
          limit,
          quantityRemaining
        }
      }
    }
  };
}
export const fetchLimits = () => dispatch => {
  return LimitsApi.fetchLimits().then(limits => dispatch(getFetchLimitsSucceeded(limits))).catch(e => {
    reportError(e, {
      action: 'FETCH_LIMITS_FAILED'
    });
  });
};
export const confirmVideoUploadWithoutEmbed = () => ({
  type: CONFIRM_VIDEO_UPLOAD_WITHOUT_EMBED
});
export function getDecreaseVideoQuantityUsedAction(videoCount) {
  return {
    type: DECREASE_VIDEO_QUANTITY_USED,
    videoCount
  };
}