var FileUploadErrorType;
(function (FileUploadErrorType) {
  FileUploadErrorType["HIGH_RISK_PORTAL"] = "HIGH_RISK_PORTAL";
  FileUploadErrorType["MISSING_WRITE_SCOPE"] = "MISSING_WRITE_SCOPE";
  FileUploadErrorType["FILE_TOO_LARGE"] = "FILE_TOO_LARGE";
  FileUploadErrorType["UPLOAD_BATCH_LIMIT"] = "UPLOAD_BATCH_LIMIT";
  FileUploadErrorType["DISALLOWED_EXTENSION"] = "DISALLOWED_EXTENSION";
  FileUploadErrorType["VIRUS_FOUND"] = "VIRUS_FOUND";
  FileUploadErrorType["CMS_FREE_MAX_UPLOAD_SIZE_EXCEEDED"] = "CMS_FREE_MAX_UPLOAD_SIZE_EXCEEDED";
  FileUploadErrorType["MAX_UPLOAD_SIZE_EXCEEDED"] = "MAX_UPLOAD_SIZE_EXCEEDED";
  FileUploadErrorType["PRE_UPLOAD_MAX_SIZE_EXCEEDED"] = "PRE_UPLOAD_MAX_SIZE_EXCEEDED";
})(FileUploadErrorType || (FileUploadErrorType = {}));
export default FileUploadErrorType;