// mirror https://git.hubteam.com/HubSpot/Vetting/blob/master/VettingCore/src/main/java/com/hubspot/vetting/core/models/accountverification/AccountVerificationState.java

const UNINITIATED = 'UNINITIATED';
const PENDING = 'PENDING';
const ACCEPTED = 'ACCEPTED';
const REJECTED = 'REJECTED';
export default {
  UNINITIATED,
  PENDING,
  ACCEPTED,
  REJECTED
};