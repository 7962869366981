export default ((name, query) => {
  if (!query || !name.toLowerCase().includes(query.toLowerCase())) {
    return null;
  }
  if (query.toLowerCase() === name.toLowerCase()) {
    return {
      prefix: '',
      matched: name,
      suffix: ''
    };
  }
  const nameLocation = name.toLowerCase().indexOf(query.toLowerCase());
  const prefix = name.slice(0, nameLocation);
  const matched = name.slice(nameLocation, nameLocation + query.length);
  const suffix = name.slice(nameLocation + query.length);
  return {
    prefix,
    matched,
    suffix
  };
});