const VISIBLE_TO_ALL = 'VISIBLE_TO_ALL';
const VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES = 'VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES';
const HIDDEN_FROM_ALL = 'HIDDEN_FROM_ALL';
const HIDDEN_IN_APP_SENSITIVE = 'HIDDEN_IN_APP_SENSITIVE';
export const FileVisibilityOptionNames = {
  [VISIBLE_TO_ALL]: VISIBLE_TO_ALL,
  [VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES]: VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES,
  [HIDDEN_FROM_ALL]: HIDDEN_FROM_ALL,
  [HIDDEN_IN_APP_SENSITIVE]: HIDDEN_IN_APP_SENSITIVE
};
export const FileVisibilityOptions = {
  [VISIBLE_TO_ALL]: {
    allow_anonymous_access: true,
    is_indexable: true
  },
  [VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES]: {
    allow_anonymous_access: true,
    is_indexable: false
  },
  [HIDDEN_FROM_ALL]: {
    allow_anonymous_access: false,
    is_indexable: false
  }
};