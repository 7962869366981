import { FileVisibilityOptionNames } from '../enums/FileVisibilityOptions';
const I18N_PREFIX = 'FileManagerCore.changeFileVisibilityConfirmationModals.shared.';
function getConfirmFileVisibilityI18nLabel(selectedFileVisibilitySetting) {
  switch (selectedFileVisibilitySetting) {
    case FileVisibilityOptionNames.VISIBLE_TO_ALL:
      return I18N_PREFIX.concat('makePublicButton');
    case FileVisibilityOptionNames.VISIBLE_TO_USERS_HIDDEN_FROM_SEARCH_ENGINES:
      return I18N_PREFIX.concat('makePublicNoIndexButton');
    case FileVisibilityOptionNames.HIDDEN_FROM_ALL:
      return I18N_PREFIX.concat('makePrivateButton');
    default:
      return I18N_PREFIX.concat('makePrivateButton');
  }
}
export default getConfirmFileVisibilityI18nLabel;