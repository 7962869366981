import Immutable from 'immutable';
import { ARCHIVE_FILE_SUCCEEDED, BULK_DELETE_ATTEMPTED, BULK_DELETE_FAILED, BULK_MOVE_ATTEMPTED, BULK_MOVE_FAILED, BULK_IMAGE_IMPORT_SUCCEEDED, DELETE_FILE_SUCCEEDED, FETCH_MORE_FILES_FAILED, MOVE_FILE_SUCCEEDED, RENAME_FILE_ATTEMPTED, RENAME_FILE_FAILED, RENAME_FILE_SUCCEEDED, REPLACE_FILE_ATTEMPTED, REPLACE_FILE_PROGRESS, REPLACE_FILE_SUCCEEDED, REPLACE_FILE_FAILED, UNARCHIVE_FILE_SUCCEEDED, UPLOAD_FILE_SUCCEEDED, CREATE_VIDEO_PLAYERID_SUCCEEDED, CREATE_VIDEO_PLAYERID_ATTEMPTED, SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED, CREATE_VIDEO_PLAYERID_FAILED, SOFT_DELETE_VIDEO_PLAYERID_FAILED, DOWNLOAD_FROM_CANVA_SUCCEEDED, VIDEO_THUMBNAIL_UPDATE_SUCCEEDED, GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED, SELECT_FILE, UPLOAD_EDITED_IMAGE_SUCCEEDED, CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED, REMOVE_CANVA_ID_SUCCEEDED, SEARCH_FILES_SUCCESS, SEARCH_FILES_ATTEMPTED, RECALCULATE_IMAGE_DIMENSIONS_SUCCESS, SOFT_DELETE_VIDEO_PLAYERID_ATTEMPTED, UPDATE_FILE_EXPIRATION_SUCCEEDED, SAVE_GENERATED_IMAGES_SUCCESS, SEARCH_FILES_FAILED, SEARCH_FILES_AND_FOLDERS_ATTEMPTED, SEARCH_FILES_AND_FOLDERS_FAILED, SEARCH_FILES_AND_FOLDERS_SUCCESS, CHANGE_FILE_PARTITIONING_SUCCEEDED, SAVE_ADOBE_IMAGE_SUCCEEDED } from '../actions/ActionTypes';
import { RequestStatus } from '../Constants';
import { toOrderedMapFilesOrFolders } from '../utils/FoldersAndFiles';
const defaultState = Immutable.Map({
  objects: Immutable.List(),
  total: 0,
  query: Immutable.Map(),
  status: RequestStatus.UNINITIALIZED,
  videoPlayerRequestStatus: RequestStatus.UNINITIALIZED,
  fetchingMore: false
});
function findIndex(state, fileId) {
  return state.get('objects').findIndex(item => {
    return item.get('id') === fileId;
  });
}
function replaceFile(state, file) {
  const index = findIndex(state, file.get('id'));
  if (index === -1) {
    return state;
  }
  return state.setIn(['objects', index], file);
}
function updateFile(state, fileId, updates) {
  const index = findIndex(state, fileId);
  if (index === -1) {
    return state;
  }
  let file = state.getIn(['objects', index]);
  file = file.merge(updates);
  return state.setIn(['objects', index], file);
}
function removeFile(state, fileId) {
  const index = findIndex(state, fileId);
  const objects = state.get('objects');
  return state.merge({
    objects: objects.delete(index),
    total: state.get('total') - 1
  });
}
function removeAll(state, files) {
  let nextState = state;
  files.forEach(file => {
    nextState = removeFile(nextState, file.get('id'));
  });
  return nextState;
}
function addAll(state, files) {
  if (files.count() === 0) {
    return state;
  }
  return state.merge({
    objects: state.get('objects').concat(files),
    total: state.get('total') + files.count()
  });
}
export default function Files(state = defaultState, action) {
  const {
    type,
    status,
    query,
    data,
    file,
    files,
    fileId,
    tempFile,
    tempUrl,
    progress,
    dimensions,
    fetchingMore,
    teamIds,
    userIds
  } = action;
  switch (type) {
    case SEARCH_FILES_AND_FOLDERS_ATTEMPTED:
    case SEARCH_FILES_ATTEMPTED:
      state = state.merge({
        query,
        fetchingMore
      });
      if (!fetchingMore) {
        state = state.merge({
          status,
          objects: Immutable.List()
        });
      }
      return state;
    case SEARCH_FILES_SUCCESS:
      {
        let objects = data.get('objects');
        if (fetchingMore) {
          objects = toOrderedMapFilesOrFolders(state.get('objects')).concat(toOrderedMapFilesOrFolders(objects)).toList();
        }
        return state.merge({
          objects,
          total: data.get('total'),
          query,
          status,
          fetchingMore: false
        });
      }
    case SEARCH_FILES_AND_FOLDERS_SUCCESS:
      {
        let objects = data.get('files');
        if (fetchingMore) {
          objects = toOrderedMapFilesOrFolders(state.get('objects')).concat(toOrderedMapFilesOrFolders(objects)).toList();
        }

        // Note that we do not update "total" because we do not know the total number of files vs folders from this action
        return state.merge({
          objects,
          query,
          status,
          fetchingMore: false
        });
      }
    case SEARCH_FILES_AND_FOLDERS_FAILED:
    case SEARCH_FILES_FAILED:
      return state.merge({
        fetchingMore: false,
        objects: Immutable.List(),
        total: 0,
        query,
        status
      });
    case FETCH_MORE_FILES_FAILED:
      return state.set('fetchingMore', false);
    case DOWNLOAD_FROM_CANVA_SUCCEEDED:
    case UPLOAD_FILE_SUCCEEDED:
    case UPLOAD_EDITED_IMAGE_SUCCEEDED:
      return state.merge({
        objects: state.get('objects').unshift(file),
        total: state.get('total') + 1
      });
    case BULK_IMAGE_IMPORT_SUCCEEDED:
      {
        return state.merge({
          objects: state.get('objects').unshift(...files.toArray()),
          total: state.get('total') + files.size
        });
      }
    case REPLACE_FILE_PROGRESS:
      return updateFile(state, fileId, {
        progress
      });
    case REPLACE_FILE_ATTEMPTED:
      return updateFile(state, fileId, Object.assign({
        replaced: true,
        size: tempFile.size,
        tempUrl,
        progress
      }, dimensions));
    case MOVE_FILE_SUCCEEDED:
      if (state.hasIn(['query', 'folder_id'])) {
        return removeFile(state, file.get('id'));
      }
      return replaceFile(state, file);
    case DELETE_FILE_SUCCEEDED:
      return removeFile(state, fileId);
    case REPLACE_FILE_SUCCEEDED:
    case CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED:
    case REPLACE_FILE_FAILED:
    case REMOVE_CANVA_ID_SUCCEEDED:
    case SAVE_ADOBE_IMAGE_SUCCEEDED:
    case RECALCULATE_IMAGE_DIMENSIONS_SUCCESS:
    case UPDATE_FILE_EXPIRATION_SUCCEEDED:
      return replaceFile(state, file);
    case RENAME_FILE_ATTEMPTED:
    case RENAME_FILE_SUCCEEDED:
    case RENAME_FILE_FAILED:
      return replaceFile(state, file);
    case ARCHIVE_FILE_SUCCEEDED:
    case UNARCHIVE_FILE_SUCCEEDED:
      return removeFile(state, file.get('id'));
    case BULK_DELETE_ATTEMPTED:
    case BULK_MOVE_ATTEMPTED:
      return removeAll(state, files);
    case BULK_DELETE_FAILED:
    case BULK_MOVE_FAILED:
      return addAll(state, files);
    case CREATE_VIDEO_PLAYERID_ATTEMPTED:
    case SOFT_DELETE_VIDEO_PLAYERID_ATTEMPTED:
      return state.merge({
        videoPlayerRequestStatus: RequestStatus.PENDING
      });
    case CREATE_VIDEO_PLAYERID_SUCCEEDED:
    case SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED:
      return replaceFile(state, file).merge({
        videoPlayerRequestStatus: RequestStatus.SUCCEEDED
      });
    case CREATE_VIDEO_PLAYERID_FAILED:
    case SOFT_DELETE_VIDEO_PLAYERID_FAILED:
      return state.merge({
        videoPlayerRequestStatus: RequestStatus.FAILED
      });
    case SELECT_FILE:
      return state.merge({
        videoPlayerRequestStatus: RequestStatus.UNINITIALIZED
      });
    case VIDEO_THUMBNAIL_UPDATE_SUCCEEDED:
    case GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED:
      return replaceFile(state, file);
    case SAVE_GENERATED_IMAGES_SUCCESS:
      {
        const visibleFiles = files.filter(f => !f.get('hidden'));
        return state.merge({
          objects: visibleFiles.concat(state.get('objects')),
          total: state.get('total') + visibleFiles.size
        });
      }
    case CHANGE_FILE_PARTITIONING_SUCCEEDED:
      return state.merge({
        objects: state.get('objects').map(fileToUpdate => {
          if (fileId === fileToUpdate.get('id')) {
            return fileToUpdate.set('teams', Immutable.Set(teamIds).toList()).set('owners', Immutable.Set(userIds).toList());
          }
          return fileToUpdate;
        })
      });
    default:
      return state;
  }
}