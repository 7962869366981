import I18n from 'I18n';
import { reportMessage } from './logging';
export const SUBTITLE_FILENAME_AND_LANG_DIVIDER = '-';
export const splitFileNameAndLangFromFileName = subtitleFileName => {
  const indexOfDivider = subtitleFileName.indexOf(SUBTITLE_FILENAME_AND_LANG_DIVIDER);
  if (indexOfDivider > -1) {
    return {
      fileName: subtitleFileName.substring(indexOfDivider + 1),
      lang: subtitleFileName.substring(0, indexOfDivider),
      originalFileName: subtitleFileName
    };
  }
  reportMessage(`Subtitle file name parsing error ${subtitleFileName}`);
  throw new Error('Subtitle file name format is incorrect');
};
export const constructSubtitleFileNameFromNameAndLanguage = ({
  subtitleFileName,
  lang
}) => {
  if (subtitleFileName.startsWith(`${lang}${SUBTITLE_FILENAME_AND_LANG_DIVIDER}`)) {
    return subtitleFileName;
  }
  return lang.concat(SUBTITLE_FILENAME_AND_LANG_DIVIDER).concat(subtitleFileName);
};
export const constructSubtitleFileNameFromFileWithExtensionAndLang = ({
  subtitleFile,
  lang
}) => {
  return constructSubtitleFileNameFromNameAndLanguage({
    subtitleFileName: subtitleFile.name,
    lang
  });
};

// SubtitlesTODO: needs tests
export const rebuildSubtitleNameWithNewLanguage = (oldSubtitleFile, newLang) => {
  const fileNameAndLang = splitFileNameAndLangFromFileName(oldSubtitleFile.get('name'));
  return `${newLang}-${fileNameAndLang.fileName}`;
};
export const sortSubtitlesByLanguage = subtitles => {
  return subtitles.sortBy((__file, lang) => {
    return I18n.text(`SharedI18nStrings.languageNames.${lang}`);
  });
};