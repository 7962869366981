import Raven from 'raven-js';
import enviro from 'enviro';
import { TRACK_EVENT } from '../../Constants';
import { reportMessage } from '../logging';
import { isCurrentUsageTracker } from '../tracking';
const isDeployed = enviro.deployed('file_manager_lib');
const warnIfLocal = (...message) => {
  if (!isDeployed) {
    console.warn(...message);
  }
};
export default function usageTrackerMiddleware(usageTracker, getEventPayload = (eventAction, extra = {}) => Object.assign({}, extra, {
  action: eventAction
})) {
  const canTrack = isCurrentUsageTracker(usageTracker);
  if (!canTrack) {
    warnIfLocal('FileManagerLib should be passed a usage-tracker-js tracker as `usageTracker` prop.');
  }
  return () => next => action => {
    if (!canTrack || !action.meta || !action.meta[TRACK_EVENT]) {
      return next(action);
    }
    const actionMeta = action.meta[TRACK_EVENT];
    const {
      eventKey,
      action: eventAction,
      meta = {}
    } = actionMeta;
    if (typeof eventKey !== 'string' || typeof eventAction !== 'string') {
      reportMessage('usageTrackerMiddleware received invalid eventKey or action, strings are required', {
        eventKey,
        eventAction
      });
      return next(action);
    }
    Raven.captureBreadcrumb({
      message: `[usage tracker] ${eventKey} ${eventAction}`
    });
    const extra = Object.assign({}, meta);
    if (typeof actionMeta.target === 'string' && !meta.target) {
      extra.target = actionMeta.target;
    }
    usageTracker.track(eventKey, getEventPayload(eventAction, extra));
    return next(action);
  };
}