import Raven from 'raven-js';
const IGNORE_XHR_BREADCRUMB_URLS = [new RegExp('//bam.nr-data.net')];
const breadcrumbCallback = crumb => {
  if (crumb.type === 'http' && crumb.data && crumb.data.url) {
    return !IGNORE_XHR_BREADCRUMB_URLS.some(urlPattern => urlPattern.test(crumb.data.url));
  }
  return crumb;
};
export const setupBreadcrumbCallback = () => {
  Raven.setBreadcrumbCallback(breadcrumbCallback);
};