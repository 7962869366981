import Immutable from 'immutable';
import * as DashbboardActionTypes from '../actions/ActionTypes';
import { RequestStatus } from 'FileManagerCore/Constants';
const defaultState = Immutable.Map();
export default function FileUsage(state = defaultState, action) {
  const {
    type,
    items,
    fileId
  } = action;
  switch (type) {
    case DashbboardActionTypes.FETCH_FILE_PARENTS_ATTEMPTED:
      return state.set(fileId, Immutable.Map({
        status: RequestStatus.PENDING,
        items: Immutable.List()
      }));
    case DashbboardActionTypes.FETCH_FILE_PARENTS_SUCCEEDED:
      return state.set(fileId, Immutable.Map({
        status: RequestStatus.SUCCEEDED,
        items
      }));
    case DashbboardActionTypes.FETCH_FILE_PARENTS_FAILED:
      return state.setIn([fileId, 'status'], RequestStatus.FAILED);
    default:
      return state;
  }
}