import http from 'hub-http/clients/apiClient';
import allSettled from 'file-manager-components/utils/allSettled';
import { getType, splitNameAndExtension } from '../utils/file';
import { hashBlob } from '../utils/hashing';
import { isFulfilled } from '../utils/promise';

// endpoint will 204 when no matches so must handle missing response

export function checkForDuplicates(files, elapsedMs) {
  return http.post(`filemanager/api/v3/files/check-duplicates`, {
    data: {
      files
    },
    query: {
      elapsedMs
    }
  });
}
export const checkLocalFilesForDuplicates = files => {
  const startTime = Date.now();
  const fileHashPromises = files.map(file => hashBlob(file).then(hash => {
    const elapsedMs = Date.now() - startTime;
    return {
      hash,
      elapsedMs
    };
  }));
  return allSettled(fileHashPromises).then(fileHashResults => {
    const hashElapsedTotalMs = Date.now() - startTime;
    console.log(`Computed MD5 hash for ${files.length} files in ${hashElapsedTotalMs} ms`);
    const filesMeta = files.map((file, i) => {
      const fileHashResult = fileHashResults[i];
      return {
        name: file.name,
        size: file.size,
        hash: fileHashResult.status === 'fulfilled' ? fileHashResult.value.hash : null,
        hashElapsedMs: fileHashResult.status === 'fulfilled' ? fileHashResult.value.elapsedMs : null
      };
    });
    return checkForDuplicates(filesMeta, hashElapsedTotalMs).then(duplicateCheckResp => ({
      duplicateCheckResp,
      filesMeta,
      hashElapsedTotalMs
    })).catch(err => {
      throw err;
    });
  }).catch(err => {
    throw err;
  });
};
export const compareLocalFilesWithUploadResponse = ({
  duplicateCheckResp,
  filesMeta
}, uploadedFiles, tracker) => {
  if (!duplicateCheckResp) {
    return;
  }
  duplicateCheckResp.forEach((fileResp, i) => {
    // skip failed uploads when reconciling with /check-duplicates response
    if (!isFulfilled(uploadedFiles[i]) || !('value' in uploadedFiles[i])) {
      return;
    }
    const uploadedFile = uploadedFiles[i].value;
    fileResp.uploadedFile = uploadedFile;
    if (fileResp.newFile && fileResp.newFile.hash !== uploadedFile.get('file_hash')) {
      fileResp.hashMatches = false;
    } else {
      fileResp.hashMatches = true;
    }
    const fileMeta = filesMeta[i];
    if (fileMeta.hashElapsedMs) {
      fileResp.hashElapsedMs = fileMeta.hashElapsedMs;
    }
  });
  const successfulChecks = duplicateCheckResp.filter(fileResp => fileResp.uploadedFile);
  if (tracker) {
    successfulChecks.forEach(fileResp => {
      const {
        extension
      } = splitNameAndExtension(fileResp.newFile);
      const fileType = getType(extension);
      tracker.track('fileManagerUploadDeduplication', {
        fileType,
        size: fileResp.newFile.size,
        hasData: fileResp.hashMatches,
        count: fileResp.duplicateFiles.length,
        duration: fileResp.hashElapsedMs
      });
    });
  }
};