'use es6';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
import createNavigationMiddleware from './navigationMiddleware';
import usageTrackerMiddleware from 'FileManagerCore/utils/redux/usageTrackerMiddleware';
import loggingMiddleware from './loggingMiddleware';
import usageTracker from '../usageTracker';
import rootReducer from '../../reducers';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  name: 'FileManagerDashboard'
}) : compose;
export default function configureStore(initialState, history) {
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, loggingMiddleware(), NotificationMiddleware, createNavigationMiddleware(history), usageTrackerMiddleware(usageTracker)))(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}