import I18n from 'I18n';
import Immutable from 'immutable';
import unescapedText from 'I18n/utils/unescapedText';
import * as FolderValidationErrors from '../enums/FolderValidationErrors';
import getDoesStringOnlyContainSpaces from './getDoesStringOnlyContainSpaces';

// eslint-disable-next-line
const invalidFolderCharsRegExp = /[#\?&'";:\*\^!\$\|\/%@]/g;
function getI18nKey(suffix) {
  return `FileManagerCore.folderNameValidation.${suffix}`;
}
export default function validateFolderName(folderName) {
  if (getDoesStringOnlyContainSpaces(folderName)) {
    return {
      code: FolderValidationErrors.ONLY_WHITESPACES,
      message: I18n.text(getI18nKey('invalidNameOnlyWhitespace'))
    };
  }
  if (folderName.length > 100) {
    return {
      code: FolderValidationErrors.TOO_LONG,
      message: I18n.text(getI18nKey('tooLong'))
    };
  }
  const matches = folderName.match(invalidFolderCharsRegExp);
  if (matches) {
    const uniqueMatches = Immutable.Set(matches);
    const invalidChars = uniqueMatches.join('');
    return {
      code: FolderValidationErrors.INVALID_CHARS,
      message: unescapedText(invalidChars.includes('"') ? getI18nKey('invalidCharsSingleQuote') : getI18nKey('invalidCharsDoubleQuote'), {
        invalidChars
      })
    };
  }
  return null;
}